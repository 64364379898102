import { Formik, Form, ErrorMessage, Field } from 'formik';
import { InputText } from 'primereact/inputtext';
import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components';
import PhoneInput from 'react-phone-input-2';
import { useHistory, useLocation } from "react-router";
import * as yup from 'yup';
import './AddNewRestaurant.css'
import { Button } from 'primereact/button';
import { useStore } from '../stores/useStore';
import { getItem } from './storage';
import { toast } from 'react-toastify';

const FormErrorMsg = styled.span`
    color: red;
    float:right;
    font-size:12px;
   `
toast.configure();

const EditBranch = (props: any) => {
    const orgID = getItem('org-id')
    const location = useLocation()
    let restaurantId = location?.state?.restaurantId
    restaurantId = restaurantId ? restaurantId : ''

    const { restaurantStore } = useStore();
    const [mobile, setMobile] = useState('');
    const [uploadedImage, setUploadedImage] = useState('');
    const [showWarning, setShowWarning] = useState('');
    const [imagePath, setImagePath] = useState("");
    const [uploadingImage, setUploadingImage] = useState('');
    const [restaurantData, setRestaurantData] = useState('')
    const history = useHistory();
    const Goback = () => {
        history.goBack()
    }

    const getRestDetails = useCallback((id: any) => {
        restaurantStore.getRestaurantSettings2(id)
            .then(([success, data]) => {
                if (success) {
                    console.log(data.restaurant_settings)
                    setRestaurantData(data.restaurant_settings)
                    setMobile(data.restaurant_settings.restaurant_mobile)
                    console.log("success", data.restaurant_settings, data.restaurant_settings.restaurant_logo)
                } else {
                    console.log("Rest details responce : ", data)
                }
            })
    }, [])

    useEffect(() => {
        if (restaurantId !== '') {
            getRestDetails(restaurantId)
        }
    }, [getRestDetails, restaurantId])

    const showLogo = (img: any) => {
        console.log("image responce while uploading : ", img.target.files[0])

        var formdata = new FormData()
        formdata.append('logo_url', img.target.files[0])

        var maxSize = 200 * 1024; // 200 is size in kb * 1024 gives size in bytes

        if (img.target.files.length > 0 && img.target.files[0].size <= maxSize) {
            let image = new Image();
            let url = (URL.createObjectURL(img.target.files[0]));

            image.onload = () => {
                console.log(image.naturalWidth, image.naturalHeight)
                if (image.naturalWidth <= 200 && image.naturalHeight <= 200) {
                    console.log(formdata)
                    restaurantStore.uploadImg(formdata)
                        .then(([success, data]: any) => {
                            if (success) {
                                setImagePath(data);
                                console.log("success image upload", data)
                            } else {
                                console.log("Image upload error responce : ", data)
                            }
                        })
                    setUploadedImage(url);
                    setUploadingImage('uploadimg')
                    setShowWarning('');
                }

                else {
                    setUploadedImage("");
                    setUploadingImage("");
                    setShowWarning('showerr');
                    console.log("please upload an image of 200x200")
                }
            };
            image.src = (URL.createObjectURL(img.target.files[0]));
        }
        else {
            setUploadedImage("");
            setUploadingImage("");
            setShowWarning('showerr');
            console.log("please upload an image size less then or equal to 200kb")
        }
    }

    const updateRest = (values: any) => {
        console.log("updated image details", imagePath)

        const data = {
            "name": values.RestaurantName,
            "logo_url": imagePath !== '' ? imagePath : restaurantData?.restaurant_logo,
            "address": {
                "address1": values.Address1,
                "address2": values.Address2,
                "land_mark": restaurantData?.address.land_mark,
                "zip_code": restaurantData?.address.zip_code,
                "latitude": restaurantData?.address.latitude,
                "longitude": restaurantData?.address.longitude,
                "city": restaurantData?.address.city,
                "state": restaurantData?.address.state,
                "country": restaurantData?.address.country
            },
            "booking_widget_settings": JSON.stringify({
                "theme": {
                    "primarycolor": restaurantData?.booking_widget_settings?.theme.primarycolor,
                    "secondarycolor": restaurantData?.booking_widget_settings?.theme.secondarycolor,
                    "backgroundcolor": restaurantData?.booking_widget_settings?.theme.backgroundcolor,
                    "fontfamily": restaurantData?.booking_widget_settings?.theme.fontfamily,
                },
                "display_type": restaurantData?.booking_widget_settings?.display_type,
            }),
            "email": values.Email,
            "mobile": mobile ? mobile : restaurantData?.restaurant_mobile,
            "status": restaurantData?.status,
            "party_size_limit": restaurantData?.party_size_limit,
            "is_otp_enabled": restaurantData?.is_otp_enabled,
            "domain": "string",
            "venue_start_time": restaurantData?.venue_start_time,
            "venue_close_time": restaurantData?.venue_close_time,
            "deposit_amount": restaurantData?.deposit_amount,
            "booking_period": restaurantData?.booking_period,
            "deposit_min_guest": restaurantData?.deposit_min_guest,
            "rest_id": restaurantData?.rest_id,
            "no_show_period": restaurantData?.no_show_period,
            "duration_btn_bookings": restaurantData?.no_show_period,
            "TWILIO_ACCOUNT_SID": restaurantData?.TWILIO_ACCOUNT_SID,
            "TWILIO_AUTHTOKEN": restaurantData?.TWILIO_AUTHTOKEN,
            "TWILIO_FROM_NO": restaurantData?.TWILIO_FROM_NO,
            "STRIPE_API_KEY": restaurantData?.STRIPE_API_KEY,
            "STRIPE_SECRET_KEY": restaurantData?.STRIPE_SECRET_KEY,
            "timezone": restaurantData?.timezone
        }
        restaurantStore.updateRestaurant(data)
            .then(([success, data]) => {
                if (success) {

                    toast.success("Restaurant updated successfully");
                    setTimeout(() => {
                        Goback();
                    }, 500);
                    console.log("updated restaurant details", data);
                } else {
                    toast.error(data);
                    console.log("updated restaurant error responce : ", data);
                }
            })

    }
    return <>
        <div className="breadcrum">
            <h2 onClick={Goback} className="breadcrumText activeBreadcrum">MY PROFILE</h2 >
            <h2 className="breadcrumText"> &gt; </h2>
            <h2 className="breadcrumText">EDIT BRANCH</h2>
        </div>
        <hr className="hr" />
        {restaurantData && <div className='formHolder'>
            <Formik
                initialValues={{ RestaurantName: restaurantData?.restaurant_name, Address1: restaurantData?.address?.address1, Address2: restaurantData?.address?.address2, Email: restaurantData?.restaurant_email, Phone: restaurantData?.restaurant_mobile }}
                validationSchema={yup.object({
                    RestaurantName: yup.string()
                        .required('Required'),
                    Address1: yup.string()
                        .required('Required'),
                    Address2: yup.string()
                        .required('Required'),
                    Email: yup.string().email('Enter a valid Email').required("Required"),

                    // Phone:yup.string().required('Required'),

                    // Phone: yup.string().matches(regex,"Enter a valid mobile number").min(10,'Required min 10 characters')
                    // 	.required('Required'),

                })}
                // onSubmit={(values:any, {resetForm}) => { console.log(values); resetForm({values: ''}) }}
                onSubmit={(values,) => {
                    updateRest(values);
                }}

            >{({ errors, status, touched }) => (<Form>
                <div style={{ padding: '0' }} className='form-control-group hndl-cwinps'>
                    <div className="inpcont">
                        <label>Branch Logo</label>
                        <div id="uploadFiles" className="newImg">
                            <input type="file" className="newlogo" accept="image/png" name="image" id="file" onChange={(e: any) => showLogo(e)} ></input>
                            <img style={{ display: 'none' }} className={uploadingImage} src={uploadedImage} alt=" " />
                        </div>
                        <p style={{ display: 'none', margin: '5px 0 10px' }} className={showWarning} > * please upload an image of 200 x 200 with size &lt;= 200KB* </p>
                    </div>
                    <div className='inpcont'>
                        <label>Current Logo</label>
                        <div className='pastImg'>
                            <img className='logo' src={`https://api.mytablefinder.co.uk${restaurantData?.restaurant_logo}`} alt="Restaurant logo" />
                        </div>
                    </div>
                    <div className="inpcont">
                        <label>Branch Name*</label>
                        <ErrorMessage name='RestaurantName' component={FormErrorMsg} />
                        <Field as={InputText} maxLength="50" autoComplete="off" type="text" name="RestaurantName"
                            className={(errors.RestaurantName && touched.RestaurantName ? ' is-invalid' : 'minputtext')} />
                    </div>
                    <div className="inpcont">
                        <label>Branch Email*</label>
                        <ErrorMessage name='Email' component={FormErrorMsg} />
                        <Field as={InputText} autoComplete="off" type="text" name="Email"
                            className={(errors.Email && touched.Email ? ' is-invalid' : 'minputtext')} />
                    </div>
                    <div className="inpcont">
                        <label>Address 1*</label>
                        <ErrorMessage name='Address1' component={FormErrorMsg} />
                        <Field as={InputText} maxLength="100" autoComplete="off" type="text" name="Address1"
                            className={(errors.Address1 && touched.Address1 ? ' is-invalid' : 'minputtext')} />
                    </div>
                    <div className="inpcont">
                        <label>Address 2*</label>
                        <ErrorMessage name='Address2' component={FormErrorMsg} />
                        <Field as={InputText} maxLength="100" autoComplete="off" type="text" name="Address2"
                            className={(errors.Address2 && touched.Address2 ? ' is-invalid' : 'minputtext')} />
                    </div>

                    <div className="inpcont">
                        <label htmlFor="Phone">Phone*</label>
                        <span id="phone"
                            style={{ display: "none", float: "right" }}
                            className="float-right">Required</span>
                        <div id="pinp" className="mobileInp">
                            <Field as={PhoneInput} required onlyCountries={['gb', 'in']}
                                country={'gb'} autoComplete="off" type="tel" name="Phone" value={mobile}
                                onChange={(e: any) => {

                                    if (e?.length <= 11) {
                                        let p = document.getElementById('phone')
                                        let pi = document.getElementById('pinp')
                                        p.style.color = "red"
                                        p.style.display = "initial"
                                        pi.style.border = "1px solid red"
                                    }
                                    else {
                                        let p = document.getElementById('phone')
                                        let pi = document.getElementById('pinp')
                                        p.style.display = "none"
                                        pi.style.border = "none"

                                    }

                                    setMobile('+' + e)
                                }}
                                onBlur={(e: any) => {
                                    console.log("on blur phone", e.target.value)
                                    if (e.target.value?.length <= 3) {
                                        let p = document.getElementById('phone')
                                        let pi = document.getElementById('pinp')
                                        p.style.color = "red"
                                        p.style.display = "initial"
                                        pi.style.border = "1px solid red"
                                    }
                                    else {
                                        let p = document.getElementById('phone')
                                        let pi = document.getElementById('pinp')
                                        p.style.display = "none"
                                        pi.style.border = "none"

                                    }
                                }}
                                className={(errors.Phone && touched.Phone ? ' is-invalid' : '')} />

                        </div>
                    </div>
                </div>
                <br />
                <div style={{ display: 'flex', columnGap: '20px', rowGap: '20px', flexWrap: 'wrap' }}>
                    <Button type="button" onClick={Goback} className='outline' label="BACK" style={{ width: "150px" }} />
                    <Button type="submit" className='editSave' label=" SAVE " />
                </div>
            </Form>
            )}
            </Formik>
        </div>}

    </>
}

export default EditBranch