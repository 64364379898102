import React, { useState } from 'react';
import { useStore } from '../stores/useStore';
import { useHistory } from 'react-router';
import { Dialog } from 'primereact/dialog';

import { Link } from 'react-router-dom';

const SideNav = () => {
    const { userSessionStore } = useStore();
    const history = useHistory();
    const [dialog, setDialog] = useState(false)

    const onclick = () => {
        setDialog(true);
    }

    const onHide = () => {
        setDialog(false);
    }


    const floor = <svg xmlns="http://www.w3.org/2000/svg"  width="26" height="26" viewBox="0 0 26 26">
    <defs>
      <clipPath id="clip-Artboard_2">
        <rect width="26" height="26"/>
      </clipPath>
    </defs>
    <g id="Artboard_2" data-name="Artboard – 2" clipPath="url(#clip-Artboard_2)">
      <g id="Group_37818" data-name="Group 37818" transform="translate(-7 -7)">
        <g id="Group_37817" data-name="Group 37817" transform="translate(-95 -478)">
          <rect className="rect-floor" id="Rectangle_2492" data-name="Rectangle 2492" width="6" height="6" rx="1" transform="translate(104 487)" fill="#510aea"/>
          <rect className="rect-floor" id="Rectangle_2493" data-name="Rectangle 2493" width="6" height="6" rx="1" transform="translate(112 487)" fill="#510aea"/>
          <rect className="rect-floor" id="Rectangle_2494" data-name="Rectangle 2494" width="6" height="6" rx="1" transform="translate(120 487)" fill="#510aea"/>
        </g>
        <g id="Group_37816" data-name="Group 37816" transform="translate(-95 -478)">
          <rect className="rect-floor" id="Rectangle_2497" data-name="Rectangle 2497" width="6" height="6" rx="1" transform="translate(104 495)" fill="#510aea"/>
          <rect className="rect-floor" id="Rectangle_2496" data-name="Rectangle 2496" width="6" height="6" rx="1" transform="translate(112 495)" fill="#510aea"/>
          <rect className="rect-floor" id="Rectangle_2495" data-name="Rectangle 2495" width="6" height="6" rx="1" transform="translate(120 495)" fill="#510aea"/>
        </g>
        <g id="Group_37815" data-name="Group 37815" transform="translate(-95 -478)">
          <rect className="rect-floor" id="Rectangle_2500" data-name="Rectangle 2500" width="6" height="6" rx="1" transform="translate(104 503)" fill="#510aea"/>
          <rect className="rect-floor" id="Rectangle_2499" data-name="Rectangle 2499" width="6" height="6" rx="1" transform="translate(112 503)" fill="#510aea"/>
          <rect className="rect-floor" id="Rectangle_2498" data-name="Rectangle 2498" width="6" height="6" rx="1" transform="translate(120 503)" fill="#510aea"/>
        </g>
      </g>
    </g>
  </svg>
  
    return <div className="side-nav left-menu text-center bg-white index-8"><ul>
        
        <li>
            <Link  to="/" className="nav-div">
            <figure className="icon d-flex align-items-center justify-content-center flex-no-wrap transition">
                {/* <i className="pi pi-home "> </i> */}
                <svg className="svgIcon" xmlns="http://www.w3.org/2000/svg" width="17.893" height="19.659" viewBox="0 0 17.893 19.659"><defs></defs><g transform="translate(1 1)"><path className="a" d="M4.5,9.181,12.446,3l7.946,6.181v9.712a1.766,1.766,0,0,1-1.766,1.766H6.266A1.766,1.766,0,0,1,4.5,18.893Z" transform="translate(-4.5 -3)"/><path className="a" d="M13.5,26.829V18h5.3v8.829" transform="translate(-8.202 -9.171)"/></g></svg>
            </figure>
            <span className=" d-block">Dashboard</span>
        </Link>        
        </li>
        <li>
            <Link to='/reservationlist' className="nav-div">
                <figure className="icon d-flex align-items-center justify-content-center flex-no-wrap transition">
                    {/* <i className="pi  pi-ticket "> </i> */}
                    <svg className="svgIcon" xmlns="http://www.w3.org/2000/svg" width="25.572" height="23.215" viewBox="0 0 25.572 23.215"><defs></defs><g transform="translate(1 1)"><path className="a" d="M3,4.5h7.072a4.714,4.714,0,0,1,4.714,4.714v16.5a3.536,3.536,0,0,0-3.536-3.536H3Z" transform="translate(-3 -4.5)"/><path className="a" d="M29.786,4.5H22.714A4.714,4.714,0,0,0,18,9.214v16.5a3.536,3.536,0,0,1,3.536-3.536h8.25Z" transform="translate(-6.214 -4.5)"/></g></svg>
                </figure>
                <span className=" d-block">Reservations</span>
            </Link>
        </li>
        <li>
            <Link to='/create-floor-plan' className="nav-div">
            <figure className="icon d-flex align-items-center justify-content-center flex-no-wrap transition">
                {/* <i className="pi  pi-plus "> </i> */}
                {floor}
            </figure>
            <span className=" d-block">Floor Plan</span>
        </Link>
        </li>
        <li>
            <Link to='/guestbooklist' className="nav-div">
                <figure className="icon d-flex align-items-center justify-content-center flex-no-wrap transition">
                {/* <i class="far fa-bookmark"></i> */}
                {/* <img className="svgIcon" src="./assets/images/bookmark.svg" alt="" /> */}
                <svg className="svgIcon" xmlns="http://www.w3.org/2000/svg" width="20.645" height="25.972" viewBox="0 0 20.645 25.972"><defs></defs><path className="a" d="M26.145,28.472l-9.322-6.659L7.5,28.472V7.164A2.664,2.664,0,0,1,10.164,4.5H23.481a2.664,2.664,0,0,1,2.664,2.664Z" transform="translate(-6.5 -3.5)"/></svg>
                </figure>
                <span className=" d-block">Guest Book</span>
            </Link>
        </li>
        <li>
            <Link to='/settings' className="nav-div">
                <figure className="icon d-flex align-items-center justify-content-center flex-no-wrap transition">
                    {/* <i className="pi pi-cog "> </i> */}
                    <svg className="svgIcon" xmlns="http://www.w3.org/2000/svg" width="26.028" height="26.028" viewBox="0 0 26.028 26.028"><defs></defs><g transform="translate(-1416.986 4430.049)"><path className="a" d="M21.6,16.791a1.8,1.8,0,0,0,.36,1.988l.066.066a2.186,2.186,0,1,1-3.091,3.091l-.066-.066a1.817,1.817,0,0,0-3.08,1.289v.186a2.184,2.184,0,0,1-4.369,0v-.1a1.8,1.8,0,0,0-1.18-1.649,1.8,1.8,0,0,0-1.988.36l-.066.066a2.186,2.186,0,1,1-3.091-3.091l.066-.066a1.817,1.817,0,0,0-1.289-3.08H3.684a2.184,2.184,0,1,1,0-4.369h.1a1.8,1.8,0,0,0,1.649-1.18,1.8,1.8,0,0,0-.36-1.988l-.066-.066A2.186,2.186,0,1,1,8.1,5.093l.066.066a1.8,1.8,0,0,0,1.988.36h.087A1.8,1.8,0,0,0,11.33,3.87V3.684a2.184,2.184,0,1,1,4.369,0v.1a1.817,1.817,0,0,0,3.08,1.289l.066-.066A2.186,2.186,0,1,1,21.935,8.1l-.066.066a1.8,1.8,0,0,0-.36,1.988v.087a1.8,1.8,0,0,0,1.649,1.092h.186a2.184,2.184,0,0,1,0,4.369h-.1A1.8,1.8,0,0,0,21.6,16.791Z" transform="translate(1416.486 -4430.549)"/><path className="a" d="M20.053,16.777A3.277,3.277,0,1,1,16.777,13.5,3.277,3.277,0,0,1,20.053,16.777Z" transform="translate(1413.223 -4433.812)"/></g></svg>
                </figure>
                <span className=" d-block">Settings</span>
            </Link>
        </li>
        {/* <li>
            <Link to='/profile-settings' className="nav-div">
                <figure className="icon d-flex align-items-center justify-content-center flex-no-wrap transition">
                    <i className="pi pi-fw pi-cog "> </i>
                </figure>
                <span className=" d-block">Settings</span>
            </Link>
        </li> */}
    
        {/* <Link to='/restaurantlist' className="nav-div">
            <a href="/restlist" className="pi pi-fw pi-cog "> </a><br />
            <span className="">Settings</span>
        </Link> */}
        <li onClick={onclick} className="nav-div">
            <a>
                <figure className="icon d-flex align-items-center justify-content-center flex-no-wrap transition">
                    {/* <i className="pi pi-fw pi-sign-out"></i> */}
                    <svg className="svgIcon2 " xmlns="http://www.w3.org/2000/svg" width="20.146" height="20.146" viewBox="0 0 20.146 20.146"><defs></defs><path className="a logout" d="M12.447,18.6l1.567,1.567,5.6-5.6-5.6-5.6-1.567,1.567,2.91,2.91H4.5v2.238H15.3ZM22.408,4.5H6.738A2.245,2.245,0,0,0,4.5,6.738v4.477H6.738V6.739h15.67v15.67H6.738V17.931H4.5v4.477a2.245,2.245,0,0,0,2.238,2.238h15.67a2.245,2.245,0,0,0,2.238-2.238V6.738A2.245,2.245,0,0,0,22.408,4.5Z" transform="translate(-4.5 -4.5)"/></svg>
                </figure>
                <span className="d-block">Log Out</span>
            </a>
        </li>
        </ul>

        <Dialog visible={dialog} style={{ width: '358px' }} onHide={() => onHide()}>
            <h3 className="logoutTtl">Are you sure, you want to log out?</h3>
            <br/>
            <span><button onClick={onHide} className="outline">No</button></span>

            <span><button onClick={(e) => { userSessionStore.logout(); history.replace('/logoutConformation'); onHide(); }} className="filled">Yes</button></span>
            <br/>

        </Dialog>
    </div>

}

export default SideNav




// (e) => { userSessionStore.logout(); history.replace('/login') }