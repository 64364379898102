import React, { Fragment } from 'react';
import { useHistory } from "react-router";
import './payment.css'
const Success = ()=>{
    const history = useHistory();

    return <Fragment>
        <div className="payment-s">
        <div className="breadcrum">
            <h2 onClick={()=>history.push("/adminprofile")} className="breadcrumText activeBreadcrum">my profile</h2 >
            <h2 className="breadcrumText"> &gt; </h2>
            <h2 onClick={()=>history.push("/MySubscriptions")} className="breadcrumText activeBreadcrum">manage subscription</h2>
            <h2 className="breadcrumText"> &gt; </h2>
            <h2 className="breadcrumText">Payment Confirmation</h2>
        </div>
        <hr className="hr" />
        <div className='dpayflex'>

            <div className="paycard">
            <i className="fas fa-check-circle green"></i>
            <h1>Payment Success</h1>
            <p>You have successfully upgraded to new plan.</p>
            {/* <h6>*For prototype purpose only : If payment failed.*</h6> */}
            </div>
            </div>
        </div>
    </Fragment>
}

export default Success