import React,{useState} from "react";
import Button from "react-bootstrap/Button";
import InputMask from 'react-input-mask';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
// import closeIcon from "./close-icon.svg";
import { ErrorMessage, Field, Formik, Form as FormI } from "formik";
import * as yup from "yup";
import valid from "card-validator";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import { useStore } from '../stores/useStore';
import { useHistory } from "react-router";
import Loader from '../components/Loader/loader';
import { getItem,removeItem } from "./storage";
import './paymentDetails.css';
import encrypt from './encrypt'
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";

// import moment from "moment";
const FormErrorMsg = styled.span`
  color: red;
  float: right;
  font-size: 12px;
`;
// const stripePromise = loadStripe("pk_test_TYooMQauvdEDq54NiTphI7jx");
toast.configure();

const PaymentInformation = () => {
    const [loader, setLoader] = useState(false);
    const history = useHistory();
  console.log("plan id : ",getItem('planId'));
  const validationSchema = yup.object().shape({
    cardnumber: yup
      .string()
      .required("Required")
      .test("test-number", "Invalid", (value) => valid.number(value).isValid),
    valid: yup
      .string()

      .required("Required")
      .test(
        "test-number",
        "Invalid",
        (value) => valid.expirationDate(value).isValid
      ),
    cvv: yup
      .string()
      .required("Required")
      .test("test-number", "Invalid", (value) => valid.cvv(value).isValid),
    namecard: yup
      .string()

      .required("Required"),
  });
  const { restaurantStore,userSessionStore } = useStore();

  const updateSub = (planid)=>{
    userSessionStore.updateSubscription(planid)
    .then(([success, data]) => {
        if(success){
          setLoader(false);
          removeItem('planId');
          console.log("update subscription data : ",data);
          history.push("/MySubscriptions");
        }
    })
}
  const addCard = (values) => {
    setLoader(true);
    const data = {
      collect_p:encrypt({
        card_number: values.cardnumber,
        exp_month: (values.valid).slice(0,2),
        exp_year: (values.valid).slice(3),
        cvc: values.cvv
      })
    }
      restaurantStore.addCard(data)
      .then(([success, message]) => {
        if (success) {
            console.log(message)
            updateSub(getItem('planId'));
        } else {
            console.log(message)
        }
    })
  }

  return (<>
    <div><Loader setLoader={setLoader} visible={loader} /></div> 
    <Formik
      initialValues={{
        cardnumber: "",
        valid: "",
        cvv: "",
        namecard: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        
        addCard(values)
      }}
      style={{ width: "50%" }}
    >
      {({ errors, status, touched, values }) => (
        <>
          <div className="breadcrum">
            <h2 onClick={() => history.push("/adminprofile")} className="breadcrumText activeBreadcrum">my profile</h2 >
            <h2 className="breadcrumText"> &gt; </h2>
            <h2 onClick={() => history.push("/MySubscriptions")} className="breadcrumText activeBreadcrum">manage subscription</h2>
            <h2 className="breadcrumText"> &gt; </h2>
            <h2 className="breadcrumText">Add card</h2>
          </div>
          <hr className="hr" />
          <FormI>

          <div className="paymentCont">
              <center>
                <br />
                <div className="mb-2 mt-3">
                  <Row className="mb-2">
                    <Col sm="12" className="text-left padding-none">
                      <Form.Label>CARD NUMBER</Form.Label>

                      <ErrorMessage
                        name="cardnumber"
                        component={FormErrorMsg}
                      />
                    </Col>
                    <Col sm="12" className="text-left  padding-none">
                      <Field
                        name="cardnumber"
                        as={InputMask}
                        maskChar={null}
                        type="text"
                        mask="9999 9999 9999 9999"
                        className={
                          errors.cardnumber && touched.cardnumber
                            ? " is-invalid form-control"
                            : "pr-0 comman-input form-control"
                        }
                      
                      />
                    </Col>
                  </Row>
                </div>

                <div>
                  <div className="mb-3">
                    <Row>
                      <Col sm="6" className="text-left padding-left">
                        <Form.Label>VALID TILL (MM/YY)</Form.Label>
                        <ErrorMessage name="valid" component={FormErrorMsg} />

                        <Field
                          name="valid"
                          className={
                            errors.valid && touched.valid
                              ? "is-invalid form-control"
                              : "pr-0 comman-input form-control"
                          }
                          as={InputMask}
                          mask="99/9999"
                          type="text"
                          maskChar={null}
                          placeholder="12/2023"
                        />
                      </Col>
                      <Col sm="6" className="text-left padding-right">
                        <Form.Label>CVV</Form.Label>
                        <ErrorMessage name="cvv" component={FormErrorMsg} />

                        <Field
                          name="cvv"
                          className={
                            errors.cvv && touched.cvv
                              ? " is-invalid form-control"
                              : "pr-0 comman-input form-control"
                          }
                          as={InputMask}
                          type="text"
                          maskChar={null}
                          placeholder="001"
                          mask="999"
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="mb-0">
                    <Row className="mb-2">
                      <Col sm="12" className="text-left padding-left">
                        <Form.Label> NAME ON THE CARD</Form.Label>
                        <ErrorMessage
                          name="namecard"
                          component={FormErrorMsg}
                        />

                      </Col>
                      <Col sm="12" className="text-right padding-right">
                        
                        <Field
                          name="namecard"
                          className={
                            errors.namecard && touched.namecard
                              ? " is-invalid"
                              : "pr-0 comman-input"
                          }
                          as={Form.Control}
                          type="text"

                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="mb-2">
                    <Row>
                      <Col sm={12} className="padding-none">
                        <div className="stripe-card-main">
                          <div className="left-col-name">Stripe</div>
                          <div className="right-content">
                            Your payment information are secure and not sharing
                            with third parties
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <br />
                    <Row>
                      <Button
                        className="outline"
                        onClick={() => {
                          removeItem('planId');
                          history.push('/MySubscriptions');
                        }}
                      >
                        BACK
                        </Button>
                      <Button
                        className="filled"
                        type="submit"
                      >
                        PAY NOW
                        </Button>
                    </Row>
                  </div>
                </div>
              </center>
          </div>
          </FormI>
        </>
      )}
    </Formik>
    </>
  );

}

export default PaymentInformation;
