import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import uItems from './floorplan.json'
function RenderFloorPlan() {

    //Starting----adjusting re-rendering element position to start from top-left corner with padding of 40px

    var leftLow = uItems.map(table => (
        parseInt(table.left)
    ))
    var topLow = uItems.map(table => (
        parseInt(table.top)
    ))
    const leftPo = Math.min.apply(Math, leftLow) - 40
    const topPo = Math.min.apply(Math, topLow) - 40

    //Ending----

    return (
        <Fragment>
            <div className="floorplan">
                <div className="container">
                    <div>
                        <Link className="create" to="/create-floor-plan">Create Floor Plan</Link>
                    </div>
                    <h3>Floor Plan:</h3>
                    <div
                        id="droparea"
                        className="canvas-box">

                        {uItems.map(table => (
                            <div style={{ position: table.position, top: (table.top - topPo) + 'px', left: (table.left - leftPo) + 'px' }}>
                            <div style={{position:"relative"}}>
                            <img height="70px" src={`assets/tables/${table.image_url}`} alt={table.image_url} />

                            </div>
                            <div
                                key={table.id}
                                className={table.ClassName}
                                onClick={() => alert("Hi vinay, you clicked on Table : " + table.tableName)}
                            >
                                {table.tableName}
                            </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Fragment>

    );
}
export default RenderFloorPlan