import React, { Fragment } from 'react'
let count = 1;
const Table = (props) => {
    const getPosition = async (e) => {
        e.preventDefault();
        //calculating percentage values of the X,Y positions
        let left = (Math.abs((e.pageX - 30) - props.cleft) / (props.dwidth)) * 100;
        let top = (Math.abs((e.pageY - 25) - props.ctop) / (props.dheight)) * 100;
        // let left = e.pageX;
        // let top = e.pageX;

        console.log("FloorID : ", props.floorId)
        //Assigning new values to an object
        const newValue = {
            "table_type_id": props.id,
            "table_type_name": props.table_type_name,
            "floor_id": props.floorId,
            // "ClassName": props.className,
            "table_shape": props.table_shape,
            // "position": "absolute",
            // "leftPer": e.pageX,
            // "topPer": e.pageY,
            "left_per": Math.floor(left),
            "top_per": Math.floor(top),
            "posx": e.pageX,
            "posy": e.pageY,
            "image_url": props.img,
            "rotate_angle":props.rotate_angle,
            "is_deleted_floor_plan":props.is_deleted,
            "alias":props.alias,
            "plan_id":0,
            "is_online":true,
            "track_id":props.id+"_"+count
        }
        //updating array with new values
        props.UpdateTable(newValue);
        console.log(newValue)
        count++
    }

    return (
        <Fragment>
            <div
                id={props.id}
                draggable
                className={props.table_shape}
                onDragEnd={e => { getPosition(e) }}
            >
                {props.table_type_name}
            </div>
        </Fragment>
    )
}

export default Table
