import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './settings.css'
import { useStore } from "../stores/useStore";
import { getItem } from './storage';

const Settings = () => {
    const { restaurantStore } = useStore()
    const [r_key, setRkey] = useState();

    useEffect(() => {
        restaurantStore.getRestaurantSettings(getItem('RestaurantId'))
            .then(([success, message]: any) => {
                if (success) {
                    setRkey(message?.restaurant_settings?.r_key)
                    console.log(message)
                    // return data
                } else {
                    console.log(message)
                }
            })
    }, [])
    const integrate = `<script src="tbs-button.js" TBS_LOGO_ID="{booking_widget_button_image_file_name}" TBS_ORG_ID="${getItem('RestaurantId')}"></script>`
    const [copy, setCopy] = useState(true);
    const helpMobile = "08000862252";
    const helpMail = "info@etakeawaymax.co.uk";




    const handelCopy = () => {
        navigator.clipboard.writeText(integrate)
        setCopy(false);
        setTimeout(() => {
            setCopy(true);
        }, 5000);
    }
    return <Fragment>
        <div className="settingsBody">
            <div className="breadcrum">
                <h2 className="breadcrumText">Settings</h2 >

            </div>
            <hr className="hr" />
            <div className="settingsCont">
                <Link to="/manage-features">
                    <div className="displayBtns">
                        <img className="settingsImg" src="./assets/images/Personal settings.svg" alt="manage Features" />
                        <p>Manage features</p>
                        <i className="pi pi-caret-right" />
                    </div>
                </Link>
                <Link to='/profile-settings'>
                    <div className="displayBtns">
                        <img className="settingsImg2" src="./assets/images/Time management.svg" alt="manage Features" />
                        <p>TIME &amp; AVAILABILITY</p>
                        <i className="pi pi-caret-right" />
                    </div>
                </Link>
            </div>
        </div>
        <div className="integration">
            <h3 className="scrhead">Integrate with website &lt;/&gt;</h3>
            <div className="script">
                <div className="schold">
                    {integrate}
                </div>
                <button disabled={!copy} style={{ color: !copy ? "#279137" : "black" }} onClick={handelCopy} className="copyTxt"><i className="far fa-copy"> </i> {copy ? "Copy" : "Copied"}</button>
            </div>
            <div className="instructions">
                <div><span>1</span> Open your website <b>Index.html</b> file using any editor</div>
                <div><span>2</span> Locate <b>&lt;/body&gt;</b> Tag</div>
                <div><span>3</span> Copy above table reservation script and paste before <b>&lt;/body&gt;</b></div>
                <div><span>4</span> Provide booking widget button image file in PNG format to our support team </div>
            </div>
            <div className="help">
                <p>Need help? Please contact our support team.</p>
                <div style={{ display: "flex", columnGap: "20px" }}>
                    <a href={`tel:${helpMobile}`}><i className="fas fa-phone-alt"></i> &nbsp;{helpMobile}</a>
                    <a rel="noopener noreferrer" target="_blank" href={`mailto:${helpMail}`}><i className="far fa-envelope"></i> &nbsp;{helpMail}</a>
                </div>
            </div>
        </div>
    </Fragment>
}

export default Settings
