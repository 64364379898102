import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from "react-router";
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import styled from 'styled-components';
import { Dialog } from 'primereact/dialog';
import * as yup from 'yup';
import "./manageRoleSettings.css";
import { InputMask } from 'primereact/inputmask';
import Coadmin from '../fakeApi/coAdmin.json';
import './manageWaiter.css';
import { useStore } from "../stores/useStore";
import Toaster from '../components/Toaster/toaster';
import { ToastContainer, toast } from 'react-toastify';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

// import { getItem,setItem } from './storage';


const FormErrorMsg = styled.span`
    color: red;
    float:right;
    font-size:12px;
   `
   toast.configure()
const ManageCoAdmin = () => {
	// const regex = /^\(?([6-9]{1})?([0-9]{9})$/;
	const history = useHistory();
	const [bi, setBI] = useState(true);
	const [Ttype, setTtype] = useState("");
	const [msg, setMsg] = useState("");
	const { restaurantStore } = useStore();
	const Goback = () => {
		history.goBack()
	}
	const [dialog, setDialog] = useState(false);

	const Showdeactivate = () => {
		setDialog(true);
	}
	const Deactivate = (adminId: any) => {
		console.log("User Deactivated", adminId);
		restaurantStore.deactivateUser(adminId)
			.then(([success, message]: any) => {
				if (success) {
					console.log("User Deactivated", message);
					// setTtype("success");
					// setMsg("Co-admin Deactivated");
					toast.success("Co-admin Deactivated")
					onHide();
					window.history.go(0);
				} else {
					// setTtype("error");
					// setMsg(message.msg);
					toast.error(message.msg)
					console.log("not working ", message)
				}
			})

	}
	const Activate = (adminId: any) => {
		console.log("User Activated", adminId);
		restaurantStore.activateUser(adminId)
			.then(([success, message]: any) => {
				if (success) {
					console.log("User Activated", message);
					// setTtype("success");
					// setMsg("Co-admin Activated");
					toast.success("Co-admin Activated")
					onHide();
					window.history.go(0);

				} else {
					// setTtype("error");
					// setMsg(message.msg);
					toast.error(message.msg)
					console.log("not working ", message)
				}
			})

	}

	const onHide = () => {
		setDialog(false);
	}

	const [coAdmin, setCoadmin] = useState([]);
	const coAdminList = () => {
		restaurantStore.getCoAdminList()
			.then(([success, message]: any) => {
				if (success) {
					console.log("co-admin list : ", message);
					setCoadmin(message)
				} else {
					console.log("not working : ", message)
				}
			})
	}
	const [searchCA, setSearchCA] = useState("");
	const searchCoAdmin = () => {
		let data = coAdmin.filter((name: any) => {
			if (searchCA === "") {
				console.log(coAdmin)
				return coAdmin
			}
			else {
				let f = (name.first_name).toLowerCase()
				let l = (name.last_name).toLowerCase()
				let fullN = f+l
				let check = searchCA.toLowerCase()
				let res:any = fullN.includes(check)?name.email:"No match Found";
				console.log(fullN,check,res)
				return res === name.email
			}
		})
		setCoadmin(data);
	}
	useEffect(() => {
		coAdminList();
		searchCoAdmin();
	}, [])

	const [showAdmin, setShowAdmin] = useState(true);
	const [coadmin, setCoAdmin] = useState();
	const [fName, setFname] = useState("");
	const [lName, setLname] = useState("");
	const [email, setEmail] = useState("");
	const [mobile, setMobile] = useState("");
	const [mobile2, setMobile2] = useState("");
	const [gender, setGender] = useState("");
	const [otp, setOtp] = useState('');
	const [showOtp, setShowOtp] = useState(true);
	const [showOtp2, setShowOtp2] = useState(true);
	const [adminId, setAdminId] = useState("");
	const [admin_id, setAdmin_id] = useState();
	const [is_active, setIs_active] = useState();

	const getOtp = () => {
		if (mobile.length === 13) {
			console.log("otp mobile", mobile)
			const num = mobile.replace("+", "%2B")
			console.log("otp mobile", num)
			restaurantStore.getOtp(num)
				.then(([success, message]: any) => {
					if (success) {
						console.log("otp : ", message.data);
						// setTtype("success");
						// setMsg(message);
						toast.success(message);
					} else {
						console.log("otp : ", message)
						// setTtype("error");
						// setMsg(message.msg);
						toast.error(message.msg)
					}
				})
		}
	}

	const onCancel=()=>{
		setBI(true);
		setShowOtp(true);
		setFname(coadmin.co_admin_info.first_name);
					setLname(coadmin.co_admin_info.last_name);
					setEmail(coadmin.co_admin_info.email);
					setMobile(coadmin.co_admin_info.mobile);
	}
	const verifyOtp = (e: any) => {
		setOtp(e.target.value);
		console.log(e.target.value)
		if (e.target.value.length === 6) {
			const num = mobile.replace("+", "%2B")
			restaurantStore.verifyOtp(num, e.target.value)
				.then(([success, message]: any) => {
					if (success) {
						console.log("otp verification : ", message);
						// setTtype("success");
						// setMsg(message);
						toast.success(message);
						setShowOtp2(true);
					} else {
						console.log("otp verification error : ", message)
						// setTtype("error");
						// setMsg(message.msg);
						toast.error(message.msg)
					}
				})
		}
	}

	const selectedAdmin = async (user: any) => {
		setOtp("");
		setShowOtp(true);
		setShowOtp2(true);
		setAdminId(user.id);
		setAdmin_id(user.user_id);
		restaurantStore.getUser2(user.id)
			.then(([success, message]: any) => {
				if (success) {
					setCoAdmin(message)
					setFname(message.co_admin_info.first_name);
					setLname(message.co_admin_info.last_name);
					setEmail(message.co_admin_info.email);
					setMobile(message.co_admin_info.mobile);
					setMobile2(message.co_admin_info.mobile);
					setGender(message.co_admin_info.gender);
					setIs_active(message.co_admin_info.is_active)
					setShowAdmin(false)
				} else {
					console.log("Error : ", message.msg)
				}
			})
		setCoAdmin(user)

	}
	const updateCoAdmin = () => {
		const data = {
			"first_name": fName,
			"last_name": lName,
			"email": email,
			"mobile": mobile,
			"gender": gender,
			"admin_id": adminId
			// "updated_by": getItem('role-id')
		}
		restaurantStore.updateAdmin(data)
			.then(([success, message]: any) => {
				if (success) {
					// setTtype("success");
					// setMsg("co-admin updated");
					toast.success("Co-admin Updated")
					setOtp("");
					coAdminList();
					setBI(true);
				} else {
					// setTtype("error");
					// setMsg(message);
					toast.error(message);
					console.log("Error of co-admin : ", message)
				}
			})
	}
	return <Fragment>
		<Toaster type={Ttype} text={msg} />
		<div id="content" className="position-relative  ">

			{/* <div className="page-section text-primary text-uppercase">
			<span className="text-black">my profile</span> <i className="fas fa-chevron-right text-black"></i> <span className="text-black">manage roles</span> <i className="fas fa-chevron-right"></i> manage co-admin
		</div> */}
			<div className="breadcrum">
				<h2 onClick={() => history.push('/adminprofile')} className="breadcrumText activeBreadcrum">my profile</h2 >
				<h2 className="breadcrumText"> &gt; </h2>
				<h2 onClick={Goback} className="breadcrumText activeBreadcrum">manage roles</h2 >
				<h2 className="breadcrumText"> &gt; </h2>
				<h2 className="breadcrumText">manage co-admin</h2>
			</div>
			<hr className="hr" />
			<div className="profile-wrap d-flex flex-wrap managew align-items-stretch">
				<div className="employee-list-profile">
					<form onSubmit={(e)=>{e.preventDefault();searchCoAdmin()}} action="">
						<Link to="createCoAdmin"><i className="btn d-block text-uppercase text-white primary-btn transition"><i className="fas fa-plus-circle fa-2x"></i>create co-admin</i></Link>
						<label htmlFor="" className="position-relative">
							<input  type="search" autoComplete="off" value={searchCA} onChange={(e: any) => { setSearchCA(e.target.value); coAdminList(); }} name="search-employee" id="search-employee" placeholder="Search Co-Admin" className="form-control" />
							<i style={{ cursor: "pointer" }} onClick={searchCoAdmin} className="fas fa-search fa-lg text-gray  searchIc"></i>
						</label>
					</form>

					<div className="employee-list custom-scrollbar bg-white" style={{ overflowY: 'scroll' }}>
						<ul>
							{coAdmin?.map(
								(users: any) => <li key={users.id} id={users.id} onClick={() => {selectedAdmin(users);setBI(true)}} className="Waiterlist ">{users.first_name} {users.last_name}<span style={{ float: "right", marginRight: "10px" }}>{users.is_active ? <i className="pi pi-check-circle green"></i> : <i className="pi pi-ban red"></i>}</span></li>
							)
							}
						</ul>

					</div>
				</div>
				{showAdmin ? <div className="showWaiter">
					<div>
						<p>Please select <b>Co-admin</b> to see their details.</p>
						<img src="assets/images/select-user.svg" alt="" />
					</div>
				</div>
					:
					<div className="profile-info">
						<div className="user-info-list">
							<div className="user-name text-black d-flex justify-content-between"><span>{fName} {lName}</span> {is_active ? <h6 onClick={Showdeactivate} className="ada red rhove">Deactivate</h6> : <h6 onClick={() => Activate(admin_id)} className="ada green ghove">Activate</h6>}</div>
							<Dialog visible={dialog} style={{ width: '358px' }} onHide={() => onHide()}>
								<h3 className="logoutTtl">Are you sure, you want to Deactivate</h3>
								<br />
								<span><button style={{ width: "70px", marginRight: "10px" }} onClick={onHide} className="outline">No</button></span>

								<span><button autoFocus style={{ width: "70px", height: "35px" }} onClick={() => Deactivate(admin_id)} className="filled">Yes</button></span>
								<br />

							</Dialog>
							<div className="designation text-gray">Co-Admin</div>

						</div>

						<div className="basic-info">
							<div className="section-title text-capitalize">
								<span className="text-black">basic information &nbsp;&nbsp;</span>
								<span onClick={() => setBI(false)} className="price-edit"><img className="price-editIcon" src="./assets/images/edit2.png" alt="" />&nbsp;EDIT</span>

							</div>
							<Formik
								initialValues={{ FirstName: "", LastName: "", Email: "", Mobile: "", Otp: "" }}
								// validationSchema={yup.object({
								// 	FirstName: yup.string()
								// 		.required('Required'),
								// 	LastName: yup.string()
								// 		.required('Required'),
								// 	Email: yup.string().email()
								// 		.required('Required'),
								// 	Mobile: yup.string()
								// 		.min(10, 'Required min 10 characters')
								// 		.required('Required')
								// 	// .matches(regex,"Enter a valid mobile number")
								// })}
								onSubmit={(values) => { updateCoAdmin(); }}
							>{({ errors, status, touched, values }) => (

								<Form>
									<div className='form-control-group hndl-inps'>
										<div className="div">
											<label>First Name</label>
											<ErrorMessage name='FirstName' component={FormErrorMsg} />
											<Field required as={InputText} autoComplete="off" minLength={3} maxLength={15} value={fName} onChange={(e: any) => { setFname(e.target.value); }}
												className={(errors.FirstName && touched.FirstName ? ' is-invalid' : 'minputtext')} type="text" name="FirstName" readOnly={bi} />
										</div>
										<div className="div">
											<label>Last Name</label>
											<ErrorMessage name='LastName' component={FormErrorMsg} />
											<Field required as={InputText} autoComplete="off" minLength={3} maxLength={15} value={lName} onChange={(e: any) => { setLname(e.target.value); }}
												className={(errors.LastName && touched.LastName ? ' is-invalid' : 'minputtext')} type="text" name="LastName" readOnly={bi} />
										</div>
										<div className="div">
											<label>Email</label>
											<ErrorMessage name='Email' component={FormErrorMsg} />
											<Field required as={InputText} autoComplete="off" type="email" name="Email" value={email} onChange={(e: any) => { setEmail(e.target.value); }}
												className={(errors.Email && touched.Email ? ' is-invalid' : 'minputtext')} readOnly={bi} />
										</div>
										<div className="div">
											<label>Mobile</label>
											<span id="phone"
                            style={{ display: "none",float:"right",fontSize:"14px" }}
                            className="float-right">Required</span>
											<div id="pinp" className="mobileInp">
												<Field  as={PhoneInput} required onlyCountries={['gb', 'in']}
								country={'gb'}  autoComplete="off" value={mobile} onChange={(e: any) => {
													if (mobile2 !== e) {
														setShowOtp(false);
														setShowOtp2(false);
													}
													else { setShowOtp(true); setShowOtp2(true); }

													if (e?.length <= 11) {
														let p = document.getElementById('phone')
														let pi = document.getElementById('pinp')
														p.style.color = "red"
														p.style.display = "initial"
														pi.style.border = "1px solid red"
													  }
													  else {
														let p = document.getElementById('phone')
														let pi = document.getElementById('pinp')
														p.style.display = "none"
														pi.style.border = "none"
						
													  }
													setMobile('+'+e)
												}}
												onBlur={(e:any) => {
													console.log("on blur phone", e.target.value)
													if (e.target.value?.length <= 3) {
														let p = document.getElementById('phone')
														let pi = document.getElementById('pinp')
														p.style.color = "red"
														p.style.display = "initial"
														pi.style.border = "1px solid red"
													}
													else {
														let p = document.getElementById('phone')
														let pi = document.getElementById('pinp')
														p.style.display = "none"
														pi.style.border = "none"
				
													}
												}}
													className={(errors.Mobile && touched.Mobile ? ' is-invalid' : '')} type="tel" name="Mobile" disabled={bi} />
												{/* <span hidden={showOtp} onClick={getOtp} className="MblOTP">GET OTP</span> */}
											</div>
										</div>
										{/* {!showOtp ?
											<div className="p-input">
												<label>OTP</label>
												<ErrorMessage name='Otp' component={FormErrorMsg} />
												<Field as={InputText} min="000001" max="999999" value={otp} onChange={(e: any) => {
													verifyOtp(e);
												}} autoComplete="off" type="number" name="Otp"
												//  disabled={showOtp2}
												 className={(errors.Otp && touched.Otp ? ' is-invalid' : 'minputtext')} />
											</div> : null} */}
									</div>
									<br />
									<Button type="submit"
									//  disabled={showOtp2 !== true}
									style={{ display: bi === true ? 'none' : 'initial', marginTop: "10px", marginLeft: "10px" }} className='filledSmall' label="save" />
									<Button type="button" onClick={onCancel} style={{ display: bi === true ? 'none' : 'initial', marginTop: "10px", marginLeft: "10px" }} className='outlineSmall' label="cancel" />
								</Form>
							)}
							</Formik>
							<div style={{ display: is_active ? "none" : "block" }} className="deactivated">
								<p className="deactivate-stamp">Deactivated</p>
							</div>
						</div>
					</div>}
			</div>
		</div>
	</Fragment>
}

export default ManageCoAdmin