import React, { Fragment, useEffect, useState } from 'react';
import './floorplan.css';
import Table from './table';
import { Link } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import styled from 'styled-components';
import { Dropdown } from 'primereact/dropdown';
import Loader from '../Loader/loader'
import { useStore } from "../../stores/useStore";
import Toaster from '../Toaster/toaster';
import { useHistory } from "react-router";
import { InputSwitch } from 'primereact/inputswitch';
import { ToastContainer, toast } from 'react-toastify';
import { getItem } from '../../pages/storage';
const FormErrorMsg = styled.span`
    color: red;
    float:right;
    font-size:12px;
   `

toast.configure()
function Create() {

    const [uItems, setUItems] = useState([]);
    const [isDropped, setIsDropped] = useState("false");
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)
    const [cTop, setCTop] = useState(0)
    const [cLeft, setcLeft] = useState(0)
    const [dialog, setDialog] = useState(false);
    const [dialog2, setDialog2] = useState(false);
    // const [showEdit, setShowEdit] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null)
    const { restaurantStore } = useStore()
    const [Ttype, setTtype] = useState("");
    const [msg, setMsg] = useState("");
    const history = useHistory();
    const [checked, setChecked] = useState(true);

    const onclick = () => {
        setDialog(true);
    }
    const [alias, setAlias] = useState("");
    const EditFloorTable = (table) => {
        setDialog2(true);
        setAlias(table.alias === (null || "null") ? table.table_type_name : table.alias);
        setChecked(table?.is_online)
        console.log("Editing table : ", table)
    }
    const EditFloortbl = (table) => {
        console.log("updating table 2 : ", table)

        const EditT = nTable => {
            var Utable = uItems.find(table => {
                if (table.plan_id > (0)) {
                    return table.plan_id === nTable.plan_id
                }
                else return (table.track_id === nTable.track_id)
            })
            console.log(uItems, nTable, Utable)
            Utable.alias = alias;
            Utable.is_online = (checked ? checked : false);
            Utable.plan_id = (Utable.plan_id ? Utable.plan_id : 0)
            const newTable = {
                "table_type_id": nTable.table_type_id,
                "table_type_name": nTable.table_type_name,
                "floor_id": nTable.floor_id,
                "table_shape": nTable.table_shape,
                "left_per": nTable.left_per,
                "top_per": nTable.top_per,
                "posx": nTable.posx,
                "posy": nTable.posy,
                "image_url": nTable.image_url,
                "rotate_angle": nTable.rotate_angle,
                "is_deleted_floor_plan": nTable.is_deleted_floor_plan,
                "alias": Utable.alias,
                "plan_id": Utable.plan_id,
                "is_online": Utable.is_online,
                "track_id": nTable.track_id
            }
            console.log(uItems, nTable)
            const NT = uItems.filter(table => {
                if (table.plan_id > 0) {
                    return table.plan_id !== nTable.plan_id
                }
                else return table.track_id !== nTable.track_id
            }).concat(newTable)
            setUItems(NT)
            console.log("Edited table : ", NT);
            // deleteTable(Utable);
        }
        EditT(table);

    }
    // start from here

    const onHide = () => {
        setDialog(false);
        setDialog2(false);
    }

    const allowDrop = (e) => {
        e.preventDefault();
    }
    const table = [

        { name: 'circle', value: 'circle' },
        { name: 'square', value: 'square' },
        { name: 'rectangle', value: 'rectangle' },
    ];

    const [selectedTable, setSelectedTable] = useState("");
    const [limit, setLimit] = useState();

    useEffect(() => {
        if (selectedTable === "circle") {
            setLimit(7);
        }
        else if (selectedTable === "square") {
            setLimit(4);
        }
        else if (selectedTable === "rectangle") {
            setLimit(14);
        }
    }, [selectedTable])

    const setTableType = (e) => {
        setSelectedTable(e.value);
    }

    const [loader, setLoader] = useState(false);

    //------Floor selecton------
    const [selectedFloor, setSelectedFloor] = useState();
    const [floorId, setFloorId] = useState(0);
    const setFloor = (e) => {
        setSelectedFloor(e.value);
        setFloorId(e.value.floor_id)
        console.log(e);
        FloorPlanList(e.value.floor_id);
    }

    //------Pushing new values to an array------
    const UpdateTable = (values) => {
        setUItems(oldValues => [...oldValues, values]);
        setIsDropped("true");
    }

    //------Downloading link for tables array as JSON file------
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(uItems));

    //------Getting Drop-container width,height,Top and Left positions------
    useEffect(() => {
        var po = document.querySelector("#droparea");
        setWidth(parseInt(po.offsetWidth));
        setHeight(parseInt(po.offsetHeight));
        setCTop(parseInt(po.offsetTop));
        setcLeft(parseInt(po.offsetLeft));
        // console.log(po.offsetWidth, po.offsetHeight, po.offsetTop, po.offsetLeft)
    }, [setWidth, setHeight, setCTop, setcLeft]);


    //------Rotate Table------
    const rotateTable = (e, Table) => {
        e.preventDefault();
        const rotate = nTable => {
            const table = uItems.find(table => {
                if (table.plan_id > (0)) {
                    return table.plan_id === nTable.plan_id
                }
                else return (table.track_id === nTable.track_id)
            });
            table.rotate_angle === 0 ? table.rotate_angle = 90 : table.rotate_angle = 0
            table.plan_id = (table.plan_id ? table.plan_id : 0)
            console.log("updated Floorid: ", nTable.floor_id, nTable)
            const newTable = {
                "table_type_id": nTable.table_type_id,
                "table_type_name": nTable.table_type_name,
                "floor_id": nTable.floor_id,
                "table_shape": nTable.table_shape,
                "left_per": nTable.left_per,
                "top_per": nTable.top_per,
                "posx": nTable.posx,
                "posy": nTable.posy,
                "image_url": nTable.image_url,
                "rotate_angle": table.rotate_angle,
                "is_deleted_floor_plan": nTable.is_deleted_floor_plan,
                "alias": nTable.alias,
                "plan_id": table.plan_id,
                "is_online": nTable.is_online,
                "track_id": nTable.track_id

            }
            const NT = uItems.filter(table => {
                if (table.plan_id > 0) {
                    return table.plan_id !== nTable.plan_id
                }
                else return table.track_id !== nTable.track_id
            }).concat(newTable)
            setUItems(NT)
            console.log(NT);
        }
        rotate(Table);
    }


    //------Getting tables left and top position------
    const getPosition = (e, Table) => {
        // e.preventDefault();
        let left = (Math.abs((e.pageX - 25) - cLeft) / (width)) * 100;
        let top = (Math.abs((e.pageY - 25) - cTop) / (height)) * 100;
        // let left = e.pageX;
        // let top = e.pageY;

        //------Updating new positions for dropped tables------
        const newPosition = nTable => {
            const table = uItems.find(table => {
                if (table.plan_id > (0)) {
                    return table.plan_id === nTable.plan_id
                }
                else return (table.track_id === nTable.track_id)
            });
            table.left = Math.floor(left);
            table.top = Math.floor(top);
            table.plan_id = (table.plan_id ? table.plan_id : 0);
            console.log("updateing table : ", nTable, table)
            const newTable = {
                "table_type_id": nTable.table_type_id,
                "table_type_name": nTable.table_type_name,
                "floor_id": nTable.floor_id,
                "table_shape": nTable.table_shape,
                "left_per": table.left,
                "top_per": table.top,
                "posx": e.pageX,
                "posy": e.pageY,
                "image_url": nTable.image_url,
                "rotate_angle": nTable.rotate_angle,
                "is_deleted_floor_plan": nTable.is_deleted_floor_plan,
                "alias": nTable.alias,
                "plan_id": table.plan_id,
                "is_online": nTable.is_online,
                "track_id": nTable.track_id
            }
            const NT = uItems.filter(table => {
                if (table.plan_id > 0) {
                    return table.plan_id !== nTable.plan_id
                }
                else return table.track_id !== nTable.track_id
            }).concat(newTable)
            setUItems(NT)
            // console.log(NT);
        }
        newPosition(Table);
        //------showing element on drag end in drop zone------
        let element = e.target;
        element.style.opacity = "1";

    }

    const DeleteTableType = (table) => {

        const DeleteT = nTable => {
            var Utable = uItems.find(table => {
                if (table.plan_id > (0)) {
                    return table.plan_id === nTable.plan_id
                }
                else return (table.track_id === nTable.track_id)
            });
            console.log("updated table 2 : ", Utable)
            Utable.is_deleted_floor_plan = true;


            const newTable = {
                "table_type_id": nTable.table_type_id,
                "table_type_name": nTable.table_type_name,
                "floor_id": nTable.floor_id,
                "table_shape": nTable.table_shape,
                "left_per": nTable.left_per,
                "top_per": nTable.top_per,
                "posx": nTable.posx,
                "posy": nTable.posy,
                "image_url": nTable.image_url,
                "rotate_angle": nTable.rotate_angle,
                "is_deleted_floor_plan": Utable.is_deleted_floor_plan,
                "alias": nTable.alias,
                "plan_id": Utable.plan_id,
                "is_online": nTable.is_online,
                "track_id": nTable.track_id
            }
            const NT = uItems.filter(table => {
                if (table.plan_id > 0) {
                    return table.plan_id !== nTable.plan_id
                }
                else return table.track_id !== nTable.track_id
            }).concat(newTable)
            setUItems(NT)
            console.log(NT);
            // deleteTable(Utable);
        }
        DeleteT(table);

    }

    // const deleteTable = (table)=>{
    //     let index = (uItems.indexOf(table))
    //     let dt = uItems.splice(index, 1);
    //     console.log("updated table: ",dt)
    // }

    //------hiding element on dragging in drop zone------
    const hideElement = (e) => {
        let element = e.target;
        element.style.opacity = "0.1";
    }

    //------Edit table------
    const editTable = (table) => {
        var showED = document.getElementById(`${"V" + (table.plan_id > 0 ? table.plan_id : table.track_id)}`);
        console.log(showED, table.plan_id, table.track_id)
        var display = showED.style.display;
        console.log(showED, table)
        if (display === 'none') {
            showED.style.display = 'flex';
        }
        else {
            showED.style.display = 'none';
        }
    }

    //------Edit Table Type------
    const editTableType = (table) => {
        var showED = document.getElementById(`${"N" + table.id}`);
        var display = showED.style.display;
        console.log(showED, table)
        if (display === 'none') {
            showED.style.display = 'flex';
        }
        else {
            showED.style.display = 'none';
        }
    }

    //------Delete Table Type------

    const DeleteTable = (table) => {
        restaurantStore.deleteTable(table.id)
            .then(([success, message]) => {
                if (success) {
                    console.log("Deleted Table", message)
                    toast.success("Table Deletion Successfull");
                    GetTables();

                } else {

                    toast.error(message);
                }
            })
    }


    //------Create Table type------
    const [ct, setCt] = useState(true);
    const createTable = (values) => {
        const table = {
            "table_type_name": values.tableName,
            "table_shape": values.tableType,
            "seating_capacity": values.partySize,
            "image_url": values.tableType + "_" + values.partySize + ".svg",
            "rest_id": getItem("RestaurantId")
        }
        restaurantStore.createTable(table)
            .then(([success, message]) => {
                if (success) {
                    console.log("Created Table", message)
                    toast.success("Table Creation Successfull");
                    GetTables();
                    // setTimeout(() => {
                    //     history.go(0);
                    // }, 3500);
                } else {
                    toast.error(message)
                }
            })
        console.log(table);
    }

    //------Table type listing------
    const [tables, setTables] = useState([{}]);
    const GetTables = () => {
        restaurantStore.getTableList()
            .then(([success, message]) => {
                if (success) {
                    const tables = message.filter((table) => table.is_deleted === false)
                    setTables(tables)
                    console.log("table types", message)
                } else {
                    toast.success(message);
                }
            })
    }
    useEffect(() => {
        GetTables();
    }, [])

    //------floors listing------
    const [FloorList, setFloorList] = useState([{}])
    const GetFloorsList = () => {
        restaurantStore.getFloorsList()
            .then(([success, data]) => {
                if (success) {
                    // console.log("Floors data", data.floors_list);
                    const floors = data.floors_list.filter((floor) => floor.is_deleted === false)
                    setFloorList(floors)
                } else {
                    toast.error(data);
                }
            })
    }
    useEffect(() => {
        GetFloorsList();
    }, [])

    const Applychanges = () => {
        var floorplanTable = uItems.map(tables => (
            {
                "floor_id": tables.floor_id,
                "table_type_id": tables.table_type_id,
                "posx": tables.posx,
                "posy": tables.posy,
                "left_per": tables.left_per,
                "top_per": tables.top_per,
                "rotate_angle": tables.rotate_angle,
                "alias": tables.alias,
                "is_deleted": tables.is_deleted_floor_plan,
                "plan_id": tables.plan_id !== undefined ? tables.plan_id : 0,
                "is_online": tables.is_online ? tables.is_online : false
            })
        );
        console.log("original floor", uItems)
        console.log("updated floor", floorplanTable)

        restaurantStore.saveFloorPlan(floorplanTable)
            .then(([success, message]) => {
                if (success) {
                    console.log("Created Floorplan", message)

                    toast.success("Floor Plan Saved Successfully")
                    GetFloorsList();
                    history.go(0);
                } else {
                    toast.error(message)

                    console.log("floorplan error message", message)
                }
            })
    }

    const FloorPlanList = (floor_id) => {
        setUItems([]);
        restaurantStore.getFloorPlan(floor_id)
            .then(([success, data]) => {
                if (success) {
                    const floor = data.filter(deleted => deleted.is_deleted_floor_plan === false)
                    setUItems(floor)

                    console.log("Fplist ", data)
                } else {
                    // setErrorMsg(data)
                    toast.error(data);
                    console.log("Fplist ", data)
                }
            })
    }
    const [isDesktop, setIsDesktop] = useState(true)
    useEffect(() => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            setIsDesktop(false)
        }
        else setIsDesktop(true)
    }, [])

    return (
        <Fragment>
            <Loader setLoader={setLoader} visible={loader} />
            <Toaster type={Ttype} text={msg} />

            <div className="breadcrum">
                <h2 className="breadcrumText">FLOOR PLAN</h2>
            </div>
            <hr className="hr" />
            <div className='addtbl'>
                <div className="managectmf">
                    {isDesktop &&
                        <div onClick={onclick} className="displayBtns">
                            <img className="settingsImg" src="./assets/images/manage-floor.svg" alt="manage Features" />
                            <p>create table</p>
                        </div>}

                    <Link to='/floor-plans'>
                        <div className="displayBtns">
                            <img className="settingsImg2" src="./assets/images/make-table.svg" alt="manage Features" />
                            <p>manage floors</p>
                        </div>
                    </Link>
                </div>
                <div className="floorOptions">
                    <Dropdown name="Floor" className="tableDrop" onChange={setFloor} value={selectedFloor} options={FloorList} optionLabel="name" placeholder="Select Floor" />
                    <span hidden={!isDesktop} onClick={() => history.go(0)} className="red">Cancel</span>
                    <span hidden={!isDesktop} onClick={Applychanges} className="green">Apply Changes</span>
                </div>
                <div className="floorplan">
                    <div className="container">
                        <h3 hidden={!isDesktop}>Drag and drop tables to create floor layout. <span className="dnote">*Doubleclick on tables to open/close options</span></h3>
                        {isDesktop &&
                            <div className="drag-area">
                                <div className='tableHolder'>
                                    {tables.map(table => (
                                        <div
                                            key={table.id}
                                            style={{ position: 'relative' }}
                                            onDoubleClick={() => editTableType(table)}
                                        >
                                            <div id={"N" + table.id} style={{ display: "none" }} className='editOptions2' >
                                                <span onClick={() => { DeleteTable(table); }}><i className="pi pi-trash red"></i></span>
                                                {/* <span onClick={() => EditFloorTable(table)}><i className="pi pi-pencil blue"></i></span> */}
                                            </div>
                                            <img height="70px" src={`assets/tables/${table.image_url}`} alt={table.image_url} />
                                            <Table
                                                id={table.id}
                                                key={table.id}
                                                className={table.table_shape}
                                                table_type_name={table.table_type_name}
                                                UpdateTable={UpdateTable}
                                                dwidth={width}
                                                dheight={height}
                                                ctop={cTop}
                                                cleft={cLeft}
                                                table_shape={table.table_shape}
                                                img={table.image_url}
                                                floorId={floorId}
                                                rotate_angle={0}
                                                is_deleted={table.is_deleted}
                                                alias={"null"}
                                                plan_id={0}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>}
                        <span className="dnote">{isDesktop ? "Note: Close options before you edit a new table" : "Floor plan customizations are permitted in Computer Browser only"}</span>
                        <div className='responsiveGrid'>
                            <div
                                id="droparea"
                                onDragOver={e => { allowDrop(e) }}
                                className="canvas-box">
                                {isDropped ? uItems.filter(deleted => deleted.is_deleted_floor_plan === false).map(table => (
                                    <div
                                        key={table.plan_id > 0 ? table.plan_id : table.track_id}
                                        style={{ position: "absolute", top: (table.top_per) + '%', left: (table.left_per) + '%' }}
                                        onDoubleClick={() => editTable(table)}
                                    >
                                        <div id={"V" + (table.plan_id > 0 ? table.plan_id : table.track_id)} style={{ display: "none" }} className='editOptions' >
                                            <span onClick={() => { DeleteTableType(table); }}><i className="pi pi-trash red"></i></span>
                                            <span onClick={() => EditFloorTable(table)}><i className="pi pi-pencil blue"></i></span>
                                            <span onClick={(e) => { rotateTable(e, table); console.log(table) }}><i className="pi pi-refresh orange"></i></span>
                                            <Dialog visible={dialog2} position="center" style={{ width: 'auto' }} onHide={() => onHide()}>
                                                <div>
                                                    <div className="cfhead">
                                                        <h3 style={{ color: "#510AEA" }}>EDIT TABLE </h3>
                                                        <i onClick={() => {
                                                            var showED = document.getElementById(`${"V" + (table.plan_id > 0 ? table.plan_id : table.track_id)}`);
                                                            var display = showED.style.display;
                                                            console.log(showED, table)
                                                            if (display === 'none') {
                                                                showED.style.display = 'flex';
                                                            }
                                                            else {
                                                                showED.style.display = 'none';
                                                            }
                                                            onHide();
                                                        }} className="pi pi-times close"></i>
                                                    </div>
                                                    <hr className='hr' />
                                                    <br />
                                                    <Formik
                                                        initialValues={{ floor: "" }}
                                                        // validationSchema={yup.object({
                                                        //     floor: yup.string()
                                                        //         .required('Required')
                                                        // })}
                                                        onSubmit={(values) => {
                                                            onHide();
                                                            console.log(values);

                                                            EditFloortbl(table);
                                                            var showED = document.getElementById(`${"V" + (table.plan_id > 0 ? table.plan_id : table.track_id)}`);
                                                            var display = showED.style.display;
                                                            console.log(showED, table)
                                                            if (display === 'none') {
                                                                showED.style.display = 'flex';
                                                            }
                                                            else {
                                                                showED.style.display = 'none';
                                                            }

                                                        }}
                                                    >
                                                        {({ errors, status, touched }) => (
                                                            <Form>
                                                                <div className="name">
                                                                    <label style={{ fontSize: "14px", color: "#000" }} >CHANGE TABLE NAME</label>
                                                                    <ErrorMessage name='floor' component={FormErrorMsg} /><br />
                                                                    <Field autoComplete="off" required maxLength="5" style={{ width: '300px', heigh: '35px !important' }} value={alias} onChange={(e) => setAlias(e.target.value)}
                                                                        className={(errors.floor && touched.floor ? ' is-invalid' : 'inpFld inpwd')} name='floor' as={InputText} />
                                                                    <br />
                                                                    <br />
                                                                </div>
                                                                <div className="p-field-checkbox">
                                                                    <InputSwitch name="check" checked={checked} onChange={e => setChecked(e.value)} />
                                                                    <label htmlFor="binary">Online Booking</label>
                                                                </div>
                                                                <div>
                                                                    <button className="calSearch" style={{ width: '100%', height: '45px', marginTop: '20px' }} >save</button>
                                                                </div>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                </div>
                                            </Dialog>
                                        </div>
                                        <div
                                            draggable
                                            style={{ position: 'relative' }}
                                            onDragEnd={(e) => getPosition(e, table)}
                                            onDragStart={(e) => hideElement(e)}
                                        >
                                            <img height="70px" style={{ transform: `rotate(${table.rotate_angle}deg)` }} src={`assets/tables/${table.image_url}`} alt={table.image_url} />

                                            <div
                                                key={(table.plan_id > 0 ? table.plan_id : table.track_id)}
                                                className={table.table_shape}
                                            >
                                                {table.alias === (null || "null") ? table.table_type_name : table.alias}
                                            </div>
                                        </div>
                                    </div>
                                )) : null}
                            </div>
                        </div>
                        {/* <div>
                            <a id="FloorPlan" className="Download" href={dataStr} download="FloorPlan.json">Download Floor Plan</a>
                            <Link className="view" to="/floor-plan">View Floor Plan</Link>
                        </div> */}
                    </div>
                </div>
                <Dialog visible={dialog} position="center" style={{ width: '700px' }} onHide={() => onHide()}>
                    <div className="createFloor">
                        <div className="cfhead">
                            <h3>Create Table</h3>
                            <i onClick={onHide} className="pi pi-times close"></i>
                        </div>
                        <hr className='hr' />
                        <br />
                        <Formik
                            initialValues={{ tableName: '', tableType: '', partySize: '' }}
                            validationSchema={yup.object({
                                tableName: yup.string()
                                    .required('Required'),
                                partySize: yup.string()
                                    .required('Required')
                                // .test("Enter even number", function (value) { if (value % 2 === 0) { return value } }),
                            })}
                            onSubmit={(values) => {
                                values.tableType = selectedTable;
                                if (values) {
                                    setDialog(false);
                                }
                                createTable(values);
                                console.log(values);
                                setSelectedTable('');
                            }}
                        >
                            {({ errors, status, touched }) => (<Form>
                                <div className="addTableBox">
                                    <div>
                                        <label>Table Name  <ErrorMessage name='tableName' component={FormErrorMsg} /></label>
                                        <br />
                                        <Field as={InputText} autoComplete="off" maxLength='5' style={{ width: '190px', marginTop: '5px', marginBottom: '5px' }}
                                            className={(errors.tableName && touched.tableName ? ' is-invalid' : 'inputtext')} type="text" name="tableName"
                                        />
                                    </div>
                                    <div>
                                        <label>Table Type <ErrorMessage name='tableType' component={FormErrorMsg} /></label>
                                        <br />
                                        <Dropdown name="tableType"
                                            className="tableDrop"
                                            onChange={(e) => {
                                                setTableType(e);
                                                if (table.filter(t => t.value === e.target.value)) { setCt(false) };
                                            }}
                                            value={selectedTable}
                                            options={table}
                                            optionLabel="name" placeholder="Select Type" />
                                    </div>
                                    <div>
                                        <label>Party Size  <ErrorMessage name='partySize' component={FormErrorMsg} /></label>
                                        <br />
                                        <Field id="partySize" title="Enter party size" as={InputText} min='2' max={limit} style={{ width: '190px', marginTop: '5px', marginBottom: '5px' }} autoComplete="off" type="number" name="partySize"
                                            className={(errors.partySize && touched.partySize ? ' is-invalid' : 'inputtext')}
                                        />
                                    </div>
                                    <br />
                                </div>
                                <br />
                                <div className="submitTable">
                                    <Button disabled={ct} type="submit" className='filledbtn' label="Create Table" />
                                </div>
                            </Form>
                            )}
                        </Formik>
                    </div>
                </Dialog>
            </div>
        </Fragment>
    );
}
export default Create;

