import React, { useState, useEffect, Fragment } from 'react';
import "./AdminDashboard.css";
import { Chart } from 'primereact/chart';
import { Dropdown } from 'primereact/dropdown';
import { useStore } from '../stores/useStore';
import { getItem } from './storage';
let count = 100

function AdminDashboard(props: any) {
    const [errorMsg, setErrorMsg] = useState(null);
    const [month, setMonth] = useState<any>([]);
    const [walkinCount, setWalkinCount] = useState<any>([]);
    const [bookingsCount, setBookingsCount] = useState<any>([]);
    const [cancelCount, setCancelCount] = useState<any>([]);
    const { restaurantStore } = useStore();

    const isValid = (getItem('RestaurantId')) === "undefined"


    const dashboardGraph = () => {
        restaurantStore.dashboardGraph()
            .then(([success, data]: any) => {
                if (success) {
                    setErrorMsg(null);
                    // console.log(data.dashboard_graph_list);
                    data.dashboard_graph_list.map((data: any) => (
                        setMonth((prev: any) => [...prev, data.month])
                    ))
                    data.dashboard_graph_list.map((data: any) => (
                        setWalkinCount((prev: any) => [...prev, data.walkins_count])
                    ))
                    data.dashboard_graph_list.map((data: any) => (
                        setBookingsCount((prev: any) => [...prev, data.bookings_count])
                    ))
                    data.dashboard_graph_list.map((data: any) => (
                        setCancelCount((prev: any) => [...prev, data.cancellations_count])
                    ))

                }
                else {
                    setErrorMsg(data.msg)
                }
            })
    }
    const [selectedDuration, setSelectedDuration] = useState();
    const duration = [
        { name: 'Last Week', code: 0 },
        { name: 'Last Month', code: 1 },
        { name: 'Last Year', code: 2 },
    ];

    const setDuration = (e: any) => {
        setSelectedDuration(e.value);
        summary(e.value.code);
        console.log(e.value)
    }

    const [summaryd, setSummaryd] = useState<any>();
    const summary = async (code: any) => {
        let dt
        if (code >= 0) {
            dt = code
        }
        await restaurantStore.dashboardSummary(dt)
            .then(([success, data]: any) => {
                if (success) {
                    setErrorMsg(null);
                    console.log(data)
                    setSummaryd(data.dashboard_summary_list)
                }
                else {
                    console.log(data)
                    setErrorMsg(data.msg)
                }
            })
    }
    const loadData = () => {
        dashboardGraph();
        summary(0);
    }
    useEffect(() => {
        loadData();
    }, [])

    if ((Math.max(...bookingsCount) || Math.max(...cancelCount) || Math.max(...walkinCount)) > count) {
        count = Math.max(...bookingsCount) + 100
    }

    const multiAxisData = {
        labels: month,
        datasets: [{
            label: 'Bookings',
            backgroundColor: '#3EAF4F',
            yAxisID: 'y-axis-1',
            data: bookingsCount
        },
        {
            label: 'cancelled bookings',
            backgroundColor: '#ED5200',
            yAxisID: 'y-axis-2',
            data: cancelCount
        },
        {
            label: 'walkins',
            backgroundColor: '#0387E8',
            yAxisID: 'y-axis-2',
            data: walkinCount
        },
        ]
    };


    let multiAxisOptions = {
        responsive: true,
        tooltips: {
            mode: 'index',
            intersect: true
        },
        scales: {
            xAxes: [{
                ticks: {
                    fontColor: '#495057'
                },
                gridLines: {
                    color: '#ebedef'
                }
            }],
            yAxes: [{
                type: 'linear',
                display: true,
                position: 'left',
                id: 'y-axis-1',
                ticks: {
                    min: 0,
                    max: count,
                    fontColor: '#495057'
                },
                gridLines: {
                    color: '#ebedef'
                }
            },
            {
                type: 'linear',
                display: false,
                position: 'right',
                id: 'y-axis-2',
                gridLines: {
                    drawOnChartArea: false,
                    color: '#ebedef'
                },
                ticks: {
                    min: 0,
                    max: count,
                    fontColor: '#495057'
                }
            }]
        },
        legend: {
            display: false,
            labels: {
                fontColor: '#495057'
            }
        }
    };


    return (
        <Fragment>

            {isValid ? <div className="empty-dashboard">
                <img width={400} src='assets/images/Work time-pana.svg' alt='loading animation' />
                <p>select Branch to get started</p>
            </div> :
                <div className="dashboard-admin">
                    <div className="breadcrum">
                        <h2 className="breadcrumText">Dashboard</h2 >
                    </div>
                    <hr className="hr" />

                    <div className="breadcrum">
                        <Dropdown style={{ fontSize: '20px' }} onChange={setDuration} value={selectedDuration} options={duration} optionLabel="name" placeholder={duration[0].name} />
                    </div>

                    <div className="dashboard">
                        <div className="card1">
                            <h2 className="CardHead">Bookings</h2>
                            <h1 className="CardNumber green">{summaryd?.bookings_count}</h1>
                            <svg className="cardSign" xmlns="http://www.w3.org/2000/svg" width="55.994" height="55.994" viewBox="0 0 55.994 55.994"><defs><clipPath id="a"><circle className="a" cx="11.5" cy="11.5" r="11.5" transform="translate(749.018 225.018)" /></clipPath></defs><g transform="translate(-500.003 -212.003)"><path className="b" d="M28,0A28,28,0,1,1,0,28,28,28,0,0,1,28,0Z" transform="translate(500.003 212.003)" /><g className="c" transform="translate(-232.018 4.982)"><path style={{ stroke: summaryd?.bookings_percentage >= 0 ? "#3eaf4f" : "#ED5200" }} className="d" d="M25.035,15.561H20.846L17.7,24.986,11.421,6.136,8.28,15.561H4.091" transform="translate(745.955 220.957)" /></g></g></svg>

                            <p className={summaryd?.bookings_percentage >= 0 ? "per green" : "per red"}>{summaryd?.bookings_percentage}%</p>

                        </div>
                        <div className="card2">
                            <h2 className="CardHead">CANCELLED BOOKINGS</h2>
                            <h1 className="CardNumber red">{summaryd?.cancellations_count}</h1>

                            <svg className="cardSign" xmlns="http://www.w3.org/2000/svg" width="55.994" height="55.994" viewBox="0 0 55.994 55.994"><defs><clipPath id="a"><circle className="a" cx="11.5" cy="11.5" r="11.5" transform="translate(749.018 225.018)" /></clipPath></defs><g transform="translate(-500.003 -212.003)"><path className="b" d="M28,0A28,28,0,1,1,0,28,28,28,0,0,1,28,0Z" transform="translate(500.003 212.003)" /><g className="c" transform="translate(-232.018 4.982)"><path style={{ stroke: summaryd?.cancellations_percentage >= 0 ? "#3eaf4f" : "#ED5200" }} className="d" d="M25.035,15.561H20.846L17.7,24.986,11.421,6.136,8.28,15.561H4.091" transform="translate(745.955 220.957)" /></g></g></svg>

                            <p className={summaryd?.cancellations_percentage >= 0 ? "per green" : "per red"}> {summaryd?.cancellations_percentage}%</p>

                        </div>
                        <div className="card3">
                            <h2 className="CardHead">WALKINs</h2>
                            <h1 className="CardNumber blue">{summaryd?.walkins_count}</h1>

                            <svg className="cardSign" xmlns="http://www.w3.org/2000/svg" width="55.994" height="55.994" viewBox="0 0 55.994 55.994"><defs><clipPath id="a"><circle className="a" cx="11.5" cy="11.5" r="11.5" transform="translate(749.018 225.018)" /></clipPath></defs><g transform="translate(-500.003 -212.003)"><path className="b" d="M28,0A28,28,0,1,1,0,28,28,28,0,0,1,28,0Z" transform="translate(500.003 212.003)" /><g className="c" transform="translate(-232.018 4.982)"><path style={{ stroke: summaryd?.walkins_percentage >= 0 ? "#3eaf4f" : "#ED5200" }} className="d" d="M25.035,15.561H20.846L17.7,24.986,11.421,6.136,8.28,15.561H4.091" transform="translate(745.955 220.957)" /></g></g></svg>

                            <p className={summaryd?.walkins_percentage >= 0 ? "per green" : "per red"}> {summaryd?.walkins_percentage}%</p>

                        </div>
                    </div>
                    <br />
                    <br />
                    <hr className="hr" />
                    <div className="Admingraph">
                        <div className="summary">
                            <h4>Summary <span>{errorMsg}</span></h4>
                            <div className="legends">
                                <p><span className="greenc circle">B</span> <span>Bookings</span></p>
                                <p><span className="redc circle">B</span> <span>Cancelled Bookings</span></p>
                                <p><span className="bluec circle">B</span> <span>Walkins</span></p>
                            </div>
                        </div>
                        <Chart type="bar" data={multiAxisData} options={multiAxisOptions} />
                    </div>

                </div>}

        </Fragment>
    )
}

export default AdminDashboard
