import { ErrorMessage, Field, Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from "primereact/button";
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';
import { useStore } from "../stores/useStore";

const FormErrorMsg = styled.span`
 padding-left: 0px;
 color: red;
 display: 'inline-block';
 @media (max-width: 40em) {
    padding-left: 0;
    position: relative;
    top: 4px;
 }
`

export const ScheduleList = observer((props) => {
    const { restaurantStore } = useStore()
    const [formData, setFormData] = useState({})
    const [errorMsg, setErrorMsg] = useState(null)
    const [visible, setVisible] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(false)
    const { id } = useParams();
    const [floorOptions, setFloorOptions] = useState([])
    const [resData, setResData] = useState({ id: null, name: '' })
    const [shiftData, setShiftData] = useState([])
    const history = useHistory()
    const rest = id

    useEffect(() => {
        setLoading(true)
        restaurantStore.getRestaurant(Number(rest))
            .then(([success, resdata]) => {
                if (success) {
                    setResData(resdata)
                }
                else {
                    setErrorMsg(resdata)
                }
            })

            .then(() => {
                return restaurantStore.getFloor(Number(rest))
                    .then(([success, data]) => {
                        if (success) {
                            let options = data.map((item: any) => ({ label: item.name, value: item.id }))
                            setFloorOptions(options)
                        }
                        else {
                            setErrorMsg(data)
                        }
                    })
            })
            .then(() => {
                return restaurantStore.getShiftList(Number(rest))
                    .then(([success, data]) => {
                        if (success) {
                            setShiftData(data)
                        }
                        else {
                            setErrorMsg(data)
                        }
                    })
            })
            .finally(() => {
                setLoading(false)
                setRefresh(false)
            })
    }, [refresh])

    const editTemplate = (rowData: any) => {
        return <a href='#' onClick={() => { onEditClick(rowData); return false }}>Edit</a>
    }

    const deleteTemplate = (rowData: any) => {
        return <a href='#' onClick={() => { onDeleteClick(rowData); return false }}>Delete</a>
    }

    const onNewClick = () => {
        setFormData({
            name: '',
            start_time: timeSlots[0].value,
            end_time: timeSlots[1].value,
            sun: false,
            mon: false,
            tue: false,
            wed: false,
            thu: false,
            fri: false,
            sat: false,
            rest_id: null,
            fp_ids: []
        })
        setEditMode(false)
        setVisible(true);
    }

    const daysTemplate = (rowData: any) => {
        const sun = (rowData.sun === true ? 'S' : '')
        const mon = (rowData.mon === true ? 'M' : '')
        const tue = (rowData.tue === true ? 'T' : '')
        const wed = (rowData.wed === true ? 'W' : '')
        const thu = (rowData.thu === true ? 'TH' : '')
        const fri = (rowData.fri === true ? 'F' : '')
        const sat = (rowData.sat === true ? 'SA' : '')
        const day = sun + ' ' + mon + ' ' + tue + ' ' + wed + ' ' + thu + ' ' + fri + ' ' + sat
        return <span>{day}</span>

    }

    const floorPlansTemplate = (rowData: any) => {
        const floor = (rowData.floorplans.map((item: any, index: any) => {
            return <span key={`${index}`}>{(index ? ', ' : '') + item.name}</span>
        }))
        return <span>{floor}</span>
    }

    const onEditClick = (rowData: any) => {
        let selected_fp_ids = rowData.floorplans.map((item: any) => item.id)
        setFormData({ id: rowData.id, name: rowData.name, start_time: rowData.start_time, end_time: rowData.end_time, sun: rowData.sun, mon: rowData.mon, tue: rowData.tue, wed: rowData.wed, thu: rowData.thu, fri: rowData.fri, sat: rowData.sat, fp_ids: selected_fp_ids })
        setEditMode(true)
        setVisible(true);
    }

    const onDeleteClick = (rowData: any) => {
        deleteConfirm(rowData)
    }

    const deleteConfirm = (rowData: any) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to delete this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        restaurantStore.deleteSchedule(rowData.id)
                            .then(([success, data]) => {
                                if (success) {
                                    setRefresh(true)
                                    //growl.current?.show({ severity: 'info', summary: 'Message', detail: `Guest ${rowData.name} deleted` })
                                }
                            })
                    }
                },
                {
                    label: 'No',
                    onClick: () => void (0)
                }
            ]
        });
    };

    const getTimeOptions = (start: any, end: any) => {
        let startTime = moment(start, 'HH:mm');
        let endTime = moment(end, 'HH:mm');
        let timeSlots = [];

        while (startTime <= endTime) {
            timeSlots.push(moment(startTime).format('HH:mm'));
            startTime.add(15, 'minutes');
        }
        return timeSlots.map((item: any) => ({ label: item, value: item }));
    }

    const timeSlots = getTimeOptions('00:00', '23:45');

    const menuItems = [
        { label: 'Restaurants', command: () => { history.push('/restaurantlist') } },
        { label: 'Schedule' },
    ]

    const home = { icon: 'pi pi-home', url: '/' }
    return (
        <>
            <BreadCrumb model={menuItems} home={home} />
            {loading ? <div>Loading... </div> : (
                <div className="p-grid p-fluid">
                    <div className="p-col-12">
                        <div className="card card-w-title">
                            <div className="p-grid p-justify-between">
                                <div className="p-col">
                                    <h1>{Object.values(resData)[0]}</h1>
                                </div>
                                <div className="p-col-4 p-sm-4 p-md-4 p-xl-2">
                                    <Button label="New Shift" className="p-button-primary" onClick={onNewClick} />
                                </div>
                            </div>
                            <div className='p-grid'>
                                <div className="p-col-12">
                                    {shiftData.length === 0 ? (
                                        <div>No Shift Available</div>
                                    ) : (
                                            <DataTable value={shiftData} responsive={true}  >
                                                <Column field="name" header="Name" />
                                                <Column field="start_time" header="Start Time" />
                                                <Column field="end_time" header="End Time" />
                                                <Column field="days" header="Days" body={daysTemplate} />
                                                <Column field="status" header="Status" />
                                                <Column field="floorplans" body={floorPlansTemplate} header="Floor Plan" />
                                                <Column field="edit" body={editTemplate} style={{ width: '80px' }} />
                                                <Column field="delete" body={deleteTemplate} style={{ width: '80px' }} />
                                            </DataTable>)}
                                </div>
                            </div>
                            <div className="p-grid">


                                <Dialog header={<span>{editMode ? 'Edit' : 'New'} Shift </span>} style={{ width: '40vw' }} visible={visible} onHide={() => setVisible(false)}>

                                    <Formik
                                        initialValues={formData}
                                        enableReinitialize={true}
                                        validationSchema={yup.object({
                                            name: yup.string()
                                                .required('Required')
                                                .max(30, 'Must be 30 characters or less'),
                                            start_time: yup.string()
                                                .required('Required'),
                                            end_time: yup.string()
                                                .required('Required')
                                                .test("is-greater", "end time should be greater", function (value) {
                                                    const { start_time } = this.parent;
                                                    return moment(value, "HH:mm").isSameOrAfter(moment(start_time, "HH:mm"));
                                                }),
                                            sun: yup.boolean(),
                                            mon: yup.boolean(),
                                            tue: yup.boolean(),
                                            wed: yup.boolean(),
                                            thu: yup.boolean(),
                                            fri: yup.boolean(),
                                            sat: yup.boolean(),
                                            fp_ids: yup.string()
                                                .required('Required'),
                                        })
                                            .test(
                                                'validateCheckBox',
                                                "null",
                                                (values: any) => {
                                                    if (values.sun || values.mon || values.tue || values.wed || values.thu || values.fri || values.sat) {
                                                        return true;
                                                    }
                                                    return new yup.ValidationError(
                                                        'Please select atleast one day',
                                                        null,
                                                        'sat'
                                                    );
                                                }
                                            )
                                        }

                                        onSubmit={(values: any, { setSubmitting }) => {
                                            if (editMode) {
                                                restaurantStore.updateShift(Number(values.id), values)
                                                    .then(([success, data]) => {
                                                        setVisible(false)
                                                        setRefresh(true)

                                                    })
                                            }

                                            else {
                                                restaurantStore.createShift(Number(rest), values)
                                                    .then(([success, data]) => {
                                                        setVisible(false)
                                                        setRefresh(true)
                                                    })
                                            }
                                        }}>

                                        {formik =>
                                            <Form>
                                                <div className="form-panel-content">
                                                    <div className="p-grid p-fluid">
                                                        <div className="p-col-8 error-msg">
                                                            {errorMsg}
                                                        </div>
                                                        <div className="p-col-12 shiftName-container">
                                                            <label>Name</label>
                                                            <Field name='name' autoComplete="off" as={InputText} />
                                                            <ErrorMessage name='name' component={FormErrorMsg} />
                                                        </div>

                                                        <div className="p-col-12 floorArea-container">
                                                            <label>Floor Area</label>
                                                            <Field name='fp_ids' as={MultiSelect} options={floorOptions} placeholder={floorOptions.length === 0 ? 'No Floor Area Available' : 'Select a Floor Area'} disabled={floorOptions.length === 0} />
                                                            <ErrorMessage name='fp_ids' component={FormErrorMsg} />
                                                        </div>

                                                        <div className="p-col-12 time-container">
                                                            <div className="p-grid p-fluid">
                                                                <div className="p-col-6 time-container">
                                                                    <label>Start Time</label>
                                                                    <Field name='start_time' as={Dropdown} options={timeSlots} />
                                                                    <ErrorMessage name='start_time' component={FormErrorMsg} />
                                                                </div>

                                                                <div className="p-col-6 time-container">
                                                                    <label>End Time</label>
                                                                    <Field name='end_time' as={Dropdown} options={timeSlots} />
                                                                    <ErrorMessage name='end_time' component={FormErrorMsg} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="p-col-12 days-container" >
                                                            <label> Days:</label>
                                                            <label style={{ padding: '8px' }}> Sun <Field type="checkbox" name="sun" /> </label>
                                                            <label style={{ padding: '8px' }}> Mon <Field type="checkbox" name="mon" /> </label>
                                                            <label style={{ padding: '8px' }}> Tue <Field type="checkbox" name="tue" /> </label>
                                                            <label style={{ padding: '8px' }}> Wed <Field type="checkbox" name="wed" /> </label>
                                                            <label style={{ padding: '8px' }}> Thu <Field type="checkbox" name="thu" /> </label>
                                                            <label style={{ padding: '8px' }}> Fri <Field type="checkbox" name="fri" /> </label>
                                                            <label style={{ padding: '8px' }}> Sat <Field type="checkbox" name="sat" /> </label>
                                                            <ErrorMessage name='sat' component={FormErrorMsg} />
                                                        </div>
                                                    </div>

                                                    <div className="p-grid p-fluid p-justify-start " style={{ paddingTop: '10px' }}>
                                                        <div className="p-col p-lg-4 button">
                                                            <Button type='submit' disabled={formik.isSubmitting} label={editMode ? 'Update' : 'Save'} />
                                                        </div>
                                                        <div className="p-col p-lg-4 button">
                                                            <Button type='button' onClick={() => { formik.resetForm(); setVisible(false) }} label="Cancel" className='p-button-secondary' />
                                                        </div>
                                                    </div>
                                                </div>

                                            </Form>
                                        }
                                    </Formik>
                                </Dialog>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
})

