import { action, computed, observable } from 'mobx';
import moment from 'moment';
import apiClient from './apiClient';
import { getItem, setItem } from '../pages/storage'

let access = getItem('access');

export class RestaurantStore {

    //Dashboard---------------------------------
    @observable
    id: any = getItem('RestaurantId')

    @action
    dashboardGraph() {
        return apiClient.get(`/api/v1/admin/dashboard/graph?rest_id=${getItem('RestaurantId')}`)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }
    @action
    dashboardSummary(data: any) {
        return apiClient.get(`/api/v1/admin/dashboard/summary?rest_id=${getItem('RestaurantId')}&filter_type=${data}`)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    @action
    getRestaurantList() {
        return apiClient.get(`/api/v1/admin/admin/${getItem("role-id")}`)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    @action
    createRestaurant(data: any) {
        return apiClient.post('/api/v1/rest/', data)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    @action
    // createWaiter(data:any){
    //     return apiClient.post('/api/v1/waiter/',data)
    //     .then(action((res:any)=>{
    //         return [true, res.data]
    //     }))

    //     .catch(action(err=>{
    //         let errorMsg = err.response? err.response.data.detail :err.message
    //         return [false, errorMsg]
    //     }))
    // }

    @action
    createWaiter(firstname: string, lastname: string, email: string, mobile: string, gender: string) {
        let waiter = {
            'first_name': firstname,
            'last_name': lastname,
            'email': email,
            'mobile': mobile,
            'gender': gender,
            'rest_id': getItem('RestaurantId')
        }
        return apiClient.post('/api/v1/waiter/create', waiter, {
            headers: {
                Authorization: 'Bearer ' + access,
            }
        })
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    @action
    createHoliday(data: any) {

        return apiClient.post('/api/v1/restaurant_settings/time_availability/holiday', data)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    @action
    blockRest(startdate: string, enddate: string, starttime: string, endtime: string) {
        let reservation =
        {
            blocked_from: startdate,
            blocked_to: enddate,
            is_blocked_all_day: true,
            blocked_time_from: starttime,
            blocked_time_to: endtime,
            note: "Reservations blocked",
            rest_id: getItem('RestaurantId')
        }
        return apiClient.post('/api/v1/restaurant_settings/block_restaurant', reservation)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }






    @action
    getWaiter(waiter_id: number) {
        return apiClient.get(`/api/v1/waiter/details/${waiter_id}`)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }
    @action
    updateAdmin(data: any) {
        return apiClient.put('/api/v1/admin/update', data, {
            headers: {
                Authorization: 'Bearer ' + access,
            }
        })
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }
    @action
    activateUser(user_id: any) {
        return apiClient.put(`/api/v1/activate/${user_id}`)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }
    @action
    deactivateUser(user_id: any) {
        return apiClient.put(`/api/v1/deactivate/${user_id}`)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }
    @action
    updateWaiter(waiter: any) {
        return apiClient.put(`/api/v1/waiter/update`, waiter, {
            headers: {
                Authorization: 'Bearer ' + access,
            }
        })
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }
    // @action
    // getCoAdminList() {
    //     return apiClient.get(`/api/v1/admin/co_admin/list/${getItem('RestaurantId')}`, {
    //         headers: {
    //             Authorization: 'Bearer ' + getItem('access')
    //         }
    //     })
    //         .then(action((res: any) => {
    //             return [true, res.data]
    //         }))
    //         .catch(action(err => {
    //             let errorMsg = err.response ? err.response.data.detail : err.message
    //             return [false, errorMsg]
    //         }))
    // }

    @action
    getCoAdminList() {
        return apiClient.get(`/api/v1/admin/co_admin/list/${getItem('RestaurantId')}`, {
            headers: {
                Authorization: 'Bearer ' + getItem('access')
            }
        })
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }
    @action
    createAdmin(firstname: string, lastname: string, email: string, mobile: string, gender: string) {
        let coAdmin = {
            'first_name': firstname,
            'last_name': lastname,
            'email': email,
            'mobile': mobile,
            'gender': gender,
            'rest_id': getItem('RestaurantId'),
            'org_id': getItem('org-id'),
            'created_by': getItem('user-id')
        }
        return apiClient.post('/api/v1/admin/create_co_admin', coAdmin)
            .then(action((res: any) => {
                console.log("Co-admin created --", coAdmin);
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }


    @action
    addBranch(data: any) {
        return apiClient.post(`/api/v1/restaurant/create/`, data)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }
    @action
    updateSettings(data: any) {
        return apiClient.put(`/api/v1/restaurant/update`, data)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }
    @action
    updateWeekSchedule(data: any) {
        return apiClient.put(`/api/v1/restaurant_settings/update_week_schedule`, data)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }
    @action
    getUser(admin_id: any) {
        return apiClient.get(`/api/v1/admin/admin/${admin_id}`)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }
    getUser2(admin_id: any) {
        return apiClient.get(`/api/v1/admin/co_admin/${admin_id}`)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    @action
    createShifts(shiftname: string, starttime: string, endtime: string, shiftAvailability: any) {
        let Shifts = {
            'name': shiftname,
            'start_time': starttime,
            'end_time': endtime,
            'rest_id': getItem('RestaurantId'),
            'days_applicable': JSON.stringify(shiftAvailability),
        }
        console.log('shift creation dat :', Shifts)
        return apiClient.post('/api/v1/shift/create', Shifts, {
            headers: {
                Authorization: 'Bearer ' + access,
            }
        })
            .then(action((res: any) => {
                console.log("shifts created --", Shifts);
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }
    @action
    updateShifts(id: any, name: string, starttime: string, endtime: string, shiftAvailability: any) {
        let shifts = {
            'name': name,
            'start_time': starttime,
            'end_time': endtime,
            'shift_id': id,
            'days_applicable': JSON.stringify(shiftAvailability),
        }
        return apiClient.put(`/api/v1/shift/update`, shifts, {
            headers: {
                Authorization: 'Bearer ' + access,
            }
        })
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }
    @action
    deleteShift(shiftid: any) {
        return apiClient.put(`/api/v1/shift/delete?shift_id=${shiftid}`, {}, {
            headers: {
                Authorization: 'Bearer ' + access,
            }
        })
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    @action
    getRestaurant(id: number) {
        return apiClient.get(`/api/v1/rest/${id}`)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    @action
    blockReservation() {
        let blockObj = {

            'note': "",
            'rest_id': getItem('RestaurantId'),
        }
        return apiClient.post(`/api/v1/restaurant_settings/block_restaurant`, blockObj)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }
    @action
    unblockReservation() {
        return apiClient.put(`/api/v1/restaurant_settings/unblock_restaurant/${this.id}`)
            .then(action((res: any) => {

                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    @action
    updateRestaurant(data: any) {
        return apiClient.put(`/api/v1/restaurant/update`, data)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    @action
    deleteRestaurant(id: number) {
        return apiClient.delete(`/api/v1/rest/${id}`)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }


    @action
    deleteHoliday(id: number) {
        return apiClient.put(`/api/v1/restaurant_settings/time_availability/holiday/delete/${id}`)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    @action
    publishRestaurant(id: number) {
        return apiClient.put(`/api/v1/rest/publish/${id}`)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }


    // Floorplan-----------------------------
    @action
    deleteFloor(id: number) {
        return apiClient.put(`/api/v1/floor/delete/${id}`, {}, {
            headers: {
                Authorization: 'Bearer ' + access,
            }
        })
            .then(action((res: any) => {
                return [true, res]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    @action
    createFloor(rest: number, data: any) {
        return apiClient.post(`/api/v1/fp/${rest}`, data, {
            headers: {
                Authorization: 'Bearer ' + access,
            }
        })
            .then(action((res: any) => {
                return [true, res.data]

            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))

    }

    @action
    createNewFloor(floor: any) {
        return apiClient.post(`/api/v1/floor/`, { "name": floor, "rest_id": getItem('RestaurantId') }, {
            headers: {
                Authorization: 'Bearer ' + access,
            }
        })
            .then(action((res: any) => {
                console.log("created floor : ", res)
                return [true, res]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    @action
    saveFloorPlan(data: any) {
        return apiClient.post(`/api/v1/floorplan/`, data)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))

    }
    @action
    getFloorPlan(floor_id: any) {
        return apiClient.get(`/api/v1/floorplan/get/${floor_id}`)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }


    @action
    getFloor(rest: number) {
        return apiClient.get(`/api/v1/fp/${rest}/`)

            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))

    }

    @action
    updateFloor(data: any) {
        return apiClient.put(`/api/v1/floor/update`, data, {
            headers: {
                Authorization: 'Bearer ' + access,
            }
        })
            .then(action((res: any) => {
                return [true, res]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    @action
    deleteFloorPlan(id: number) {
        return apiClient.delete(`/api/v1/fp/${id}`)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }
    // Table --------------------------------------------------------------------
    @action
    getTableList() {
        return apiClient.get(`/api/v1/table_type/list/${getItem("RestaurantId")}`)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    @action
    getWeeklist() {
        return apiClient.get(`/api/v1/restaurant_settings/get_week_schedule?rest_id=${3}`)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    @action
    getHolidayList() {
        return apiClient.get(`/api/v1/restaurant_settings/time_availability/holiday/${getItem("RestaurantId")}`)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    @action
    createTable(data: any) {
        return apiClient.post(`/api/v1/table_type/create`, data)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    @action
    getTable(id: number) {
        return apiClient.get(`/api/v1/table/${id}`)
            .then(action((res: any) => {

                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    @action
    updateTable(id: number, data: any) {
        return apiClient.put(`/api/v1/table/${id}`, data)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    @action
    deleteTableType(id: number) {
        return apiClient.put(`/api/v1/table_type/delete/${id}`)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }
    @action
    deleteTable(id: number) {
        return apiClient.put(`/api/v1/table_type/delete/${id}`)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    //  Guestbook----------------------------------------------------
    @action
    getGuestList() {
        return apiClient.get(`/api/v1/guest/restaurant/${getItem('RestaurantId')}`)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    @action
    createGuest(data: any) {
        return apiClient.post('/api/v1/guest/', data)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    @action
    getGuest(id: number) {
        return apiClient.get(`/api/v1/guest/${id}`)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    @action
    updateGuestBook(id: number, data: any) {
        return apiClient.put(`/api/v1/guest/${id}`, data)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }
    @action
    deleteGuest(id: number) {
        return apiClient.delete(`/api/v1/guest/${id}`)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }





    //  Reservation ----------------------------------------------------

    @action
    getReservationList(date: any, search: any, page: any) {
        return apiClient.get(`/api/v1/reservation/list_reservation_by_date/?rest_id=${getItem('RestaurantId')}&booking_date=${date}&search_text=${search}&page=${page}&size=100`)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    @action
    getFloorsList() {
        return apiClient.get(`/api/v1/floor/list/${getItem('RestaurantId')}`)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }


    @action
    createReservation(data: any) {
        return apiClient.post('/api/v1/reserv/', data)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    @action
    getReservation(id: number) {
        return apiClient.get(`/api/v1/reserv/${id}`)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }




    @action
    getRestaurantSettings(id: any) {
        return apiClient.get(`/api/v1/restaurant/settings/${id}`)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }
    @action
    stripeOnBoard() {
        return apiClient.post(`/api/v1/admin/stripe/on_boarding_link/create`, {
            headers: {
                Authorization: 'Bearer ' + access,
            }
        })
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }
    @action
    getRestaurantSettings2(id: any) {
        return apiClient.get(`/api/v1/restaurant/settings/${id}`)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    @action
    updateReservation(id: number, data: any) {
        return apiClient.put(`/api/v1/reserv/${id}`, data)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    @action
    deleteReservation(id: number) {
        return apiClient.delete(`/api/v1/reserv/${id}`)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    @action
    getTimeSlots(restId: number, resvDate: Date) {
        return apiClient.get(`/api/v1/reserv/timeslot/${restId}/${moment(resvDate).format('YYYY-MM-DD')}`)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    // ---Shift---------------------------------------------------

    @action
    getShiftList(rest: number) {
        return apiClient.get(`/api/v1/shift/${rest}/`)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }
    @action
    getShifts() {
        return apiClient.get(`/api/v1/shift/list?rest_id=${getItem('RestaurantId')}`)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }
    @action
    createShift(rest: number, data: any) {
        return apiClient.post(`/api/v1/shift/${rest}`, data)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    @action
    updateShift(id: number, data: any) {
        return apiClient.put(`/api/v1/shift/update/${id}`, data)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    @action
    deleteSchedule(id: number) {
        return apiClient.delete(`/api/v1/shift/${id}`)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }


    @action
    deleteShifts(id: number) {
        return apiClient.delete(`/api/v1/shift/${id}`)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }


    @action
    restaurantData(data: any) {
        setItem('RestaurantId', data);
        console.log(" --------- Restaurant Id: ", getItem('RestaurantId'), " --------- ");
    }
    @action
    getOtp(mobile: any) {
        return apiClient.post(`/api/v1/otp/sendotp?mobile_number=${mobile}`)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }
    @action
    verifyOtp(mobile: any, otp: any) {
        console.log(mobile, otp)
        return apiClient.post(`/api/v1/otp/validate?mobile=${mobile}&otp=${otp}`)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    @action
    uploadImg(data: any) {
        console.log("image data passing to endpoint :", data)
        return apiClient.post('/api/v1/image_upload/', data, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    @action
    planDetails() {
        return apiClient.get('/api/v1/stripe/products_list')
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }
    @action
    addCard(data: any) {
        return apiClient.post('/api/v1/stripe/add_card/admin', data, {
            headers: {
                Authorization: 'Bearer ' + access,
            }
        })
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }
}
