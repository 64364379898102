import "./ChangePassword.css";
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from "primereact/button";
import { InputText } from 'primereact/inputtext';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from "react-router";
import * as yup from 'yup';
import { useStore } from '../stores/useStore';
import Toaster from '../components/Toaster/toaster'
const FormErrorMsg = styled.span`
 color: red;
 float:right;
 font-size:12px;
`
const ChangePassword = () => {
    const history = useHistory();
    const { userSessionStore } = useStore();
    const [Ttype, setTtype] = useState("");
    const [msg, setMsg] = useState("");

    const Goback = () => {
        history.goBack()
    }
    return <div>
        <Toaster type={Ttype} text={msg} />
        <div className="breadcrum">
            <h2 onClick={Goback} className="breadcrumText activeBreadcrum">my profile</h2 >
            <h2 className="breadcrumText"> &gt; </h2>
            <h2 className="breadcrumText">manage password</h2>
        </div>
        <hr className="hr" />
        <Formik
            initialValues={{ currentpassword: '', newpassword: '', cnfmpassword: '' }}
            validationSchema={yup.object({
                currentpassword: yup.string().min(5, "should be more then 5 characters")
                    .required('Required'),
                newpassword: yup.string().min(5, "should be more then 5 characters")
                    .notOneOf([yup.ref('currentpassword'), null], 'Should be different from current password')
                    .matches(/[a-zA-Z]/, 'Password should contain atleast one Alphabet')
                    .required('Required'),
                cnfmpassword: yup.string()
                    .notOneOf([yup.ref('currentpassword'), null], 'Should be different from current password')
                    .matches(/[a-zA-Z]/, 'Password should contain atleast one Alphabet')
                    .oneOf([yup.ref('newpassword'), null], 'Not a match')
                    .required('Required'),
            })}
            onSubmit={(values: any, { resetForm }) => {
                console.log(values);
                userSessionStore.changePassword(values.currentpassword, values.newpassword)
                    .then(([success, message]: any) => {
                        if (success) {
                            resetForm({ values: '' })
                            setTtype("success");
                            setMsg("Change Password successful !");
                            setTimeout(() => {
                                userSessionStore.logout(); history.replace('/login');
                            }, 4000);
                        } else {
                            setMsg(message);
                            setTtype("error");

                        }
                    })
            }}
        >
            {({ errors, status, touched }) => (
                <Form>
                    <div className='pContent'>
                        {/* isChecked: yup.bool() */}

                        <h2 className="changeP" style={{ fontSize: '20px' }}>Change Password </h2>
                        <hr className="hr" />
                        <br />
                        <label style={{ fontSize: '14px' }}>Current Password</label>
                        <ErrorMessage name='currentpassword' component={FormErrorMsg} />
                        <Field style={{ marginTop: "10px", marginBottom: "20px" }} maxLength="20" autoComplete="off" type='password' name='currentpassword'
                            className={(errors.currentpassword && touched.currentpassword ? ' is-invalid' : 'fld')} as={InputText} />
                        <br />

                        <label style={{ fontSize: '14px' }}>Enter New Password</label>
                        <ErrorMessage name='newpassword' component={FormErrorMsg} />
                        <Field maxLength="20" style={{ marginTop: "10px", marginBottom: "20px" }} autoComplete="off" type='password'
                            className={(errors.newpassword && touched.newpassword ? ' is-invalid' : 'fld')} name='newpassword' as={InputText} />
                        <br />

                        <label style={{ fontSize: '14px' }}>Confirm New Password</label>
                        <ErrorMessage name='cnfmpassword' component={FormErrorMsg} />
                        <Field maxLength="20" style={{ marginTop: "10px", marginBottom: "20px" }} autoComplete="off"
                            className={(errors.cnfmpassword && touched.cnfmpassword ? ' is-invalid' : 'fld')} type='password' name='cnfmpassword' as={InputText} />
                        <br />
                        <br />
                        <Button type='submit' className="sbt-btn" label="CHANGE PASSWORD" />
                    </div>
                </Form>
            )}
        </Formik>
    </div>

}


export default ChangePassword