import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory } from "react-router";
import { Dialog } from 'primereact/dialog';
import './MySubscriptions.css'
import { useStore } from '../stores/useStore';
import Loader from '../components/Loader/loader';
import Subscribe from './stripe';
import { setItem } from './storage';

import { InputSwitch } from 'primereact/inputswitch';

const MySubscriptions = () => {
    const history = useHistory();
    const { restaurantStore, userSessionStore } = useStore();
    const [loader, setLoader] = useState(false);
    const [checked, setChecked] = useState(false);

    const Goback = () => {
        history.goBack()
    }
    const [dialog, setDialog] = useState(false)
    const [dialog2, setDialog2] = useState(false)
    const [subID, setSubid] = useState()
    const onclick = () => {
        setDialog(true);
    }

    const onHide = () => {
        setDialog(false);
        setDialog2(false);
    }
    const [plans, setPlans] = useState([]);
    useEffect(() => {
        restaurantStore.planDetails()
            .then(([success, message]: any) => {
                if (success) {
                    let plan = message
                    setPlans(plan)
                    console.log(message)

                } else {
                    console.log(message)
                }
            })
    }, [])

    const [subdata, setSubdata] = useState();
    const [trns, setTrns] = useState();

    const subscriptiondata = () => {
        userSessionStore.subscriptiondata()
            .then(([success, data]: any) => {
                if (success) {
                    console.log("subscription data : ", data.data)
                    setSubdata(data.data)
                    setChecked(data.data.subscription_details.subscription_auto_renewal)
                }
            })
    }
    const transactiondata = () => {
        setLoader(true)
        userSessionStore.transactiondata()
            .then(([success, data]: any) => {
                if (success) {
                    setLoader(false)
                    setTrns((data?.data?.transactions_data)?.reverse())
                    console.log("transction data : ", data.data)
                }
            })
    }
    useEffect(() => {
        transactiondata();
        subscriptiondata();
    }, [])

    const updateSub = (planid: any) => {
        setLoader(true)
        userSessionStore.updateSubscription(planid)
            .then(([success, data]: any) => {
                if (success) {
                    setLoader(false)
                    onHide();
                    transactiondata();
                    subscriptiondata();
                    console.log("update subscription data : ", data)
                }
            })
    }
    const TakeSub = (planid: any) => {
        setLoader(true)
        userSessionStore.takeSubscription(planid)
            .then(([success, data]: any) => {
                if (success) {
                    subscriptiondata();
                    transactiondata();
                    setTimeout(() => {
                        setLoader(false)
                        onHide();
                        history.go(0)
                    }, 1000);

                    console.log("update subscription data : ", data)
                }
            })
    }
    const autorenewal = (ar: any) => {
        console.log("auto renewal : ", ar ? 1 : 0)
        userSessionStore.autorenewal(ar ? 1 : 0)
            .then(([success, data]: any) => {
                if (success) {
                    console.log("Auto renewal success", data)
                }
                else console.log("error", data)
            })
    }
    const cancelSub = (e: any) => {
        e.preventDefault();
        console.log("sub id", subID)
        // {subscription_id:subID}
        userSessionStore.cancleSubscription()
            .then(([success, data]: any) => {
                if (success) {
                    setLoader(false)
                    onHide();
                    window.history.go(0)
                    console.log("update subscription data : ", data)
                }
                else console.log("Cancel sub Error : ", data)
            })

    }
    const handleSubscription = (planID: any, plan: any, e: any, mail: any, card: any) => {
        e.preventDefault();
        console.log("subscription status : ", subdata?.subscription_details.subscription_status)
        if (plan === "Free Plan") {
            // Subscribe(planID,mail);
            if (card) {
                updateSub(planID)
            }
            else {
                setItem('planId', planID);
                history.push('/AddCard');
            }
        }
        else {
            if (subdata?.request_status === "Accepted" || subdata?.subscription_details.subscription_status === "Expired") {
                TakeSub(planID)
            }
            else {
                updateSub(planID)
            }
        }
    }


    return <div className="subscriptions">
        <div><Loader setLoader={setLoader} visible={loader} /></div>
        <div className="breadcrum">
            <h2 onClick={() => history.push("/adminprofile")} className="breadcrumText activeBreadcrum">my profile</h2 >
            <h2 className="breadcrumText"> &gt; </h2>
            <h2 className="breadcrumText">manage subscription</h2>
        </div>
        <hr className="hr" />
        <div className="subscriptions-body">
            <div className="currentS">
                <h1 className="msHeading">CURRENT SUBSCRIPTION</h1>

                <div className="planCard">
                    <h6 className="planBadge">{subdata?.product_details.name} </h6>
                    {subdata?.subscription_details.subscription_status === "Expired" ?
                        <span className="accept">Subscription Expired</span> : ((subdata?.request_status === null) && subdata?.product_details.name !== "Free Plan") ?
                            <span onClick={() => { setDialog2(true); setSubid(subdata?.subscription_details.stripe_subscription_id) }} className="cancel">Request for cancellation</span> : subdata?.request_status === "Accepted" ?
                                <span className="accept">Subscription Cancelled</span> : subdata?.request_status === "Pending" ?
                                    <span className="pending">Requested cancellation</span> : subdata?.request_status === "Rejected" ?
                                        <span onClick={() => { setDialog2(true); setSubid(subdata?.subscription_details.stripe_subscription_id) }} className="cancel">Request for cancellation</span> :
                                        null}
                    <h3 className="planPrice">£ {subdata?.subscription_details.amount}</h3>
                    <h5 className="planType">Per {subdata?.subscription_details.subscription_interval}</h5>


                    {subdata?.request_status !== "Accepted" ? <h4 className="billingHead">{subdata?.product_details.name !== "Free Plan" ? "Next Billing Date" : "Remaining Credits"}</h4> : null}
                    <h5 className="billingDate">{subdata?.product_details.name === "Free Plan" ? subdata?.remaining_credits + " /100" : subdata?.request_status !== "Accepted" ? moment(subdata?.subscription_details.subscription_renewal_date, 'yy-MM-DD HH:mm:ss').format('MMMM Do YYYY') : null}  {subdata?.request_status !== "Pending" ? <span style={{ transform: `translateY(${subdata?.request_status === "Accepted" ? "-15px" : '0px'})` }} onClick={onclick} className="changePlan">{(subdata?.request_status === "Accepted" || subdata?.subscription_details.subscription_status === "Expired") ? "Buy plan" : "Change plan"}</span> : null}</h5>

                </div>

                <h1 className="msHeading">PLAN FEATURES</h1>
                <hr className="hr" />
                <div className="planList">
                    <h3 className="planDetails"><i className="pi pi-check checkList"></i>Automatic table assignment</h3>
                    <h3 className="planDetails"><i className="pi pi-check checkList"></i>{subdata?.product_details.name === "Free Plan" ? "Up To 100" : "Unlimited"} Bookings/Month</h3>
                    <h3 className="planDetails"><i className="pi pi-check checkList"></i>SMS and Email notifications</h3>
                    <h3 className="planDetails"><i className="pi pi-check checkList"></i>Push Notifications</h3>
                    <h3 className="planDetails"><i className="pi pi-check checkList"></i>Manual Table Assignment</h3>
                </div>
            </div>

            <div className="planPayment">
                <h1 className="msHeading">Transactions <span className="p-field-checkbox">
                    {(subdata?.product_details.name !== "Free Plan" && subdata?.request_status !== "Accepted") ? <>
                        <InputSwitch checked={checked} onChange={e => { setChecked(e.value); autorenewal(e.value) }} />
                        <label > Auto Renewal</label></> : null}
                </span></h1>


                <hr className="hr" />
                <div className="paymentList">
                    {trns?.map((list: any, i: any) => (
                        <div key={i} className="payItem">
                            {list.subscription_status === "Cancelled" ?
                                <h5 className="payMonth"> Refund <span className="paidPrice">£{list.amount}</span></h5> : list.subscription_status === "Renewal" ?
                                    <h5 className="payMonth"> Renewal for the {list.subscription_interval} of {list.subscription_interval === "year" ?
                                        (moment(list.subscription_start_date, 'yy-MM-DD HH:mm:ss').format('YYYY') + " - " + moment(list.subscription_expiry_date, 'yy-MM-DD HH:mm:ss').format('YYYY')) : moment(list.subscription_start_date, 'yy-MM-DD HH:mm:ss').format('MMMM')}
                                        <span className="paidPrice">£{list.invoice_amount}</span></h5> : list.transaction_operation === "Updated" ?
                                        <h5 className="payMonth"> Updated Subscription payment for the {list.subscription_interval} of {list.subscription_interval === "year" ?
                                            (moment(list.subscription_start_date, 'yy-MM-DD HH:mm:ss').format('YYYY') + " - " + moment(list.subscription_expiry_date, 'yy-MM-DD HH:mm:ss').format('YYYY')) : moment(list.subscription_start_date, 'yy-MM-DD HH:mm:ss').format('MMMM')}
                                            <span className="paidPrice">£{list.invoice_amount}</span></h5> :
                                        <h5 className="payMonth"> Subscription payment for the {list.subscription_interval} of {list.subscription_interval === "year" ?
                                            (moment(list.subscription_start_date, 'yy-MM-DD HH:mm:ss').format('YYYY') + " - " + moment(list.subscription_expiry_date, 'yy-MM-DD HH:mm:ss').format('YYYY')) : moment(list.subscription_start_date, 'yy-MM-DD HH:mm:ss').format('MMMM')}
                                            <span className="paidPrice">£{list.invoice_amount}</span></h5>
                            }
                            <h6 className="payDate">Date : {moment(list.subscription_start_date, 'yy-MM-DD HH:mm:ss').format('YYYY-MM-DD')}</h6>
                        </div>
                    ))}
                </div>
            </div>

        </div>

        <Dialog visible={dialog} style={{ width: 'auto' }} onHide={() => onHide()}>

            <h4 className="plansHead">Choose a plan that works best for you and your team. <i onClick={onHide} className="pi pi-times close"></i></h4>
            <br />
            <div className="planTypeList">
                {plans?.filter((pl: any) => {
                    if (subdata?.product_details.name !== "Free Plan") {
                        return pl.product_name !== "Free Plan"
                    }
                    else return true

                }).map((plan: any) => (
                    <div key={plan.product_name} className={(subdata?.product_details.name === plan.product_name && subdata?.request_status !== "Accepted" && subdata?.subscription_details.subscription_status !== "Expired" ) ? "selectedPlan" : "choosePlan"}>
                        <h3 className="planName">{plan.product_name}</h3>
                        <h3 className="planType">£{(plan.product_price) / 100}</h3>
                        {(subdata?.product_details.name === plan.product_name && subdata?.request_status !== "Accepted" && subdata?.subscription_details.subscription_status !== "Expired" ) ?
                            <h6 className="planBadge">Current plan</h6> : <button onClick={(e: any) => handleSubscription(plan.product_price_id, subdata?.product_details.name, e, subdata?.admin_email, subdata?.card_added)} className="upgrade">Activate</button>}
                        {/* {subdata?.product_details.name !== plan.product_name ?
                            <button onClick={(e: any) => handleSubscription(plan.product_price_id, subdata?.product_details.name, e, subdata?.admin_email, subdata?.card_added)} className="upgrade">Activate</button> : null} */}
                    </div>
                ))}

            </div>
        </Dialog>

        <Dialog visible={dialog2} style={{ width: '358px' }} onHide={() => onHide()}>
            <h3 className="logoutTtl">Are you sure, you want to request for cancellation?</h3>
            <br />
            <span><button style={{ width: "70px", marginRight: "10px" }} onClick={() => onHide()} className="outline">No</button></span>

            <span><button style={{ width: "70px", height: "35px" }} onClick={(e: any) => cancelSub(e)} className="filled">Yes</button></span>
            <br />

        </Dialog>
    </div>
}
export default MySubscriptions