import React, { Fragment, useState } from 'react';
import "./markholiday.css";
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import styled from 'styled-components';

const FormErrorMsg = styled.span`
 color: red;
 float:right;
 font-size:12px;
`

const Markholiday = () => {
	const [date2, setDate2] = useState();
	const [date3, setDate3] = useState();
	const [date9, setDate9] = useState();
	const [date7, setDate7] = useState();
	const [title, setTitle] = useState();

	return <Fragment>
		<Formik
			initialValues={{ title: title, fromdate: date2, todate: date3, fromtime: date9, totime: date7 }}
			validationSchema={yup.object({

				title: yup.string()
					.required('Required'),
				fromdate: yup.string().nullable()
					.required('Required'),
				todate: yup.string().nullable()
					.required('Required'),
				fromtime: yup.string().nullable()
					.required('Required'),
				totime: yup.string().nullable()
					.required('Required'),
			})}
			onSubmit={values => console.log(values)}
		>
			{formik =>



				<div className="markHolidays ">
					<p>hii</p>
				</div>}
		</Formik>
	</Fragment>
}



export default Markholiday