import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useStore } from './stores/useStore';
import AccessDenined from './pages/AccessDenied';
import { observer } from 'mobx-react-lite';

const AuthRoute = observer((props: any) => {
    const { userSessionStore } = useStore();
    const [redirect, setRedirect] = useState(Boolean)
    console.log("redirect out", redirect)
    // window.onbeforeunload =()=>{
    //     if(localStorage.getItem('Remember-me') !== "true"){
    //         localStorage.clear();
    //         sessionStorage.clear();
    //     }
    // }
    useEffect(() => {

        userSessionStore.authenticateUsingToken() ? setRedirect(false) : setRedirect(true);
    }, [])
    return (redirect ? <Redirect to={{ pathname: "/login", state: { from: props.location } }} /> : props.children)
    // return (redirect ? <Redirect to={{ pathname: "/login", state: { from: props.location } }} /> : props.children)
    // return (loading ? <Loader setLoader={setLoader} visible={loader}/>
    //     :redirect ? <Redirect to={{ pathname: "/login", state: { from: props.location } }} /> : props.children)
})
export default AuthRoute