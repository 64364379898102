import React, { Fragment } from 'react';
import { useHistory } from "react-router";
import './payment.css'

const Fail = ()=>{
    const history = useHistory();
    return <Fragment>
      <div className="payment-s">
        <div className="breadcrum">
            <h2 onClick={()=>history.push("/adminprofile")} className="breadcrumText activeBreadcrum">my profile</h2 >
            <h2 className="breadcrumText"> &gt; </h2>
            <h2 onClick={()=>history.push("/MySubscriptions")} className="breadcrumText activeBreadcrum">manage subscription</h2>
            <h2 className="breadcrumText"> &gt; </h2>
            <h2 className="breadcrumText">Payment Confirmation</h2>
        </div>
        <hr className="hr" />
        <div className='dpayflex'>
            <div className="paycard">
            <i className="fas fa-times-circle red"></i>
            <h1>Payment Failed!</h1>
            <p>Unfortunately, we have an issue with your payment, Please </p>
            <p onClick={()=>history.push("/MySubscriptions")} style={{color:"#0387E8", cursor:"pointer"}}>try again</p>
            </div>
            </div>
        </div>
    </Fragment>
}

export default Fail