import React, { Fragment, useState, useEffect } from 'react';
import "./AppTopbar.css";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Link } from 'react-router-dom';
import { useStore } from "../src/stores/useStore";
import { getItem, setItem } from './pages/storage'
import { Sidebar } from 'primereact/sidebar';
import { useHistory } from 'react-router';

const AppTopbar = () => {
    const { restaurantStore, userSessionStore } = useStore()
    const history = useHistory();
    const [displayModal, setDisplayModal] = useState(false);
    const [displayPosition, setDisplayPosition] = useState(false);
    const [position, setPosition] = useState('center');
    // const [blockR, setBlockR] = useState(false);
    const [dialog, setDialog] = useState(false);
    const [loading, setLoading] = useState(true);
    const [resData, setResData] = useState();
    const [menu, setMenu] = useState(false)
    // const [errorMsg, setErrorMsg] = useState(null)
    // const [data, setData] = useState([])
    // const [refresh, setRefresh] = useState(false)
    // const [loader, setLoader] = useState(false);

    const isValid = (getItem('RestaurantId')) === "undefined"


    const [restaurent, setRestaurent] = useState(getItem('RestaurantList') ? JSON.parse(getItem('RestaurantList')) : [{ "name": "Select Branch", "id": 0 }]);


    const [selectedRestaurant, setSelectedRestaurant] = useState(null);
    const [blkres, setBlkres] = useState(false);

    // console.log("restaurent data",restaurent);

    const onRestaurantChange = (e) => {
        e.preventDefault();
        setSelectedRestaurant(e.target.value);
        restaurantStore.restaurantData(e.target.value)
        history.go()
    }

    const isChecked = () => {
        setBlkres(!blkres);
        restaurantStore.blockReservation()
            .then(([success, message]) => {
                if (success) {
                    console.log("reservation blocked")
                } else {
                    // setErrorMsg(message),
                    console.log("could not block reservations", message)
                }
            })
        onHide();
    }
    const onClick = (name, position) => {
        if (position) {
            setPosition(position);
        }
        if (name === 'displayModal') {
            setDisplayModal(true)
        }
        else if (name === 'displayPosition') {
            setDisplayPosition(true);
        }
        if (blkres) {
            setBlkres(!blkres);
            restaurantStore.unblockReservation()
                .then(([success, message]) => {
                    if (success) {
                        console.log("reservation unblocked")
                    } else {
                        // setErrorMsg(message),
                        console.log("could not unblock reservations", message)
                    }
                })
        }
        else {
            setDialog(true);
        }
    }
    const addRest = (rest) => {
        // if(rest.is_deleted === "false"){}
        setRestaurent((prev) => { return [...prev, { "name": rest.restaurant_name, "id": rest.restaurant_id }] })


    }
    const getRestData = () => {
        restaurantStore.getRestaurantSettings(getItem('RestaurantId'))
            .then(([success, data]) => {
                if (success) {
                    setResData(data.restaurant_settings)
                    setBlkres(data.restaurant_settings.block_reservations);
                    console.log("restaurant settings", data);

                    // setBlockRes(data.restaurant_settings.block_reservations)
                    // setShow(false);
                } else {

                    console.log("error")
                    // setErrorMsg(data)
                }
            })
    }
    useEffect(() => {
        getRestData()
        setLoading(true)
        // setLoader(true)
        //------Get user------
        const user = () => {
            let id = getItem('role-id');
            const admin = () => {
                restaurantStore.getUser(id)
                    .then(([success, data]) => {
                        if (success) {
                            console.log(data.admin_info.restaurant_list);
                            data.admin_info.restaurant_list.map((rest) => (
                                addRest(rest)
                            ))
                            if (!restaurent.length) {
                                restaurantStore.restaurantData(data.admin_info.restaurant_list[0].restaurant_id)
                            }
                            console.log("success", data)
                        } else {
                            console.log("get user error responce : ", data)
                        }
                    })
            }
            const coadmin = () => {
                restaurantStore.getUser2(id)
                    .then(([success, data]) => {
                        if (success) {
                            console.log(data.co_admin_info.restaurant);
                            addRest(data.co_admin_info.restaurant)
                            console.log("success", data)
                        } else {
                            console.log("get user error responce : ", data)
                        }
                    })
            }
            getItem('user-role') === "admin" ? admin() : coadmin()
            setItem('RestaurantList', JSON.stringify(restaurent))

        }

        if (restaurent.length === 1) { user(); }


    }, [])

    const onHide = (name) => {
        // dialogFuncMap[`${name}`](false);
        setDialog(false);
        if (name === 'displayModal') {
            setDisplayModal(false)
        }
        else if (name === 'displayPosition') {
            setDisplayPosition(false);
        }

    }
    const DeleteTable = (name) => {
        return (
            <div>
                <Button label="No" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                <Button label="Yes" icon="pi pi-check" onClick={() => onHide(name)} autoFocus />
            </div>
        );
    }
    // const AddTable = (name) => {
    //     return (
    //         <div>
    //             <Button label="Add" onClick={() => onHide(name)} autoFocus />
    //         </div>
    //     );
    // }

    console.log(window.location.pathname)
    return <Fragment>
        <div className="header">
            <i style={{ display: isValid && 'none' }} onClick={() => setMenu(true)} className='pi pi-bars resMenu'></i>

            <img onClick={() => history.push('/')} className='headlg' src="./assets/logo/purple bg.svg" alt="brand-logo" />
            <div className="headerDrop">
                <Dropdown
                    style={{ fontSize: '20px' }}
                    panelStyleClass="minWidthOverride"
                    value={(getItem('RestaurantId')) !== "undefined" ? JSON.parse(getItem('RestaurantId')) : selectedRestaurant}
                    options={restaurent}
                    optionLabel="name"
                    optionValue="id"
                    className='headDropbtn'
                    onChange={(e) => onRestaurantChange(e)}
                    placeholder={restaurent[0]?.name}
                />

                <Dialog visible={displayModal} position={position} modal={true} style={{ width: '798px', height: "884px" }} footer={DeleteTable('displayModal')} onHide={() => onHide('displayModal')}>
                    <p className="p-m-0 heading" style={{ fontSize: '20px !important', fontWeight: '400', color: '#151515', marginBottom: '5px', marginTop: '10px !important' }}>Dine Fine Bistro.</p>
                    <p className="p-m-0 txt" style={{ fontSize: '12px', fontWeight: '400', color: '#707070', marginBottom: '10px' }}>76 Nottingham Rd,Aberdalias SA10 6TA,UK</p>
                    <hr />
                    <p className="p-m-0 heading" style={{ fontSize: '16px', fontWeight: '400', color: '#151515', marginBottom: '5px', marginTop: '10px !important' }}>Dine Fine Bistro.</p>
                    <p className="p-m-0 txt" style={{ fontSize: '12px', fontWeight: '400', color: '#707070', marginBottom: '10px' }}>76 Nottingham Rd,Aberdalias SA10 6TA,UK</p>
                    <hr />
                    <p className="p-m-0 heading" style={{ fontSize: '16px', fontWeight: '400', color: '#151515', marginBottom: '5px', marginTop: '10px !important' }}>Dine Fine Bistro.</p>
                    <p className="p-m-0 txt" style={{ fontSize: '12px', fontWeight: '400', color: '#707070', marginBottom: '10px' }}>76 Nottingham Rd,Aberdalias SA10 6TA,UK</p>
                    <hr />
                    <p className="p-m-0 heading" style={{ fontSize: '16px', fontWeight: '400', color: '#151515', marginBottom: '5px', marginTop: '10px !important' }}>Dine Fine Bistro.</p>
                    <p className="p-m-0 txt" style={{ fontSize: '12px', fontWeight: '400', color: '#707070', marginBottom: '10px' }}>76 Nottingham Rd,Aberdalias SA10 6TA,UK</p>
                    <hr />
                    <p className="p-m-0 heading" style={{ fontSize: '16px', fontWeight: '400', color: '#151515', marginBottom: '5px', marginTop: '10px !important' }}>Dine Fine Bistro.</p>
                    <p className="p-m-0 txt" style={{ fontSize: '12px', fontWeight: '400', color: '#707070', marginBottom: '10px' }}>76 Nottingham Rd,Aberdalias SA10 6TA,UK</p>
                    <hr />
                    <p className="p-m-0 heading" style={{ fontSize: '16px', fontWeight: '400', color: '#151515', marginBottom: '5px', marginTop: '10px !important' }}>Dine Fine Bistro.</p>
                    <p className="p-m-0 txt" style={{ fontSize: '12px', fontWeight: '400', color: '#707070', marginBottom: '10px' }}>76 Nottingham Rd,Aberdalias SA10 6TA,UK</p>
                    <hr />
                    <p className="p-m-0 heading" style={{ fontSize: '16px', fontWeight: '400', color: '#151515', marginBottom: '5px', marginTop: '10px !important' }}>Dine Fine Bistro.</p>
                    <p className="p-m-0 txt" style={{ fontSize: '12px', fontWeight: '400', color: '#707070', marginBottom: '10px' }}>76 Nottingham Rd,Aberdalias SA10 6TA,UK</p>
                    <hr />
                    <p className="p-m-0 heading" style={{ fontSize: '16px', fontWeight: '400', color: '#151515', marginBottom: '5px', marginTop: '10px !important' }}>Dine Fine Bistro.</p>
                    <p className="p-m-0 txt" style={{ fontSize: '12px', fontWeight: '400', color: '#707070', marginBottom: '10px' }}>76 Nottingham Rd,Aberdalias SA10 6TA,UK</p>
                    <hr />
                    <p className="p-m-0 heading" style={{ fontSize: '16px', fontWeight: '400', color: '#151515', marginBottom: '5px', marginTop: '10px !important' }}>Dine Fine Bistro.</p>
                    <p className="p-m-0 txt" style={{ fontSize: '12px', fontWeight: '400', color: '#707070', marginBottom: '10px' }}>76 Nottingham Rd,Aberdalias SA10 6TA,UK</p>
                    <hr />
                </Dialog>
            </div>
            <div className="rightMenu">
                <div style={{ display: isValid && 'none' }} className="blockRes">
                    <h4>Block Reservations</h4>
                    <label className="switch">
                        <input id="blockRes" onChange={onClick} checked={(blkres)} type="checkbox" />
                        <span className="slider">
                            <span style={{ display: !blkres ? "none" : "initial" }} className="switchOn">ON</span>
                            <span style={{ display: blkres ? "none" : "initial" }} className="switchOff">OFF</span>
                        </span>
                        <Dialog modal={true} visible={dialog} style={{ width: '358px' }} onHide={() => onHide()}>
                            <h3 className="logoutTtl">Are you sure, you want to Block reservations</h3>
                            <br />
                            <span><button style={{ width: "70px", marginRight: "10px" }} onClick={onHide} className="outline">No</button></span>
                            <span><button autoFocus style={{ width: "70px", height: "35px" }} onClick={isChecked} className="filled">Yes</button></span>
                            <br />
                        </Dialog>
                    </label>
                </div>
                {/* <i className="pi pi-bell bell-a "></i> */}
                {/* <div className="bell-a">
                    <img src="./assets/images/bell.svg" alt=""></img>
                    <p className="nbadge">7</p>
                </div> */}

                <Link to="/adminprofile">
                    <img src='./assets/images/admin3.png' alt="user-Logo" className="avatar" />
                </Link>
            </div>
            <Sidebar visible={menu} position='left' onHide={() => setMenu(false)}>
                <div className='menuList'>
                    <img style={{ display: "initial" }} className='headlg' src="./assets/logo/white bg.svg" alt="brand-logo" />
                    <div style={{ width: "87%" }} className='rightMenu'>

                        Block Reservations
                        <div style={{ display: 'initial' }} className="blockRes">
                            <label className="switch">
                                <input id="blockRes" onChange={onClick} checked={(blkres)} type="checkbox" />
                                <span className="slider">
                                    <span style={{ display: !blkres ? "none" : "initial" }} className="switchOn">ON</span>
                                    <span style={{ display: blkres ? "none" : "initial" }} className="switchOff">OFF</span>
                                </span>

                            </label>
                        </div>
                    </div>
                    <ul>
                        <li onClick={() => setMenu(false)}><Link className={window.location.pathname === "/" ? "active" : ""} to="/">
                            <svg className="svgIcon" xmlns="http://www.w3.org/2000/svg" width="17.893" height="19.659" viewBox="0 0 17.893 19.659"><defs></defs><g transform="translate(1 1)"><path className={window.location.pathname === "/" ? "a active" : "a"} d="M4.5,9.181,12.446,3l7.946,6.181v9.712a1.766,1.766,0,0,1-1.766,1.766H6.266A1.766,1.766,0,0,1,4.5,18.893Z" transform="translate(-4.5 -3)" /><path className={window.location.pathname === "/" ? "a active" : "a"} d="M13.5,26.829V18h5.3v8.829" transform="translate(-8.202 -9.171)" /></g></svg>
                            Dashboard
                        </Link></li>
                        <li onClick={() => setMenu(false)}><Link className={window.location.pathname === "/reservationlist" ? "active" : ""} to="/reservationlist">
                            <svg className="svgIcon" xmlns="http://www.w3.org/2000/svg" width="25.572" height="23.215" viewBox="0 0 25.572 23.215"><defs></defs><g transform="translate(1 1)"><path className={window.location.pathname === "/reservationlist" ? "a active" : "a"} d="M3,4.5h7.072a4.714,4.714,0,0,1,4.714,4.714v16.5a3.536,3.536,0,0,0-3.536-3.536H3Z" transform="translate(-3 -4.5)" /><path className={window.location.pathname === "/reservationlist" ? "a active" : "a"} d="M29.786,4.5H22.714A4.714,4.714,0,0,0,18,9.214v16.5a3.536,3.536,0,0,1,3.536-3.536h8.25Z" transform="translate(-6.214 -4.5)" /></g></svg>
                            Reservations
                        </Link></li>
                        <li onClick={() => setMenu(false)}><Link className={window.location.pathname === "/create-floor-plan" ? "active" : ""} to="/create-floor-plan">
                            <svg xmlns="http://www.w3.org/2000/svg" className='svgIcon' width="26" height="26" viewBox="0 0 26 26">
                                <defs>
                                    <clipPath id="clip-Artboard_2">
                                        <rect width="26" height="26" />
                                    </clipPath>
                                </defs>
                                <g id="Artboard_2" data-name="Artboard – 2" clipPath="url(#clip-Artboard_2)">
                                    <g id="Group_37818" data-name="Group 37818" transform="translate(-7 -7)">
                                        <g id="Group_37817" data-name="Group 37817" transform="translate(-95 -478)">
                                            <rect className={window.location.pathname === "/create-floor-plan" ? "rect-floor active" : "rect-floor"} id="Rectangle_2492" data-name="Rectangle 2492" width="6" height="6" rx="1" transform="translate(104 487)" />
                                            <rect className={window.location.pathname === "/create-floor-plan" ? "rect-floor active" : "rect-floor"} id="Rectangle_2493" data-name="Rectangle 2493" width="6" height="6" rx="1" transform="translate(112 487)" />
                                            <rect className={window.location.pathname === "/create-floor-plan" ? "rect-floor active" : "rect-floor"} id="Rectangle_2494" data-name="Rectangle 2494" width="6" height="6" rx="1" transform="translate(120 487)" />
                                        </g>
                                        <g id="Group_37816" data-name="Group 37816" transform="translate(-95 -478)">
                                            <rect className={window.location.pathname === "/create-floor-plan" ? "rect-floor active" : "rect-floor"} id="Rectangle_2497" data-name="Rectangle 2497" width="6" height="6" rx="1" transform="translate(104 495)" />
                                            <rect className={window.location.pathname === "/create-floor-plan" ? "rect-floor active" : "rect-floor"} id="Rectangle_2496" data-name="Rectangle 2496" width="6" height="6" rx="1" transform="translate(112 495)" />
                                            <rect className={window.location.pathname === "/create-floor-plan" ? "rect-floor active" : "rect-floor"} id="Rectangle_2495" data-name="Rectangle 2495" width="6" height="6" rx="1" transform="translate(120 495)" />
                                        </g>
                                        <g id="Group_37815" data-name="Group 37815" transform="translate(-95 -478)">
                                            <rect className={window.location.pathname === "/create-floor-plan" ? "rect-floor active" : "rect-floor"} id="Rectangle_2500" data-name="Rectangle 2500" width="6" height="6" rx="1" transform="translate(104 503)" />
                                            <rect className={window.location.pathname === "/create-floor-plan" ? "rect-floor active" : "rect-floor"} id="Rectangle_2499" data-name="Rectangle 2499" width="6" height="6" rx="1" transform="translate(112 503)" />
                                            <rect className={window.location.pathname === "/create-floor-plan" ? "rect-floor active" : "rect-floor"} id="Rectangle_2498" data-name="Rectangle 2498" width="6" height="6" rx="1" transform="translate(120 503)" />
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            Floor Plan
                        </Link></li>
                        <li onClick={() => setMenu(false)}><Link className={window.location.pathname === "/guestbooklist" ? "active" : ""} to="/guestbooklist">
                            <svg className="svgIcon" xmlns="http://www.w3.org/2000/svg" width="20.645" height="25.972" viewBox="0 0 20.645 25.972"><defs></defs><path className={window.location.pathname === "/guestbooklist" ? "a active" : "a"} d="M26.145,28.472l-9.322-6.659L7.5,28.472V7.164A2.664,2.664,0,0,1,10.164,4.5H23.481a2.664,2.664,0,0,1,2.664,2.664Z" transform="translate(-6.5 -3.5)" /></svg>
                            Guest Book</Link></li>
                        <li onClick={() => setMenu(false)}><Link className={window.location.pathname === "/settings" ? "active" : ""} to="/settings">
                            <svg className="svgIcon" xmlns="http://www.w3.org/2000/svg" width="26.028" height="26.028" viewBox="0 0 26.028 26.028"><defs></defs><g transform="translate(-1416.986 4430.049)"><path className={window.location.pathname === "/settings" ? "a active" : "a"} d="M21.6,16.791a1.8,1.8,0,0,0,.36,1.988l.066.066a2.186,2.186,0,1,1-3.091,3.091l-.066-.066a1.817,1.817,0,0,0-3.08,1.289v.186a2.184,2.184,0,0,1-4.369,0v-.1a1.8,1.8,0,0,0-1.18-1.649,1.8,1.8,0,0,0-1.988.36l-.066.066a2.186,2.186,0,1,1-3.091-3.091l.066-.066a1.817,1.817,0,0,0-1.289-3.08H3.684a2.184,2.184,0,1,1,0-4.369h.1a1.8,1.8,0,0,0,1.649-1.18,1.8,1.8,0,0,0-.36-1.988l-.066-.066A2.186,2.186,0,1,1,8.1,5.093l.066.066a1.8,1.8,0,0,0,1.988.36h.087A1.8,1.8,0,0,0,11.33,3.87V3.684a2.184,2.184,0,1,1,4.369,0v.1a1.817,1.817,0,0,0,3.08,1.289l.066-.066A2.186,2.186,0,1,1,21.935,8.1l-.066.066a1.8,1.8,0,0,0-.36,1.988v.087a1.8,1.8,0,0,0,1.649,1.092h.186a2.184,2.184,0,0,1,0,4.369h-.1A1.8,1.8,0,0,0,21.6,16.791Z" transform="translate(1416.486 -4430.549)" /><path className={window.location.pathname === "/settings" ? "a active" : "a"} d="M20.053,16.777A3.277,3.277,0,1,1,16.777,13.5,3.277,3.277,0,0,1,20.053,16.777Z" transform="translate(1413.223 -4433.812)" /></g></svg>
                            Settings</Link></li>
                        <li onClick={(e) => { userSessionStore.logout(); history.replace('/logoutConformation') }}>
                            <svg className="svgIcon2 " xmlns="http://www.w3.org/2000/svg" width="20.146" height="20.146" viewBox="0 0 20.146 20.146"><defs></defs><path className="a logout" d="M12.447,18.6l1.567,1.567,5.6-5.6-5.6-5.6-1.567,1.567,2.91,2.91H4.5v2.238H15.3ZM22.408,4.5H6.738A2.245,2.245,0,0,0,4.5,6.738v4.477H6.738V6.739h15.67v15.67H6.738V17.931H4.5v4.477a2.245,2.245,0,0,0,2.238,2.238h15.67a2.245,2.245,0,0,0,2.238-2.238V6.738A2.245,2.245,0,0,0,22.408,4.5Z" transform="translate(-4.5 -4.5)" /></svg>
                            Log Out</li>
                    </ul>


                </div>
            </Sidebar>
            <Dialog modal={true} visible={dialog} style={{ width: '358px' }} onHide={() => onHide()}>
                <h3 className="logoutTtl">Are you sure, you want to Block reservations</h3>
                <br />
                <span><button style={{ width: "70px", marginRight: "10px" }} onClick={onHide} className="outline">No</button></span>
                <span><button autoFocus style={{ width: "70px", height: "35px" }} onClick={isChecked} className="filled">Yes</button></span>
                <br />
            </Dialog>
        </div>




    </Fragment>



}

export default AppTopbar;