import React, { useState, useEffect } from 'react';
import { observer } from "mobx-react-lite";
import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useStore } from '../stores/useStore'
import { BreadCrumb } from 'primereact/breadcrumb';
import moment from 'moment';

export const RestaurantList: React.FC<any> = observer((props: any) => {
    const { restaurantStore } = useStore()
    const [loading, setLoading] = useState(true)
    const [errorMsg, setErrorMsg] = useState(null)
    const [data, setData] = useState([])
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        setLoading(true)
        restaurantStore.getRestaurantList()
            .then(([success, data]) => {
                if (success) {
                    setData(data)
                } else {
                    setErrorMsg(data)
                }
            })
            .finally(() => {
                setLoading(false)
                setRefresh(false)
            })
    }, [refresh])

    function publishData(data: any) {
        restaurantStore.publishRestaurant(data.id)
            .then(([success, resdata]) => {
                setRefresh(true)
            })
    }

    const publishStatusTemplate = (rowData: any) => {
        return (
            <span>{rowData.published ? <span title={'Published on ' + moment(rowData.published_on).format('DD-MM-YYYY hh:mm')} style={{ color: 'green' }}>Yes</span> : 'No'}</span>
        )
    }
    const publishTemplate = (rowData: any) => {
        return (
            (!rowData.published) || moment(rowData.updated_on).isAfter(rowData.published_on,'second')? <a href='#' onClick={(e) => { publishData(rowData); return false }}>Publish</a>: null
        )
    }
    const editTemplate = (rowData: any) => {
        return <Link to={`/restaurantform/edit/${rowData.id}`}>Edit</Link>
    }
    const floorplanTemplate = (rowData: any) => {
        return <Link to={`/floorplanlist/${rowData.id}`}>Floor Plan</Link>
    }
    const scheduleTemplate = (rowData: any) => {
        return <Link to={`/schedule/${rowData.id}`}>Schedule</Link>
    }
    const menuItems = [
        { label: 'Restaurants' },
    ]

    const home = { icon: 'pi pi-home', url: '/' }

    return (
        <>
            <BreadCrumb model={menuItems} home={home} />
            {loading ? <div>Loading...</div> : errorMsg ? <div className='error-msg'>{errorMsg}</div> : <div className="p-grid p-fluid">
                <div className="p-col-12">
                    <div className="card card-w-title ">
                        <div className="p-grid p-justify-between">
                            <div className="p-col">
                                <h1>Restaurants</h1>
                            </div>
                            <div className="p-sm-4 p-md-3 p-xl-2" >
                                <Link to="/restaurantform/new">
                                    <Button label="New Restaurant" className="p-button-primary" />
                                </Link>
                            </div>
                        </div>
                        <div className='p-grid'>
                            <div className="p-col-12">
                                {data.length === 0 ? (
                                    <div>No Restaurant data Available</div>
                                ) : (
                                        <DataTable value={data} responsive={true}>
                                            <Column field="name" header="Name" />
                                            <Column field="address" header="Address" />
                                            <Column field="pstatus" body={publishStatusTemplate} header="Published" />
                                            <Column field="publish" body={publishTemplate} header="Publish" />
                                            <Column field="edit" body={editTemplate} style={{ width: '80px' }} />
                                            <Column field="floorplan" body={floorplanTemplate} style={{ width: '100px' }} />
                                            <Column field="schedule" body={scheduleTemplate} style={{ width: '100px' }} />
                                        </DataTable>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </>
    );
})
