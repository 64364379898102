import { observer } from "mobx-react-lite";
import moment from 'moment';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from "primereact/button";
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import React, { Fragment, useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import { useStore } from "../stores/useStore";
import { Calendar } from 'primereact/calendar';
import Loader from '../components/Loader/loader';
import './renderlist.css'

export const ReservationList: React.FC<any> = observer(() => {
    const { restaurantStore } = useStore()
    const [loading, setLoading] = useState(true)
    const [errorMsg, setErrorMsg] = useState(null)
    const [globalFilter, setGlobalFilter] = useState("")
    const [dataRes, setData] = useState()
    const [refresh, setRefresh] = useState(false)
    const [date2, setDate2] = useState(moment().format("YYYY-MM-DD"));
    const [loader, setLoader] = useState(false);

    const pageSize = 10;

    const [count, setCount] = useState(0);
    const onLeftClick = () => {

        if (count > 0) {
            setCount(count - 1)
            setLoader(true);
            getReservations(count - 1)

        }
        else {
            setCount(0)
        }

        console.log("left clicked", count);
    }

    const onRightClick = () => {

        if (count >= 0 && count < Math.ceil((dataRes?.total) / 10) - 1) {
            setCount(count + 1)
            setLoader(true);
            getReservations(count + 1)
        }

        console.log("Right clicked", count);

    }


    const onSearch = (e: any) => {
        console.log("search", e.target.value)
        setGlobalFilter(e.target.value)
        if (e.target.value !== "") {
            getReservations(0, date2, (e.target.value).replace("+","%2B"))
        }
        else {
            console.log("no search phrase")
            getReservations(0, date2)
        }


    }




    useEffect(() => {
        setLoading(true)
        setLoader(true)
        const page = 0;
        const pageSize = 10;
        getReservations(0)
    }, [refresh])


    const getReservations = (page: any, searchDate?: any, searchTxt?: any,) => {
        let selectedDate = date2;
        if (searchDate) {
            selectedDate = searchDate;
        }
        console.log("Filter data : ",selectedDate, searchTxt, page)
        restaurantStore.getReservationList(selectedDate, searchTxt?searchTxt:"", page)
            .then(([success, data]) => {
                if (success) {
                    setData(data);
                    // console.log("reservation data", data);
                    setLoader(false);
                } else {
                    setErrorMsg(data.msg)
                    setLoader(false);
                }
            })
            .finally(() => {
                setLoading(false)
                setRefresh(false)
            })
    }
    const resevTime = (tableData: any) => {
        if (tableData.reserved_from !== null) {
            return moment(tableData.reserved_from, "hh:mm:ss").local().format("hh:mm a")

        }
        else {
            return " "
        }
    }


    // const pageCount=data?Math.ceil(data.length/pageSize):0;

    // if(pageCount === 1) return null;

    // const pages= range(1,pageCount+1)


    const Mindate = () => {
        let today = moment().subtract(1, 'years').calendar();
        return new Date(today)
    }
    const Maxdate = () => {
        let today = moment().add(1, 'months').calendar();
        return new Date(today)
    }
    const calsearch = (e: any) => {
        let date = moment(e.target.value).format("YYYY-MM-DD").toString();
        setDate2(date)
        getReservations(0, date)
        // console.log("date check",e.target.value);
    }
    const globalSearch = () => {
        return (
            <div className="calHead">
                <div className="searchHold">
                    <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
                    <InputText autoComplete="off" className="SearchBar" maxLength={20} style={{ width: "95%" }} type="search" onChange={onSearch}
                        placeholder="Search guest name or phone number" />
                </div>
                {/* <button className="calSearch"> Search</button> */}

                <Calendar readOnlyInput dateFormat="dd/mm/yy" minDate={Mindate()} maxDate={Maxdate()} placeholder="Search By Date" style={{ width: '300px', height: '45px' }} id="icon" value={new Date(date2)} onChange={(e: any) => calsearch(e)} showIcon />
            </div>

        );
    }



    return (
        <Fragment>
            <div className="breadcrum">
                <h2 className="breadcrumText">Reservations</h2 >
            </div>
            <hr className="hr" />

            {loading ? <div><Loader setLoader={setLoader} visible={loader} /></div> : errorMsg ? <div className='error-msg'>{errorMsg}</div> : <div className=" p-fluid">
                <div><Loader setLoader={setLoader} visible={loader} /></div>
                <div className="p-col-12 resList">
                    <div className="card card-w-title">
                        <div className='p-grid'>
                            <div className="p-col-12">
                                {/* {(dataRes?.items).length === 0 ? <div className="nodata"><h1>No Reservation Data Available</h1><img src="assets/images/nodata.png" alt="nodata" /> </div> : */}
                                <DataTable value={dataRes?.items} header={globalSearch()} globalFilter={globalFilter || date2}
                                    responsive={true} rows={10} paginator={true}
                                    emptyMessage="No Reservations found" resizableColumns={true} columnResizeMode="expand">
                                    <Column field="booked_date" header="Date" style={{ width: '125px' }} />

                                    {/* <Column field='booked_date' body={(rowData: any) => moment(rowData['booked_date']).format('DD/MM/YYYY').toString()} header="Date"
                                        style={{ width: '125px' }} /> */}
                                    <Column body={resevTime} header="Time" style={{ width: '125px', textTransform: "uppercase" }} />
                                    <Column field="guest.guest_name" header="Guest" style={{ width: '150px' }} />
                                    <Column field="no_of_guests" header="Party Size" style={{ width: '150px' }} />
                                    <Column field="guest.guest_mobile" header="Mobile Number" style={{ width: '150px' }} />
                                    <Column field="reservation_status.reservation_status" header="Status" style={{ width: '125px' }} />
                                    {/* <Column body={deleteTemplate} header="Action" style={{ width: '80px', color: '#ED5200 !important' }} /> */}
                                </DataTable>
                                {/* } */}

                                {/* <br />
                                <div style={{ textAlign: 'center', columnGap: '10px' }}>
                                    <i style={{ padding: "5px 9px", borderRadius: "50%", backgroundColor: "#78787812", marginRight: "10px", cursor: "pointer" }} className="fas fa-chevron-left" onClick={onLeftClick}></i>
                                    Page: {count + 1}
                                    <i style={{ padding: "5px 9px", borderRadius: "50%", backgroundColor: "#78787812", cursor: "pointer", marginLeft: "10px" }} className="fas fa-chevron-right" onClick={onRightClick}></i>
                                </div> */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </Fragment>
    );
})