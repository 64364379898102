import axios from 'axios'
import {getItem,setItem} from '../pages/storage'
import { toast } from 'react-toastify';

toast.configure()

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_MAIN_API_BASE_URL,
    timeout: 150000,
    withCredentials: false,
    headers: {
    "Content-Type": "application/json"
    // 'Access-Control-Allow-Origin' : '*',
    // 'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE',
    // "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length"
    }
}
);

async function getAccessToken() {
    return getItem('access');
}

// Request interceptor for auth header

// apiClient.interceptors.request.use(async (config) => {
//     config.headers['Authorization'] = 'Bearer ' + await getAccessToken();
//     return config;
// });


apiClient.interceptors.response.use(undefined,
   function (error) {
    const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry ) {
            // localStorage.clear();
            // sessionStorage.clear();
            toast.error("Something went wrong! Please try again");
            originalRequest._retry = true;
            return apiClient.post('/api/v1/refresh',{},
           {
            headers:{
                Authorization: 'Bearer ' + getItem('refresh'),
            }
               
           }).then((res: any) => {
               console.log("refresh token data : ","wait for 1 sec to page reload", res)
            //    alert(`refresh token : ${res.data}`)
               setItem('access', res.data.access_token)
               apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + getAccessToken();
               setTimeout(() => {
               window.history.go(0);
               }, 1000);
               return apiClient(originalRequest);
           })
           .catch((err: any) => {
            let errorMsg = err.response ? err.response.data.detail : err.message
            console.log("refresh token error data : ", errorMsg)
            return errorMsg
            })
            }
        return Promise.reject(error);
    }
)
export default apiClient;
