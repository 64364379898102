import React, { useState } from 'react';

var tbssession;


export const SetSession = (props)=>{
    console.log("storage value : ", props)
    if(props){
    tbssession = localStorage
    }
    else tbssession = sessionStorage

}

export const getItem = (key)=>{
    console.log("Remember-me",localStorage.getItem('Remember-me'));
    if(localStorage.getItem('Remember-me')==="true"){
        tbssession = localStorage

    }
    else tbssession = sessionStorage
    console.log(tbssession?.getItem(key))
    return tbssession?.getItem(key)
}

export const setItem = (key,value)=>{
    console.log("remember me set to session",typeof (localStorage.getItem('Remember-me')))

    if(localStorage.getItem('Remember-me')==="true"){
        tbssession = localStorage
    }
    else tbssession = sessionStorage
    console.log(key,value,tbssession)
    return tbssession?.setItem(key,value)
}
export const removeItem = (key)=>{

    if(localStorage.getItem('Remember-me')==="true"){
        tbssession = localStorage
    }
    else tbssession = sessionStorage
    return tbssession?.removeItem(key)
}
export const clear = ()=>{
    localStorage.clear();
    sessionStorage.clear();
}

