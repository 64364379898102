import { ErrorMessage, Field, Form, Formik } from 'formik';
import { observer } from "mobx-react-lite";
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from "primereact/button";
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import React, { useEffect, useState,Fragment } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import styled from 'styled-components';
import * as yup from 'yup';
import { useStore } from "../stores/useStore";
import { Calendar } from 'primereact/calendar';
import Loader from '../components/Loader/loader';
//import { Growl } from 'primereact/growl';

const FormErrorMsg = styled.span`
 padding-left: 0px;
 color: red;
 display: 'inline-block';
 @media (max-width: 40em) {
	padding-left: 0;
	position: relative;
	top: 4px;
 }
`
export const GuestBookList: React.FC<any> = observer((props) => {
    const { restaurantStore } = useStore();
    const [editMode, setEditMode] = useState(false)
    const [loading, setLoading] = useState(true)
    const [errorMsg, setErrorMsg] = useState(null)
    const [formData, setFormData] = useState({})
    const [data, setData] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [visible, setVisible] = useState(false)
    const [globalFilter, setGlobalFilter] = useState(null)
    const [date2, setDate2] = useState('');
    const [loader, setLoader] = useState(false);
    const [first2, setFirst2] = useState(0);
    const [rows2, setRows2] = useState(10);

    //const growl = useRef<Growl>(null)
    const onCustomPage2 = (event:any) => {
        setFirst2(event.first);
        setRows2(event.rows);
    }

    const template2 = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        'RowsPerPageDropdown': (options:any) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 }
            ];

            return (
                <>
                    <span className="p-mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Items per page: </span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} appendTo={document.body} />
                </>
            );
        },
        'CurrentPageReport': (options:any) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            )
        }
    };

    useEffect(() => {
        setLoading(true)
        setLoader(true)
        restaurantStore.getGuestList()
            .then(([success, data]) => {
                if (success) {
                    setData(data);
                    console.log("guestbook data",data);

                } else {
                    setErrorMsg(data.msg)
                }
            })
            .finally(() => {
                setLoading(false)
                setLoader(false)

                setRefresh(false)
            })
    }, [refresh])

    const globalSearch = () => {
        return (
            <div className="calHead">
                <div className="searchHold">
                    <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
                    <InputText autoComplete="off" className="SearchBar" maxLength={20} style={{ width: "95%" }} type="search" onInput={(e: any) => setGlobalFilter(e.target.value)}
                        placeholder="Search guest name or phone number" />
                </div>

                {/* <Calendar readOnlyInput style={{ width: '300px' }} id="icon" value={new Date(date2)} onChange={(e) => setDate2((e.target.value).toString())} showIcon /> */}

                {/* <button  className="calSearch"> Search</button> */}
            </div>
        );
    }

    const countries = [
        { label: 'United Kingdom', value: 'United Kingdom' },
        { label: 'India', value: 'India' },
    ];

    const tags = [
        { label: 'Vegetarian', value: 'Vegetarian' },
        { label: 'VIP', value: 'VIP' },
        { label: 'Infant', value: 'Infant' },
    ];

    return (
        <Fragment>
        <div className="breadcrum">
                       <h2 className="breadcrumText">Guest book</h2 >
                   </div>
                   <hr className="hr"/>
            {loading ? <div><Loader setLoader={setLoader} visible={loader} /></div> : errorMsg ? <div className='error-msg'>{errorMsg}</div> : <div className=" p-fluid">
                <div className="p-col-12 resList">
                    <div className="card card-w-title">
                        <div className="p-grid p-justify-between">
                            <div className="p-col">
                                {/* <h1>Guest Book</h1> */}
                            </div>
                            {/* <div className="p-col-4 p-sm-4 p-md-4 p-xl-2">
                                <Button label="New Guest" className="p-button-primary" onClick={onNewClick} />
                            </div> */}
                        </div>

                        <div className='p-grid' >
                            <div className="p-sm-12 p-lg-12 ">
                                {data.length === 0 ? <div className="nodata"><h1>No Guest Data Available</h1><img src="assets/images/nodata.png" alt="nodata"/> </div> :
                                    <DataTable value={data} header={globalSearch()} globalFilter={globalFilter} rows={10} paginator={true} responsive={true} 
                                    // paginator  paginatorTemplate={template2}
                                    // first={first2} rows={rows2} onPage={onCustomPage2}
                                        paginatorPosition="bottom"   emptyMessage="No Guest found"
                                        resizableColumns={true} columnResizeMode="expand" rowsPerPageOptions={[10, 20, 30,50]}>
                                        <Column field="name" header="Guest" sortable={true} style={{ width: '150px' }} />
                                        <Column field="mobile" header="Mobile Number" style={{ width: '150px' }} />
                                        {/* <Column field="notes" header="Notes" style={{ width: '150px' }} /> */}
                                    </DataTable>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }

            <Dialog header={<span>{editMode ? 'Edit' : 'New'} Guest Details </span>} style={{ width: '40vw' }} visible={visible} onHide={() => setVisible(false)}>
                <Formik
                    initialValues={formData}
                    enableReinitialize={true}
                    validationSchema={yup.object({
                        name: yup.string()
                            .max(30, 'Must be 30 characters or less')
                            .required('Required'),
                        address: yup.string()
                            .max(100, 'Must be 100 characters or less')
                            .required('Required'),
                        country: yup.string()
                            .max(30, 'Must be 30 characters or less')
                            .required('Required'),
                        email: yup.string()
                            .max(30, 'Must be 30 characters or less')
                            .email('Please enter a valid email')
                            .required('Required'),
                        mobile: yup.string()
                            .matches(new RegExp(/^\+?\(?(\d{1,4}[\s-)]*)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}[\s.-]?(x\d{1,10})?$/),
                                "Mobile number is not valid")
                            .required('Required'),
                        phone: yup.string()
                            .matches(new RegExp(/^\+?\(?(\d{1,4}[\s-)]*)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}[\s.-]?(x\d{1,10})?$/),
                                "Mobile number is not valid"),
                        notes: yup.string()
                            .max(30, 'Must be 30 characters or less'),

                    })}

                    onSubmit={(values: any, { setSubmitting }) => {
                        if (Array.isArray(values.tags)) {
                            values.tags = values.tags.join(',')
                        }
                        if (editMode) {
                            restaurantStore.updateGuestBook(Number(values.id), values)
                                .then(([success, data]) => {
                                    setVisible(false)
                                    setRefresh(true)
                                })
                        }

                        else {
                            restaurantStore.createGuest(values)
                                .then(([success, data]) => {
                                    setVisible(false)
                                    setRefresh(true)
                                })
                        }
                    }}>

                    {formik =>
                        <Form>
                            <div className="form-panel-content">
                                <div className="p-grid p-fluid">
                                    <div className="p-col-8 error-msg">
                                        {errorMsg}
                                    </div>

                                    <div className="p-col-12 name-container">
                                        <label>Name</label>
                                        <Field name='name' autoComplete="off" as={InputText} />
                                        <ErrorMessage name='name' component={FormErrorMsg} />
                                    </div>

                                    <div className="p-col-12 address-container">
                                        <label>Address</label>
                                        <Field name='address' autoComplete="off" as={InputText} />
                                        <ErrorMessage name='address' component={FormErrorMsg} />
                                    </div>

                                    <div className="p-col-12 country-container">
                                        <label>Country</label>
                                        <Field name='country' as={Dropdown} options={countries} />
                                        <ErrorMessage name='country' component={FormErrorMsg} />

                                    </div>

                                    <div className="p-col-12 email-container">
                                        <label>Email</label>
                                        <Field name='email' autoComplete="off" as={InputText} />
                                        <ErrorMessage name='email' component={FormErrorMsg} />
                                    </div>

                                    <div className="p-col-12 mobile-container">
                                        <label>Mobile Number</label>
                                        <Field name='mobile' autoComplete="off" as={InputText} />
                                        <ErrorMessage name='mobile' component={FormErrorMsg} />
                                    </div>

                                    <div className="p-col-12 phone-container">
                                        <label>Telephone</label>
                                        <Field name='phone' autoComplete="off" as={InputText} />
                                        <ErrorMessage name='phone' component={FormErrorMsg} />
                                    </div>

                                    <div className="p-col-12 notes-container">
                                        <label>Notes</label>
                                        <Field name='notes' autoComplete="off" as={InputText} />
                                        <ErrorMessage name='notes' component={FormErrorMsg} />
                                    </div>

                                    <div className="p-col-12 tags-container">
                                        <label>Tags</label>
                                        <Field name='tags' as={MultiSelect} options={tags} placeholder="Select Tags" />
                                        <ErrorMessage name='tags' component={FormErrorMsg} />
                                    </div>
                                </div>
                                <div className="p-grid p-fluid form-action-container">
                                    <div className="p-col p-lg-4">
                                        <Button type='submit' disabled={formik.isSubmitting} label={editMode ? 'Update' : 'Save'} />
                                    </div>
                                    <div className="p-col p-lg-4">
                                        <Button type='button' onClick={() => { formik.resetForm(); setVisible(false) }} label="Cancel" className='p-button-secondary' />
                                    </div>
                                </div>
                            </div>
                        </Form>
                    }
                </Formik>
            </Dialog>
        </Fragment>
    );
})