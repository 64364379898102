import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router";
import './settings.css'
import { getItem } from './storage';
const ManageRoles = () =>{
    const history = useHistory();

    const Goback = () => {
        history.goBack()
    }
    return <Fragment>
        <div className="settingsBody">
            <div className="breadcrum">
            <h2 onClick={Goback} className="breadcrumText activeBreadcrum">MY PROFILE</h2 >
                <h2 className="breadcrumText"> &gt; </h2>
                <h2 className="breadcrumText">MANAGE ROLES</h2>

            </div>
            <hr className="hr"/>
            <div className="settingsCont">
                <Link to="/manageWaiter">
                <div className="displayBtns">
                    <img className="settingsImg" src="./assets/images/waiter.svg" alt="manage Waiters" />
                    <p>MANAGE WAITERS</p>
                    <i className="pi pi-caret-right" />
                </div>
                </Link>

                {getItem('user-role')==="admin"?
                <Link to="/manageCo-admin">
                <div className="displayBtns">
                <img className="settingsImg2" src="./assets/images/co-admin.svg" alt="manage co-admins" />
                    <p>MANAGE CO-ADMINS</p>
                    <i className="pi pi-caret-right" />
                </div>
                </Link>:null}

            </div>
        </div>
    </Fragment>
}

export default ManageRoles