import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from "react-router";
import { Field, Form, Formik } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import './manageFeatures.css'
import { RadioButton } from 'primereact/radiobutton';
import { InputMask } from 'primereact/inputmask';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { useStore } from "../../src/stores/useStore";
import Toaster from '../components/Toaster/toaster';
import { ToastContainer, toast } from 'react-toastify';
import * as moment from 'moment';
// import timeZone from 'moment-timezone';
import * as yup from 'yup';
import { SyntaxKind } from 'typescript';
import { request } from 'http';
import { getItem, setItem } from './storage';
import { Dialog } from 'primereact/dialog';
import ok from '../images/icons8-ok.gif'
var timeZone = require('moment-timezone');

toast.configure()
const ManageFeatures = () => {
    const history = useHistory();
    const { restaurantStore } = useStore()
    const [show, setShow] = useState(true);

    const categories = [{ time: 30, name: "Half an Hour" }, { time: 45, name: "45 Minutes" }, { time: 60, name: "1 hour" }, { time: 90, name: "1.5 hour" }, { time: 105, name: "1.75 hour" }, { time: 120, name: "2 hours" }, { time: 150, name: "2.5 hours" }, { time: 180, name: "3 hours" }];
    const [Ttype, setTtype] = useState("");
    const [msg, setMsg] = useState("");
    const [dialog2, setDialog2] = useState(false);
    const [timezone, setTimezone] = useState<any>([{ name: "" }]);
    const [editPrice, setEditPrice] = useState(true);
    const [dataRes, setData] = useState<any>()
    const [errorMsg, setErrorMsg] = useState(null)
    const [otpRes, setOtp] = useState(Boolean);
    const [timeRes, setTime] = useState(categories[1]);
    const [limitRes, setLimit] = useState(0);
    const [amountRes, setAmount] = useState("");
    const [autodepRes, setAutoDeposit] = useState(0);
    const [ngp, setNgp] = useState(5);
    const [hk, setHk] = useState(10);
    const [TWILIO_ACCOUNT_SID, setTWILIO_ACCOUNT_SID] = useState("");
    const [TWILIO_AUTHTOKEN, setTWILIO_AUTHTOKEN] = useState("");
    const [TWILIO_FROM_NO, setTWILIO_FROM_NO] = useState("");
    const [stripe_link, setStripeLink] = useState(true);
    const [primaryColor, setPrimaryColor] = useState("#5100EA");
    const [widget, setWidget] = useState(Boolean);

    // const [STRIPE_API_KEY,setSTRIPE_API_KEY]=useState("");
    // const [STRIPE_SECRET_KEY,setSTRIPE_SECRET_KEY]=useState("");

    const timezn = () => {
        let ttz = timeZone.tz.zonesForCountry('GB')

        let tt: any = []
        ttz.map((t: any) => (
            tt.push({ "name": t })
        ))
        return tt[0]
    }
    const timeZones = [timezn(), { name: timeZone.tz.guess() }];
    console.log(timeZones)
    const onHide = () => {
        setDialog2(false);
    }
    const onBoard = () => {
        restaurantStore.stripeOnBoard()
            .then(([success, message]: any) => {
                if (success) {
                    console.log("onboarding", message)
                    setDialog2(true)
                    setTimeout(() => {
                        setDialog2(false)
                    }, 5000);
                    getRestData();
                } else {
                    console.log(message)
                }
            })
    }
    console.log("restaurent settings data", dataRes);
    const getRestData = () => {
        restaurantStore.getRestaurantSettings(getItem('RestaurantId'))
            .then(([success, data]) => {
                if (success) {

                    setData(data?.restaurant_settings);
                    setOtp(data?.restaurant_settings.is_otp_enabled ? true : false);
                    setWidget(data?.restaurant_settings?.booking_widget_settings?.display_type === 'popup' ? true : false)
                    setPrimaryColor(data?.restaurant_settings?.booking_widget_settings?.theme?.primarycolor)
                    setTimezone({ "name": data?.restaurant_settings.timezone });
                    setTime({
                        "time": data.restaurant_settings.booking_period,
                        "name": data.restaurant_settings.booking_period === 30 ? "Half an Hour" :
                            data.restaurant_settings.booking_period === 45 ? "45 Minutes" :
                                data.restaurant_settings.booking_period === 60 ? "1 hour" :
                                    data.restaurant_settings.booking_period === 90 ? "1.5 hour" :
                                        data.restaurant_settings.booking_period === 105 ? "1.75 hour" :
                                            data.restaurant_settings.booking_period === 120 ? "2 hours" :
                                                data.restaurant_settings.booking_period === 150 ? "2.5 hours" :
                                                    data.restaurant_settings.booking_period === 180 ? "3 hours" : ""
                    });
                    setLimit(data?.restaurant_settings.party_size_limit);
                    setAmount(data?.restaurant_settings.deposit_amount);
                    setAutoDeposit(data?.restaurant_settings.deposit_min_guest);
                    setNgp(data?.restaurant_settings?.no_show_period);
                    setHk(data?.restaurant_settings?.duration_btn_bookings);
                    setTWILIO_ACCOUNT_SID(data?.restaurant_settings?.TWILIO_ACCOUNT_SID)
                    setTWILIO_AUTHTOKEN(data?.restaurant_settings?.TWILIO_AUTHTOKEN)
                    setTWILIO_FROM_NO(data?.restaurant_settings?.TWILIO_FROM_NO)
                    setStripeLink((data?.restaurant_settings.stripe_onboarding_status === "Pending") ? true : false)
                    // setSTRIPE_API_KEY(data?.restaurant_settings?.STRIPE_API_KEY)
                    // setSTRIPE_SECRET_KEY(data?.restaurant_settings?.STRIPE_SECRET_KEY)
                    setShow(false);
                } else {
                    // setErrorMsg(data)
                    toast.error(data);
                }
            })
    }
    useEffect(() => {
        getRestData();

    }, [])

    const saveSettings = () => {

        let settings = {
            name: dataRes?.restaurant_name,
            logo_url: dataRes?.restaurant_logo,
            address: {
                address1: dataRes?.address.address1,
                address2: dataRes?.address.address2,
                land_mark: dataRes?.address.land_mark,
                zip_code: dataRes?.address.zip_code,
                latitude: dataRes?.address.latitude,
                longitude: dataRes?.address.longitude,
                city: dataRes?.address.city,
                state: dataRes?.address.state,
                country: dataRes?.address.country
            },
            email: dataRes?.restaurant_email,
            mobile: dataRes?.restaurant_mobile,
            status: dataRes?.status,
            party_size_limit: limitRes,
            // per_head_booking_deposit: parseInt(amountRes.replace("£ ","")),
            is_otp_enabled: otpRes,
            domain: "string",
            venue_start_time: dataRes?.venue_start_time,
            venue_close_time: dataRes?.venue_close_time,
            deposit_amount: parseInt(typeof (amountRes) === "string" ? amountRes.replace("£ ", "") : amountRes),
            booking_period: timeRes.time,
            deposit_min_guest: autodepRes,
            rest_id: dataRes?.rest_id,
            no_show_period: ngp,
            duration_btn_bookings: hk,
            TWILIO_ACCOUNT_SID: TWILIO_ACCOUNT_SID,
            TWILIO_AUTHTOKEN: TWILIO_AUTHTOKEN,
            TWILIO_FROM_NO: TWILIO_FROM_NO,
            // STRIPE_API_KEY: STRIPE_API_KEY,
            // STRIPE_SECRET_KEY: STRIPE_SECRET_KEY,
            timezone: timezone.name,
            booking_widget_settings: JSON.stringify({
                theme: {
                    primarycolor: primaryColor,
                    secondarycolor: '#000',
                    backgroundcolor: '#fff',
                    fontfamily: 'Open Sans',
                },
                display_type: widget ? 'popup' : 'inline',
            }),
        }
        console.log(settings);
        restaurantStore.updateSettings(settings)
            .then(([success, message]) => {
                if (success) {
                    // setData(data.restaurant_settings);
                    setShow(false);
                    // setTtype("success");
                    // setMsg("saved features succesfully");
                    toast.success("Saved Features Successfully")
                    getRestData();
                } else {
                    toast.error(message)
                    getRestData();
                    console.log("message", message);

                }
            })

    }
    const Goback = () => {
        history.goBack()
    }
    const customerLimit = (e: any) => {
        const count = e.value;
        if (count > 0) {
            setLimit(count);
        }
        else {
            setLimit(0);
        }
    }
    const AutoDeposit = (e: any) => {
        const count = e.value;
        if (count > 0) {
            setAutoDeposit(count);
        }
        else {
            setAutoDeposit(0);
        }
    }
    return <Fragment>
        <Toaster type={Ttype} text={msg} />

        {show ? null :

            <Formik
                initialValues={{ verification: "", duration: "", customerLimit: "", amount: "", autoDeposit: "" }}
                onSubmit={() => {
                    saveSettings();
                }}
            >
                {formik => <Form>
                    <div className="manageFeatures">
                        <div className="breadcrum">
                            <h2 onClick={Goback} className="breadcrumText activeBreadcrum">Settings</h2 >
                            <h2 className="breadcrumText"> &gt; </h2>
                            <h2 className="breadcrumText">manage Features</h2>
                        </div>
                        <hr className="hr" />
                        <div className="mfCont">

                            <h1 className="mfHeading">Customer Verification  <button type="submit" className="save"><img className="price-editIcon" src="./assets/images/save.svg" alt="" /> &nbsp;Save all Changes</button></h1>
                            <hr className="hr" />
                            <div className="otpHolder">
                                <div className="radioHolder">
                                    <div>
                                        <Field type='radio' name='verification' onChange={(e: any) => setOtp(true)} checked={otpRes === true} value="otp" default={true} as={RadioButton} />
                                        <label className="otpReservation" >&nbsp; OTP</label>
                                    </div>
                                    <div>
                                        <Field type='radio' name='verification' onChange={(e: any) => setOtp(false)} checked={otpRes === false} value="captcha" as={RadioButton} />
                                        <label className="otpReservation" >&nbsp; CAPTCHA</label>
                                    </div>
                                </div>
                            </div>

                            <h1 className="mfHeading">Time zone</h1>
                            <hr className="hr" />
                            <div className="dbp">
                                <div className="radioHolder">
                                    <Dropdown style={{ outline: "none", boxShadow: "none" }} name="time" className="tableDrop" onChange={(e) => setTimezone(e.value)} value={timezone} options={timeZones} optionLabel="name" placeholder="Select Time Zone" />
                                </div>
                            </div>

                            <h1 className="mfHeading">Default Booking Period</h1>
                            <hr className="hr" />
                            <div className="dbp">
                                <div className="radioHolder">
                                    <Dropdown style={{ outline: "none", boxShadow: "none" }} name="time" className="tableDrop" onChange={(e) => setTime(e.value)} value={timeRes} options={categories} optionLabel="name" placeholder="Select Time" />
                                </div>
                            </div>


                            <div className="setAmount">
                                <div className="p-field">
                                    <h1 className="mfHeading">Set booking limit</h1>
                                    <hr className="hr" />
                                    <br />
                                    <span>Customer Limit To : </span>
                                    <InputNumber readonly id="horizontal" name="customerLimit" className="numinc" value={limitRes} onChange={(e: any) => customerLimit(e)} showButtons buttonLayout="horizontal" max={25}
                                        decrementButtonClassName="p-inpnumber-button" incrementButtonClassName="p-inpnumber-button" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                                </div>
                            </div>

                            <h1 className="mfHeading">Set booking deposit for guest</h1>
                            <hr className="hr" />
                            <div className="setAmount">
                                <span onClick={() => setEditPrice(!editPrice)} className="price-edit">{editPrice ? <span><img className="price-editIcon" src="./assets/images/edit2.png" alt="" />&nbsp;EDIT</span> : <span onClick={() => saveSettings()} className="pricesave"><img className="price-editIcon" src="./assets/images/save.svg" alt="" />&nbsp;SAVE</span>}</span>
                                <div className="p-field">
                                    <span className="pound">£</span>     <Field as={InputNumber} name="amount" min={0} max={99} value={amountRes} onChange={(e: any) => setAmount(e.value)} readonly={editPrice} className="priceinputtext priceAm txt" />
                                    <span className="guest"> / GUEST</span>
                                </div>

                                <div className="p-field">
                                    <span>Auto deposit above : </span>
                                    <InputNumber readonly id="horizontal" max={25} name="autoDeposit" value={autodepRes} onChange={(e: any) => AutoDeposit(e)} className="numinc" showButtons buttonLayout="horizontal"
                                        decrementButtonClassName="p-inpnumber-button" incrementButtonClassName="p-inpnumber-button" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                                </div>

                                {/* <div>
                                    <button style={{ width: "153px",float:"right" }} type="submit" className="filled">submit</button>
                                </div> */}
                            </div>
                            <div className="setAmount">
                                <div className="p-field">
                                    <h1 className="mfHeading">No-show Grace Period</h1>
                                    <hr className="hr" />
                                    <br />
                                    <span>Time in Minutes : </span>
                                    <InputNumber readonly id="horizontal" name="customerLimit" className="numinc" value={ngp} onChange={(e: any) => setNgp(e.value)} showButtons buttonLayout="horizontal" min={5} max={20}
                                        decrementButtonClassName="p-inpnumber-button" incrementButtonClassName="p-inpnumber-button" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                                </div>
                            </div>
                            <div className="setAmount">
                                <div className="p-field">
                                    <h1 className="mfHeading">Housekeeping Interval</h1>
                                    <hr className="hr" />
                                    <br />
                                    <span>Time in Minutes : </span>
                                    <InputNumber readonly id="horizontal" name="customerLimit" className="numinc" value={hk} onChange={(e: any) => setHk(e.value)} showButtons buttonLayout="horizontal" min={5} max={15}
                                        decrementButtonClassName="p-inpnumber-button" incrementButtonClassName="p-inpnumber-button" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                                </div>
                            </div>
                            <div className="">
                                <div className="p-field  ">
                                    <h1 className="mfHeading">Twilio Settings</h1>
                                    <hr className="hr" />
                                    <br />
                                    <div className="secretkeys">
                                        <div>
                                            <span>TWILIO_ACCOUNT_SID</span>
                                            <br />
                                            <input type="text" value={TWILIO_ACCOUNT_SID !== "string" ? TWILIO_ACCOUNT_SID : ""} onChange={(e: any) => setTWILIO_ACCOUNT_SID(e.target.value)} className="twinp"></input>
                                        </div>
                                        <div>
                                            <span>TWILIO_AUTHTOKEN</span>
                                            <br />
                                            <input type="text" value={TWILIO_AUTHTOKEN !== "string" ? TWILIO_AUTHTOKEN : ""} onChange={(e: any) => setTWILIO_AUTHTOKEN(e.target.value)} className="twinp"></input>
                                        </div>
                                        <div>
                                            <span>TWILIO_FROM_NO</span>
                                            <br />
                                            <input type="text" value={TWILIO_FROM_NO !== "string" ? TWILIO_FROM_NO : ""} onChange={(e: any) => setTWILIO_FROM_NO(e.target.value)} className="twinp"></input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                <div className="p-field  ">
                                    <h1 className="mfHeading">Widget Theme Settings</h1>
                                    <hr className="hr" />
                                    <br />
                                    <div className="secretkeys">
                                        <div>
                                            <span>Primary Color</span>
                                            <br />
                                            <input type="color" style={{ padding: '0', width: '200px' }} value={primaryColor} onChange={(e: any) => setPrimaryColor(e.target.value)} className="twinp"></input>
                                        </div>
                                        <div>
                                            <span>Widget Type</span>
                                            <br />
                                            <br />
                                            <div className="radioHolder">
                                                <div>
                                                    <Field type='radio' name='widgetType' onChange={(e: any) => setWidget(true)} checked={widget === true} value="popup" default={true} as={RadioButton} />
                                                    <label className="otpReservation" >&nbsp; POP-UP</label>
                                                </div>
                                                <div>
                                                    <Field type='radio' name='widgetType' onChange={(e: any) => setWidget(false)} checked={widget === false} value="inline" as={RadioButton} />
                                                    <label className="otpReservation" >&nbsp; IN-LINE</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'self-end' }}>
                                            <span>Preview</span>
                                            <br />
                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'self-end' }}>
                                                <button style={{ background: primaryColor, width: '200px', color: '#fff', border: 'none' }}>Primary Button</button>
                                                <br />
                                                <h2 style={{ color: primaryColor }}>Heading Style with primary color.</h2>
                                                <br />
                                                <p style={{ color: primaryColor }}>Paragraph Style with primary color.</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            {stripe_link ?
                                <div className="setAmount">
                                    <div className="p-field">
                                        <h1 className="mfHeading">Stripe</h1>
                                        <hr className="hr" />
                                        <br />
                                        <h3 style={{ fontWeight: 500 }}>Click here for <span onClick={() => onBoard()} style={{ color: "#510aea", cursor: "pointer" }}>Stripe OnBoarding</span></h3>
                                    </div>
                                </div> : null}
                            <Dialog visible={dialog2} style={{ width: '358px', textAlign: "center" }} onHide={() => onHide()}>
                                <img width="100px" height="100px" src={ok} alt="ok" />
                                <h3 className="logoutTtl">Thanks for submitting Stripe OnBoarding Request. You will receive an email with Stripe onboarding link.</h3>
                            </Dialog>
                        </div>
                    </div>
                </Form>
                }
            </Formik>
        }

    </Fragment>
}

export default ManageFeatures