import React, { useState, useEffect, Fragment } from 'react';
import styles from './AdminProfile.module.css';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useStore } from "../stores/useStore";
import * as yup from 'yup';
import { InputMask } from 'primereact/inputmask';
import Toaster from '../components/Toaster/toaster';
import { toast } from 'react-toastify';
import { getItem } from './storage'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
// import {getRefreshToken} from '../stores/apiClient'
const FormErrorMsg = styled.span`
    color: red;
    float:right;
    font-size:12px;
   `
toast.configure();

const AdminProfile = () => {
    const [bi, setBI] = useState(true);
    const [admin, setAdmin] = useState();
    const [showRL, setShowRL] = useState(false);
    const [uploadedImage, setUploadedImage] = useState('');
    const [uploadingImage, setUploadingImage] = useState('');
    const [displayModal, setDisplayModal] = useState(false);
    const [position, setPosition] = useState('center');
    const { restaurantStore } = useStore();
    const [Ttype, setTtype] = useState("");
    const [msg, setMsg] = useState("");

    const onClick = (name: any, position: any) => {
        if (position) {
            setPosition(position);
        }
        if (name === 'displayModal') {
            setDisplayModal(true)
        }
    }

    const onHide = (name: any) => {
        // dialogFuncMap[`${name}`](false);

        setUploadedImage('');
        setUploadingImage('');

        if (name === 'displayModal') {
            setDisplayModal(false)
        }

    }
    const [showWarning, setShowWarning] = useState('');
    const [imagePath, setImagePath] = useState("");

    const showLogo = (img: any) => {
        console.log("image responce while uploading : ", img.target.files[0])

        var formdata = new FormData()
        formdata.append('logo_url', img.target.files[0])

        var maxSize = 200 * 1024; // 200 is size in kb * 1024 gives size in bytes

        if (img.target.files.length > 0 && img.target.files[0].size <= maxSize) {
            let image = new Image();
            let url = (URL.createObjectURL(img.target.files[0]));

            image.onload = () => {
                console.log(image.naturalWidth, image.naturalHeight)
                if (image.naturalWidth <= 200 && image.naturalHeight <= 200) {
                    console.log(formdata)
                    restaurantStore.uploadImg(formdata)
                        .then(([success, data]: any) => {
                            if (success) {
                                setImagePath(data);
                                console.log("success image upload", data)
                            } else {
                                console.log("Image upload error responce : ", data)
                            }
                        })
                    setUploadedImage(url);
                    setUploadingImage(styles.uploadimg)
                    setShowWarning('');
                }

                else {
                    setUploadedImage("");
                    setUploadingImage("");
                    setShowWarning(styles.showerr);
                    console.log("please upload an image of 200x200")
                }
            };
            image.src = (URL.createObjectURL(img.target.files[0]));
        }
        else {
            setUploadedImage("");
            setUploadingImage("");
            setShowWarning(styles.showerr);
            console.log("please upload an image size less then or equal to 200kb")
        }
    }

    const [fName, setFname] = useState('');
    const [lName, setLname] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [mobile2, setMobile2] = useState('');
    const [mobile3, setMobile3] = useState('');
    const [gender, setGender] = useState('');
    const [subID, setsubID] = useState('');
    //------Get user------
    const [restaurent, setRestaurent] = useState([{}]);
    const addRest = (rest: any) => {
        console.log(rest)
        // if(rest.is_deleted === "false"){}
        var resta = rest.filter((rest: any) => { return typeof (rest.restaurant_id) === ("number") })
        setRestaurent(resta)
    }
    const user = () => {
        let id = getItem('role-id');
        const admin = () => {
            restaurantStore.getUser(id)
                .then(([success, data]) => {
                    if (success) {
                        addRest(data.admin_info.restaurant_list);
                        setAdmin(data.admin_info);
                        setFname(data.admin_info.first_name);
                        setLname(data.admin_info.last_name);
                        setEmail(data.admin_info.email);
                        setMobile(data.admin_info.mobile);
                        setMobile2(data.admin_info.mobile);
                        setGender(data.admin_info.gender);
                        setsubID(data.admin_info.current_subscription_id)

                        if ((data.admin_info.restaurant_list).length > 0) {
                            setShowRL(true);
                        }
                        console.log("success", data)
                    } else {
                        console.log("get user error responce : ", data)
                    }
                })
        }
        const coadmin = () => {
            restaurantStore.getUser2(id)
                .then(([success, data]) => {
                    if (success) {
                        addRest([data.co_admin_info.restaurant]);
                        setAdmin(data.co_admin_info);
                        setFname(data.co_admin_info.first_name);
                        setLname(data.co_admin_info.last_name);
                        setEmail(data.co_admin_info.email);
                        setMobile(data.co_admin_info.mobile);
                        setMobile2(data.co_admin_info.mobile);
                        setGender(data.co_admin_info.gender)
                        setShowRL(true);
                        console.log("success", data)
                    } else {
                        console.log("get user error responce : ", data)
                    }
                })
        }
        getItem('user-role') === "admin" ? admin() : coadmin()
    }

    const [otp, setOtp] = useState('');
    const [showOtp2, setShowOtp2] = useState(true);
    const [showOtp, setShowOtp] = useState(false);

    const getOtp = () => {
        if (mobile.length === 13) {
            console.log("otp mobile", mobile)
            const num = mobile.replace("+", "%2B")
            console.log("otp mobile", num)
            restaurantStore.getOtp(num)
                .then(([success, message]: any) => {
                    if (success) {
                        console.log("otp : ", message.data);
                        setTtype("success");
                        setMsg(message);
                    } else {
                        console.log("otp : ", message)
                        setTtype("error");
                        setMsg(message.msg);
                    }
                })
        }
    }
    const verifyOtp = (e: any) => {
        setOtp(e.target.value);
        console.log(e.target.value)
        if (e.target.value.length === 6) {
            const num = mobile.replace("+", "%2B")
            restaurantStore.verifyOtp(num, e.target.value)
                .then(([success, message]: any) => {
                    if (success) {
                        console.log("otp verification : ", message);
                        toast.success(message);
                        setTtype("success");
                        setMsg(message);
                        setShowOtp2(true);
                        setShowOtp(false)

                    } else {
                        console.log("otp verification error : ", message)
                        toast.error(message);
                        setTtype("error");
                        setMsg(message.msg);
                    }
                })
        }
    }

    const updateUser = () => {
        const userData = {
            "first_name": fName,
            "last_name": lName,
            "email": email,
            "mobile": mobile,
            "gender": gender,
            "updated_by": getItem("role-id"),
            "admin_id": getItem("role-id"),
            "current_subscription_id": subID
        }
        restaurantStore.updateAdmin(userData)
            .then(([success, data]) => {
                if (success) {
                    toast.success("Admin info updated");
                    console.log(data)
                    setBI(true);
                    user();

                    console.log("success", data)
                } else {
                    toast.error(data)
                    console.log("get user error responce : ", data)
                }
            })

    }

    useEffect(() => {
        user();
    }, [])

    const [isDesktop, setIsDesktop] = useState(true)
    useEffect(() => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            setIsDesktop(false)
        }
        else setIsDesktop(true)
    }, [])
    return (
        <Fragment>
            <Toaster type={Ttype} text={msg} />

            <div className={styles.adminprofile}>
                <div className={styles.adminDetails}>
                    <div className={styles.myProfile}>
                        <h1>MY PROFILE</h1>
                    </div>
                    <hr className={styles.hr} />
                    <div className={styles.adminRest}>
                        <div style={{ width: isDesktop ? "443px" : "100%" }} className={styles.profileDetails}>
                            <div className={styles.imgBackground}>
                                <div className={styles.imgBorder}>
                                    <img className={styles.adminImg} src="./assets/images/admin3.png" alt="admin-img" />
                                </div>
                                <div className={styles.changePI} hidden>
                                    <i className="pi pi-camera"></i>
                                </div>
                            </div>
                            {showRL ? <div><div className={styles.details}>
                                <h6>{fName + ' ' + lName}</h6>
                                <p>{admin?.role + ', ' + admin?.organization_name}</p>
                                <Link to="/manage-roles">
                                    <button className={styles.filled}>MANAGE ROLES</button>
                                </Link>
                                {admin?.role === "admin" ?
                                    <Link to="/MySubscriptions">
                                        <button className={styles.linked}>MANAGE SUBSCRIPTION <span style={{ float: "right" }}><i className="pi pi-caret-right "></i></span></button>
                                    </Link> : null}

                                <Link to="/change-password">
                                    <button className={styles.linked}>CHANGE PASSWORD <span style={{ float: "right" }}><i className="pi pi-caret-right "></i></span></button>
                                </Link>

                            </div>


                                <div className={styles.basicInformation}>
                                    <h5>Basic Information  &nbsp;<span onClick={() => setBI(false)} style={{ float: 'right' }} className={styles.edit}> <img className={styles.editIcon} src="./assets/images/edit2.png" alt="" />&nbsp;EDIT</span></h5>
                                    <hr className={styles.hr} />
                                    <br />
                                    <Formik
                                        initialValues={{ FirstName: "", LastName: "", Email: "", Mobile: "" }}
                                        // validationSchema={yup.object({
                                        //     FirstName: yup.string()
                                        //         .required('Required'),
                                        //     LastName: yup.string()
                                        //         .required('Required'),
                                        //     Email: yup.string().email()
                                        //         .required('Required'),
                                        // })}
                                        onSubmit={(values) => { updateUser(); }}
                                    >{({ errors, status, touched }) => (<Form>
                                        <div className='form-control-group'>
                                            <div>
                                                <label>First Name</label>
                                                <ErrorMessage name='FirstName' component={FormErrorMsg} />
                                                <Field required style={{ margin: '5px 0 10px' }} value={fName} onChange={(e: any) => setFname(e.target.value)} autoComplete="off" minLength="5" maxLength="15" as={InputText} type="text" name="FirstName" readOnly={bi}
                                                    className={(errors.FirstName && touched.FirstName ? ' is-invalid' : styles.inputtext)} />
                                            </div>
                                            <div>
                                                <label>Last Name</label>
                                                <ErrorMessage name='LastName' component={FormErrorMsg} />
                                                <Field required style={{ margin: '5px 0 10px' }} value={lName} onChange={(e: any) => setLname(e.target.value)} autoComplete="off" minLength="5" maxLength="15" as={InputText} type="text" name="LastName" readOnly={bi}
                                                    className={(errors.LastName && touched.LastName ? ' is-invalid' : styles.inputtext)} />
                                            </div>
                                            <div>
                                                <label>Email</label>
                                                <ErrorMessage name='Email' component={FormErrorMsg} />
                                                <Field required type="email" style={{ margin: '5px 0 10px' }} value={email} onChange={(e: any) => setEmail(e.target.value)} autoComplete="off" as={InputText} name="Email" readOnly={bi}
                                                    className={(errors.Email && touched.Email ? ' is-invalid' : styles.inputtext)} />
                                            </div>
                                            <div>
                                                <label>Mobile</label>
                                                <span id="phone"
                                                    style={{ display: "none", float: "right" }}
                                                    className="float-right">Required</span>
                                                <div id="pinp" className={styles.mobileInp}>
                                                    <Field required value={mobile} onChange={(e: any) => {
                                                        if (mobile2 !== e) {
                                                            setShowOtp2(false);
                                                            setShowOtp(true)
                                                        }
                                                        else { setShowOtp2(true); setShowOtp(false) }
                                                        console.log(e)

                                                        if (e?.length <= 11) {
                                                            let p = document.getElementById('phone')!
                                                            let pi = document.getElementById('pinp')!
                                                            p.style.color = "red"
                                                            p.style.display = "initial"
                                                            pi.style.border = "1px solid red"
                                                        }
                                                        else {
                                                            let p = document.getElementById('phone')
                                                            let pi = document.getElementById('pinp')
                                                            p.style.display = "none"
                                                            pi.style.border = "none"

                                                        }

                                                        setMobile('+' + e)
                                                    }}
                                                        onBlur={(e: any) => {
                                                            console.log("on blur phone", e.target.value)
                                                            if (e.target.value?.length <= 3) {
                                                                let p = document.getElementById('phone')
                                                                let pi = document.getElementById('pinp')
                                                                p.style.color = "red"
                                                                p.style.display = "initial"
                                                                pi.style.border = "1px solid red"
                                                            }
                                                            else {
                                                                let p = document.getElementById('phone')
                                                                let pi = document.getElementById('pinp')
                                                                p.style.display = "none"
                                                                pi.style.border = "none"

                                                            }
                                                        }}
                                                        autoComplete="off" as={PhoneInput}
                                                        onlyCountries={['gb', 'in']}
                                                        country={'gb'}
                                                        type="tel" name="Mobile" disabled={bi}
                                                        className={(errors.Mobile && touched.Mobile ? ' is-invalid' : '')}
                                                    />
                                                    {/* <span hidden={showOtp2} onClick={getOtp} className={styles.MblOTP}>GET OTP</span> */}
                                                </div>
                                            </div>
                                            {/* {!showOtp2 ?
                                                <div>
                                                    <label>Otp</label>
                                                    <ErrorMessage name='Otp' component={FormErrorMsg} />
                                                    <Field as={InputText} min="000001" max="999999" value={otp} onChange={(e: any) => {
                                                        verifyOtp(e);
                                                    }} autoComplete="off" type="number" name="Otp"
                                                        //  disabled={showOtp2}
                                                        className={styles.inputtext} />

                                                </div> : null} */}
                                            <br />
                                            <Button type="submit"
                                                //  disabled={showOtp} 
                                                className='filledSmall' style={{ display: bi ? 'none' : 'initial', marginRight: "10px" }} label="save" />
                                            <Button type="button" className='outlineSmall' onClick={() => window.history.go(0)} style={{ display: bi ? 'none' : 'initial' }} label="cancel" />
                                        </div>
                                    </Form>
                                    )}
                                    </Formik>
                                </div>
                            </div> : null}
                        </div>
                        <hr className={styles.vr} />

                        <div className={styles.restaurants}>
                            <h5>Restaurants <span style={{ float: 'right', fontWeight: 500, fontSize: '16px', cursor: 'pointer' }}>
                                <Link to="/add-new-restaurant"><i className="fas fa-plus"></i> &nbsp;Add Branch</Link>
                            </span></h5>
                            <hr className={styles.hr} />
                            {/* <button type="button" onClick={()=>getRefreshToken()}>refresh</button> */}
                            {showRL ?
                                <div className={styles.restaurantsList}>
                                    {restaurent.map(
                                        (rest: any) => {
                                            return <div key={rest.restaurant_id} id={rest.restaurant_id} className={styles.rest}>
                                                <h6>{rest.restaurant_name}</h6>
                                                <p><i className="fas fa-map-marker-alt"></i> &nbsp; {rest.address1} , {rest.address2}</p>
                                                <span className={styles.edit}>
                                                    <Link to={{ pathname: "/edit-branch", state: { restaurantId: rest.restaurant_id } }}><img className={styles.editIcon} src="./assets/images/edit2.png" alt="" />&nbsp;EDIT</Link></span>
                                            </div>
                                        }
                                    )}
                                </div>
                                : null}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment >)
}
export default AdminProfile



