import { action, computed, observable, autorun } from 'mobx';
// import moment from 'moment';
import apiClient from './apiClient';
import { getItem, setItem, clear } from '../pages/storage'
let access = getItem('access');

export class UserSessionStore {

    @observable
    user: any = getItem('user-role')
    id: any = getItem('user-id')
    // @observable
    // user: any
    // id: any 


    @computed
    get isAuthenticated() {
        return this.id
    }

    @action
    authenticateUsingToken() {
        if (this.user) {
            return true
        }
        else return false
    }
    @action
    authenticate(username?: string, password?: string, isChecked?: boolean) {
        console.log(isChecked);
        let user = { 'username': username, 'password': password }
        return apiClient.post('/api/v1/login', user)
            .then(action((res: any) => {
                if (isChecked) {
                    localStorage.setItem('Remember-me', isChecked.toString())
                }
                if (res.data.access_token) {
                    setItem('access', res.data.access_token)
                    setItem('refresh', res.data.refresh_token)
                    setItem('user-role', res.data.user_role)
                    setItem('user-id', res.data.user_id)
                    setItem('role-id', res.data.role_id)
                    setItem('org-id', res.data.org_id);

                    if (res.data.user_role === 'admin') {
                        setItem('RestaurantId', res.data.rest_id)
                    }
                    else setItem('RestaurantId', res.data.restaurant_id)
                }

                return res.data
            }))
            .then(action((res: any) => {
                if (localStorage.getItem('Remember-me') === "true") {
                    this.user = res.user_role
                    this.id = res.user_id
                    return [true, 'Authenticated']
                }
                else if (res.user_role !== "waiter") {
                    this.user = res.user_role
                    this.id = res.user_id
                    return [true, 'Authenticated']
                }
                else return [false, 'Nice try... You are not an Admin']
                // this.user = res.data
                // return [true, 'Authenticated']
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    @action
    logout() {
        clear();
        this.user = null
    }

    @action
    signup(data: any) {
        return apiClient.post('/api/v1/users/signup', data)
            .then(action((res: any) => {
                return [true, 'Success']
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    @action
    resetPassword(data: string) {
        let email = { "email": data }
        return apiClient.post('/api/v1/reset-password', email)
            .then(action((res) => {
                return [true, 'Success']
            }))
            .catch(action((err) => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                console.log("error : ", errorMsg)
                return [false, errorMsg]
            }))
    }

    @action
    changePassword(current: string, newP: string) {
        let password = {
            "current_password": current,
            "new_password": newP
        }
        return apiClient.post('/api/v1/change-password', password, {
            headers: {
                Authorization: 'Bearer ' + access,
            }
        })
            .then(action((res) => {
                return [true, 'Success']
            }))
            .catch(action((err) => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                console.log("error : ", errorMsg)
                return [false, errorMsg]
            }))
    }

    @action
    adminDetails() {
        return apiClient.get(`/api/v1/admin/${this.id}`)
            .then(action((res) => {
                return [true, 'Success']
            }))
            .catch(action((err) => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                console.log("error : ", errorMsg)
                return [false, errorMsg]
            }))
    }
    @action
    waiterList() {
        return apiClient.get(`/api/v1/waiter/list/${getItem('RestaurantId')}`)
            .then(action((res) => {
                return [true, res]
            }))
            .catch(action((err) => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                console.log("error : ", errorMsg)
                return [false, errorMsg]
            }))
    }
    @action
    subscriptiondata() {
        return apiClient.get('/api/v1/stripe/admin/subscription_details', {
            headers: {
                Authorization: 'Bearer ' + getItem('access')
            }
        }).then(action((res) => {
            return [true, res]
        }))
            .catch(action((err) => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                console.log("error : ", errorMsg)
                return [false, errorMsg]
            }))
    }
    @action
    autorenewal(ar: any) {
        return apiClient.post(`/api/v1/stripe/admin/auto_renewal/on/off?auto_renewal=${ar}`, {}, {
            headers: {
                Authorization: 'Bearer ' + getItem('access')
            }
        }).then(action((res) => {
            return [true, res]
        }))
            .catch(action((err) => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                console.log("error : ", errorMsg)
                return [false, errorMsg]
            }))
    }
    @action
    transactiondata() {
        return apiClient.get('/api/v1/stripe/admin/transaction/list', {
            headers: {
                Authorization: 'Bearer ' + getItem('access')
            }
        }).then(action((res) => {
            return [true, res]
        }))
            .catch(action((err) => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                console.log("error : ", errorMsg)
                return [false, errorMsg]
            }))
    }
    @action
    updateSubscription(planID: any) {
        return apiClient.post(`/api/v1/stripe/change/subscription?price_id=${planID}`, {}, {
            headers: {
                Authorization: 'Bearer ' + getItem('access')
            }
        }).then(action((res) => {
            return [true, res]
        }))
            .catch(action((err) => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                console.log("error : ", errorMsg)
                return [false, errorMsg]
            }))
    }
    @action
    takeSubscription(planID: any) {
        return apiClient.post(`/api/v1/stripe/over/subscription?price_id=${planID}`, {}, {
            headers: {
                Authorization: 'Bearer ' + getItem('access')
            }
        }).then(action((res) => {
            return [true, res]
        }))
            .catch(action((err) => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                console.log("error : ", errorMsg)
                return [false, errorMsg]
            }))
    }
    @action
    cancleSubscription() {
        return apiClient.post(`/api/v1/super_admin/create/subscription_cancellation_request`, {}, {
            headers: {
                Authorization: 'Bearer ' + getItem('access')
            }
        }).then(action((res) => {
            return [true, res]
        }))
            .catch(action((err) => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                console.log("error : ", errorMsg)
                return [false, errorMsg]
            }))
    }

}