import React, { Fragment, useState, useEffect } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import './FloorPlans.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useHistory } from "react-router";
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import * as yup from 'yup';
import styled from 'styled-components';
import { useStore } from "../stores/useStore";
import Toaster from '../components/Toaster/toaster';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const FormErrorMsg = styled.span`
 color: red;
 float:right;
 font-size:12px;
`

toast.configure()

const FloorPlans = () => {
    const [Ttype, setTtype] = useState("");
    const [msg, setMsg] = useState("");
    const { restaurantStore } = useStore()
    const history = useHistory();
    const [dialog, setDialog] = useState(false);
    const [dialog2, setDialog2] = useState(false);
    const [data, setData] = useState([{}])

    const [errorMsg, setErrorMsg] = useState(null)

    const getFloorList =  () => {
        restaurantStore.getFloorsList()
                        .then(([success, data]) => {
                            if (success) {
                                console.log("Floors data", data.floors_list);
                               const floorsList =  data.floors_list.filter((floors: any) => floors.is_deleted === false)
                               console.log("floors list",floorsList)
                               setData(floorsList)
                            } else {
toast.error(data);                            }
                        })
            return true;
    }
    useEffect(() => {
        getFloorList();
    }, [])

    const createNewFloor = (floor: any) => {
        restaurantStore.createNewFloor(floor)
            .then(([success, message]: any) => {
                if (message.status===200) {
                    console.log("Created Floor", message)
                    toast.success("Floor Created Succesfully")
                    getFloorList();
                } else {
                    console.log("Created Floor", message)
                    // setMsg(message);
                    // setTtype("error");
                    toast.error(message)

                }
            })

    }


    const DeleteFloor = (data: any) => {
        console.log("floor delete data", data.floor_id)
        restaurantStore.deleteFloor(data.floor_id)
            .then(([success, message]: any) => {
                if (message.status===200) {
                    console.log("Deleted Floor successful", message)
                   toast.success("deleted Floor Successfully")
                    getFloorList();
                    
                } else {
                    console.log("error Deleted Floor", message)
                   toast.error(message)
                }
            })
    }

    const [updatedFloor, setUpdatedFloor] = useState();
    const [restId, setRestId] = useState();
    const [FloorId, setFloorId] = useState(0);
    const OnEditFloor = () => {
        const EditData = {
            "name": updatedFloor,
            "floor_id": FloorId
        }
        console.log("Updated floor", EditData, FloorId)
        restaurantStore.updateFloor(EditData)
            .then(([success, message]: any) => {
                if (message.status===200) {
                    console.log("EDited Floor", message)
                  toast.success("Floor edited successfully")
                    getFloorList();
                    
                } else {
                    console.log("Edit Floor", message)
                  toast.error(message)
                }
            })
    }
    const EditFloor = (data: any) => {
        setDialog2(true);
        setRestId(data.restaurant.id);
        setUpdatedFloor(data.name);
        setFloorId(data.floor_id);
        console.log(data)
    }
    const onclick = () => {
        setDialog(true);
    }

    const onHide = () => {
        setDialog(false);
        setDialog2(false);
    }

    const Goback = () => {
        history.goBack()
    }

    const Actions = (rowData: any) => {
        return <div className="fd-actions"> <div onClick={() => DeleteFloor(rowData)} className="fd-icons" style={{ color: '#ED5200', display: 'flex', cursor: 'pointer' }} ><i className="pi pi-trash" style={{ 'fontSize': '1.4em' }}></i>
        </div>
            <div className="fd-icons" onClick={() => EditFloor(rowData)} style={{ color: '#0387E8', display: 'flex', cursor: 'pointer' }} ><i className="pi pi-pencil" style={{ 'fontSize': '1.4em' }}></i>
            </div>
        </div>
    }


    return <Fragment>
        {/* <Toaster type={Ttype} text={msg} /> */}
        <div className="breadcrum">
            <h2 onClick={Goback} className="breadcrumText activeBreadcrum">FLOOR PLAN</h2 >
            <h2 className="breadcrumText"> &gt; </h2>
            <h2 className="breadcrumText">MANAGE  FLOORS</h2>
        </div>
        <hr className="hr" />

        <div className="floorPlanList">
            <h2 className=" mfHead">Manage Floors</h2 >
            <hr className="hr" />
            <br />
            <button className="calSearch" onClick={onclick}>Add New Floor </button>
            <div>
                <br />

                <div className="floorPlan">
                    <DataTable value={data} responsive sortMode="multiple" rows={10} >
                        <Column field="name" header="Name"></Column>
                        <Column body={Actions} style={{ width: '130px' }} header="Actions"  ></Column>
                    </DataTable>
                </div>
            </div>


            <Dialog visible={dialog2} position="center" style={{ width: 'auto' }} onHide={() => onHide()}>

                <div>
                    <div className="cfhead">
                        <h3 style={{ color: "#510AEA" }}>EDIT FLOOR </h3>
                        <i onClick={onHide} className="pi pi-times close"></i>
                    </div>

                    <hr className='hr' />
                    <br />
                    <Formik
                        initialValues={{ floor: "" }}
                        // validationSchema={yup.object({
                        //     floor: yup.string().min(5, "minimum 5 char required")
                        //         .required('Required')
                        // })}

                        onSubmit={(values, { setSubmitting }) => {
                            console.log(values);
                            setSubmitting(true);
                            onHide();
                            OnEditFloor();
                        }}

                    >
                        {({ errors, status, touched }) => (
                            <Form>
                                <div className="name">
                                    <label style={{ fontSize: "14px", color: "#000" }} >CHANGE FLOOR NAME</label>
                                    <ErrorMessage name='floor' component={FormErrorMsg} /><br />
                                    <Field autoComplete="off" required maxLength="20" style={{ width: '300px' }} value={updatedFloor} onChange={(e: any) => { setUpdatedFloor(e.target.value); console.log(e.target.value) }}
                                        className={(errors.floor && touched.floor ? ' is-invalid' : 'inpFld inpwd')} name='floor' as={InputText} />
                                </div>
                                <div>
                                    <button type="submit" className="calSearch" style={{ width: '100%', height: '45px', marginTop: '20px' }} >save</button>
                                </div>
                            </Form>
                        )}
                    </Formik>

                </div>
            </Dialog>


            <Dialog visible={dialog} position="center" style={{ width: 'auto' }} onHide={() => onHide()}>

                <div className="createFloor">
                    <div className="cfhead">
                        <h3>Add New Floor</h3>
                        <i onClick={onHide} className="pi pi-times close"></i>
                    </div>

                    <hr className='hr' />
                    <br />
                    <Formik
                        initialValues={{ floor: "" }}
                        validationSchema={yup.object({
                            floor: yup.string().min(5, "minimum 5 char required")
                                .required('Required')
                        })}

                        onSubmit={(values, { setSubmitting }) => {
                            console.log(values);
                            setSubmitting(true);
                            createNewFloor(values.floor);
                            onHide();
                        }}

                    >
                        {({ errors, status, touched }) => (
                            <Form>
                                <div className="name">
                                    <label >Enter Floor Name</label>
                                    <ErrorMessage name='floor' component={FormErrorMsg} /><br />
                                    <Field autoComplete="off" maxLength="20" style={{ width: '300px' }}
                                        className={(errors.floor && touched.floor ? ' is-invalid' : 'inpFld inpwd')} name='floor' as={InputText} />
                                    <br />
                                </div>
                                <div className="name">
                                    <button className="calSearch" style={{ width: '100%', height: '45px', marginTop: '20px' }} >Add Floor </button>
                                </div>
                            </Form>
                        )}
                    </Formik>

                </div>
            </Dialog>
        </div>
    </Fragment>
}

export default FloorPlans