import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from "react-router";
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import styled from 'styled-components';
import { InputMask } from 'primereact/inputmask';
import * as yup from 'yup';
import './createCoAdmin.css';
import { useStore } from "../stores/useStore";
import { Dropdown } from 'primereact/dropdown';
import Toaster from '../components/Toaster/toaster';
import Loader from '../components/Loader/loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import PhoneInput from 'react-phone-number-input'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
const FormErrorMsg = styled.span`
    color: red;
    float:right;
    font-size:12px;
   `
toast.configure()
const CreateWaiter = () => {
	const [loader, setLoader] = useState(false);


	const gender = [
		{ name: 'Female', value: 'Female' },
		{ name: 'Male', value: 'Male' },
		{ name: 'Transgender', value: 'Transgender' },
		{ name: 'Others', value: 'Others' },
	];
	const [selectedGender, setSelectedGender] = useState("");
    const[ct,setCt]=useState(true);

	const onGenderChange = (e: any) => {
		setSelectedGender(e.value);
		console.log("waiter gender", e.value)

	}
	const { restaurantStore } = useStore()
	const [Ttype, setTtype] = useState("");
	const [msg, setMsg] = useState("");
	const [errorMsg, setErrorMsg] = useState(null)

	const history = useHistory();
	const Goback = () => {
		history.goBack()
	}
	const [mobile, setMobile] = useState('');

	const createW = (values: any) => {
		setLoader(true);

		restaurantStore.createWaiter(values.FirstName, values.LastName, values.Email, mobile, selectedGender)
			.then(([success, message]: any) => {
				if (success) {
					setErrorMsg(null)
					setLoader(false);
					console.log("waiter output", message);
            		toast.success(message.status);
					setTimeout(() => {
						history.push('/manageWaiter')
					}, 4000);
					// return data

				} else {
					// setErrorMsg(message),
					setLoader(false);
					toast.error(message)
					console.log("not working", message)

				}
			})
	}

	const [otp, setOtp] = useState('');
	const [showOtp2, setShowOtp2] = useState(true);
	const [showOtp, setShowOtp] = useState(false);

	const getOtp = () => {
		if (mobile.length === 13) {
			console.log("otp mobile", mobile)
			const num = mobile.replace("+", "%2B")
			console.log("otp mobile", num)
			restaurantStore.getOtp(num)
				.then(([success, message]: any) => {
					if (success) {
						console.log("otp : ", message.data);
						toast.success(message)
					} else {
						console.log("otp : ", message)
					toast.error(message)
					}
				})
		}
	}
	const verifyOtp = (e: any) => {
		setOtp(e.target.value);
		console.log(e.target.value)
		if (e.target.value.length === 6) {
			const num = mobile.replace("+", "%2B")
			restaurantStore.verifyOtp(num, e.target.value)
				.then(([success, message]: any) => {
					if (success) {
						console.log("otp verification : ", message);
						// setTtype("success");
						// setMsg("Otp Verified");
						toast.success("Otp Verified")
						setShowOtp2(true);
						setShowOtp(false)

					} else {
						console.log("otp verification error : ", message)
						// setTtype("error");
						// setMsg(message);
						toast.error(message)
					}
				})
		}
	}

	return <Fragment>
		<Loader setLoader={setLoader} visible={loader} />
		<Toaster type={Ttype} text={msg} />
		<div className="breadcrum">
			<h2 onClick={() => history.push('/adminprofile')} className="breadcrumText activeBreadcrum">my profile</h2 >
			<h2 className="breadcrumText"> &gt; </h2>
			<h2 onClick={() => history.push('/manage-roles')} className="breadcrumText activeBreadcrum">manage roles</h2 >
			<h2 className="breadcrumText"> &gt; </h2>
			<h2 onClick={Goback} className="breadcrumText activeBreadcrum">manage waiter</h2 >
			<h2 className="breadcrumText"> &gt; </h2>
			<h2 className="breadcrumText">create waiter</h2>
		</div>
		<hr className="hr" />

		<div className="cWcont">
			<h1 className="cwHead">Create Waiter</h1>
			<hr className="hr" />

			<Formik
				initialValues={{ FirstName: '', LastName: '', Phone: '', Email: '' }}
				validationSchema={yup.object({
					FirstName: yup.string()
						.required('Required'),
					LastName: yup.string()
						.required('Required'),
					// Phone: yup.string().required('Required'),
					// Phone: yup.string().matches(regex,"Enter a valid mobile number").min(10,'Required min 10 characters')
					// 	.required('Required'),
					// uid: yup.string().required('Required'),

					// upin: yup.string().required('Required').matches(regex,"Use only numbers"),
					// cupin: yup.string().matches(regex,"Use only numbers").oneOf([yup.ref('upin'), null], 'Not a match').required('Required'),
					Email: yup.string().email('Enter a valid Email').required("Required"),
				})}
				// onSubmit={(values:any, {resetForm}) => { console.log(values); resetForm({values: ''}) }}
				onSubmit={(values) => {
					console.log(values);
					createW(values);
				}}

			>


				{({ errors, status, touched }) => (

					<Form>
						<div className='form-control-group hndl-cwinps'>
							<div className="inpcont">
								<label>First Name*</label>
								<ErrorMessage name='FirstName' component={FormErrorMsg} />
								<Field as={InputText} maxLength="15" autoComplete="off" type="text" name="FirstName"
									className={(errors.FirstName && touched.FirstName ? ' is-invalid' : 'minputtext')} />
							</div>
							<div className="inpcont">
								<label>Last Name*</label>
								<ErrorMessage name='LastName' component={FormErrorMsg} />
								<Field as={InputText} maxLength="15" autoComplete="off" type="text" name="LastName"
									className={(errors.LastName && touched.LastName ? ' is-invalid' : 'minputtext')} />
							</div>
							<div className="inpcont">
								<label>Phone*</label>
								<span id="phone"
                            style={{ display: "none",float:"right" }}
                            className="float-right">Required</span>
								<div id="pinp" className="mobileInp">
									<Field as={PhoneInput} required onlyCountries={['gb', 'in']}
								country={'gb'}  autoComplete="off" type="text" name="Phone" value={mobile} onChange={(e: any) => {
										// if ((e.target.value).length === 13) {
										// 	setShowOtp2(false);
										// 	setShowOtp(true)
										// }
										// else { setShowOtp2(true); setShowOtp(false) }

										if (e?.length <= 11) {
											let p = document.getElementById('phone')
											let pi = document.getElementById('pinp')
											p.style.color = "red"
											p.style.display = "initial"
											pi.style.border = "1px solid red"
										  }
										  else {
											let p = document.getElementById('phone')
											let pi = document.getElementById('pinp')
											p.style.display = "none"
											pi.style.border = "none"
			
										  }
	

										setMobile('+'+e)
									}}
									onBlur={(e:any) => {
										console.log("on blur phone", e.target.value)
										if (e.target.value?.length <= 3) {
											let p = document.getElementById('phone')
											let pi = document.getElementById('pinp')
											p.style.color = "red"
											p.style.display = "initial"
											pi.style.border = "1px solid red"
										}
										else {
											let p = document.getElementById('phone')
											let pi = document.getElementById('pinp')
											p.style.display = "none"
											pi.style.border = "none"
	
										}
									}}
										className={(errors.Phone && touched.Phone ? ' is-invalid' : '')} />
									{/* {!showOtp2 ?
										<div className="MblOTP2" >
											<ErrorMessage name='uid' component={FormErrorMsg} />
											<Field id="numOnly" placeholder="Enter OTP" as={InputText} min="000001" max="999999" value={otp} onChange={(e: any) => {
												verifyOtp(e);
											}} autoComplete="off" type="number" name="uid"
												className='minputtext' />

										</div>
										: null}
									<span hidden={showOtp2} onClick={getOtp} className="MblOTP">GET OTP</span> */}
								</div>
							</div>

							<div className="inpcont">
								<label>Email*</label>
								<ErrorMessage name='Email' component={FormErrorMsg} />
								<Field as={InputText} autoComplete="off" type="text" name="Email"
									className={(errors.Email && touched.Email ? ' is-invalid' : 'minputtext')} />
							</div>
							<div className="inpcont">
								<label>Gender</label>
								<ErrorMessage name='Gender' component={FormErrorMsg} />
								<br />
								{/* <Field as={InputText} maxLength="15" autoComplete="off" type="text" name="Gender" 
						className={ (errors.Gender && touched.Gender ? ' is-invalid' : 'minputtext') } /> */}

								<Dropdown  name="Gender" value={selectedGender} options={gender}  onChange={(e)=>{onGenderChange(e);
						if(gender.filter(t=> t.value === e.target.value)){setCt(false)};
					}}  optionLabel="name" placeholder="Select Gender" />
							</div>
						</div>
						<br />

						<br />
						<div style={{display:'flex',columnGap:'20px',rowGap:'20px',flexWrap:'wrap'}}>
						<Button type="button" onClick={Goback} className='outline' label="BACK" style={{ width: "150px" }} />
						<Button style={{opacity:ct?".3":"1",cursor:ct?"not-allowed":"pointer",pointerEvents:ct?"none":"initial"}} 
						//  disabled={showOtp} 
						 type="submit" className='filledSmall' label="Create Waiter" />
						</div>
						
					</Form>
				)}
			</Formik>

		</div>

	</Fragment>

}
export default CreateWaiter;