import React, { Fragment, useEffect, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import './FloorPlans.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useHistory } from "react-router";
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import * as yup from 'yup';
import styled from 'styled-components';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import { useStore } from '../stores/useStore';
import Toaster from '../components/Toaster/toaster';
import { InputMask } from 'primereact/inputmask';
import { ToastContainer, toast } from 'react-toastify';
import { getItem } from './storage';
import { Checkbox } from 'primereact/checkbox';

const FormErrorMsg = styled.span`
 color: red;
 float:right;
 font-size:12px;
`

toast.configure()

const FloorPlans = () => {

    const [Ttype, setTtype] = useState("");
    const [msg, setMsg] = useState("");
    const history = useHistory();
    const { restaurantStore } = useStore();
    const [dialog, setDialog] = useState(false);
    const [dialog2, setDialog2] = useState(false);
    const [shiftid, setShiftid] = useState();
    console.log("shift id", shiftid);

    const onclick = () => {
        setDialog(true);
    }
    const onHide = () => {
        setDialog(false);
        setDialog2(false);
        setSelectedDays([])
    }
    const Goback = () => {
        history.goBack()
    }

    //     const getfromtime = (rowData: any) => {
    //         return moment( rowData['start_time']).local().format("HH:mm a")

    //     }

    //     const gettotime = (rowData: any) => {
    // return moment( rowData['end_time']).local().format("HH:mm a")
    //     }


    const Actions = (rowData: any) => {
        return <div className="fd-actions">
            <div className="fd-icons" onClick={() => DeleteShift(rowData)} style={{ color: '#ED5200', display: 'flex', cursor: 'pointer' }} ><i className="pi pi-trash" style={{ 'fontSize': '1.4em' }}></i>
            </div>
            <div className="fd-icons" onClick={() => EditShift(rowData)} style={{ color: '#0387E8', display: 'flex', cursor: 'pointer' }} ><i className="pi pi-pencil" style={{ 'fontSize': '1.4em' }}></i>
            </div>
        </div>
    }
    const getfromtime = (rowData: any) => {
        console.log("rowdata starttime", rowData.start_time)

        return moment(rowData.start_time, "HH:mm:ss").local().format('hh:mm a')
        // console.log("rowdata start time",moment.utc(rowData.start_time).local().format('hh:mm a'))

    }
    const gettotime = (rowData: any) => {
        console.log("rowdata endtime", rowData.end_time)
        return moment(rowData.end_time, "HH:mm:ss").local().format('hh:mm a')
    }

    const [efrom, setEFrom] = useState("");
    const [eto, setETo] = useState("");
    const [shiftEdit, setShiftEdit] = useState("");
    const EditShift = (data: any) => {
        setDialog2(true);
        setShiftEdit(data.name);
        setShiftid(data.id);
        setEFrom(moment(data.start_time, "hh:mm:ss").local().format('hh:mm a'));
        setETo(moment(data.end_time, "hh:mm:ss").local().format('hh:mm a'));
        if (data.days_applicable) {
            const weekMap = data.days_applicable.map((days: any) => { return { 'key': days, 'name': days } })
            setSelectedDays(weekMap)
        }
        else {
            setSelectedDays([])

        }

    }
    const [minTime, setMin] = useState(0);

    const restSettings = () => {
        restaurantStore.getRestaurantSettings(getItem('RestaurantId'))
            .then(([success, message]: any) => {
                if (success) {
                    setMin(message?.restaurant_settings.booking_period)
                } else {
                    console.log("error: ", message)
                }

            })
    }
    useEffect(() => {
        restSettings();
    }, [])
    const ShiftData = (floor: any, selectedDays: any) => {
        // var fromtt = moment(from).format('hh:mm a');
        // var tott = moment(to).format('hh:mm a');
        // var shift: any = [{
        //     shiftName: shiftN,
        //     fromtime: fromtt,
        //     toTime: tott
        // }]

        const shiftAvailability = selectedDays.map((days: any) => days.name)

        restaurantStore.createShifts(floor.floor, moment(floor.From, 'hh:mm a').format('HH:mm:ss'), moment(floor.To, 'hh:mm a').format('HH:mm:ss'), shiftAvailability)
            .then(([success, message]: any) => {
                if (success) {
                    // setMsg("Shift created successfully");
                    // setTtype("success");
                    toast.success("Shift Created Successfully")
                    console.log("Shift created successfully", message)
                    setVal1("");
                    setVal2("");
                    setSelectedDays([]);
                    ShiftsData();
                } else {
                    // setMsg(message);
                    // setTtype("error");
                    toast.error(message)
                    console.log(message)
                }
                // setTimeout(() => {
                //     setMsg("");
                //     setTtype("");
                // }, 4000);
            })
    }
    const EditShiftData = (selectedDays: any) => {
        // var fromtt = moment(values.efrom).format('hh:mm a');
        // var tott = moment(values.eto).format('hh:mm a');
        // var shift: any = [{
        //     shiftName: shiftEdit,
        //     fromtime: fromtt,
        //     toTime: tott
        // }]
        const shiftAvailability = selectedDays.map((days: any) => days.name)


        restaurantStore.updateShifts(shiftid, shiftEdit, moment(efrom, "hh:mm a").format('HH:mm:ss'), moment(eto, "hh:mm a").format('HH:mm:ss'), shiftAvailability)
            .then(([success, message]: any) => {
                if (success) {
                    console.log("Shift Updated successfully ", message)
                    console.log("Shift id", shiftid)
                    // setMsg("Shift Edited successfully");
                    // setTtype("success");
                    toast.success("Shift Updated Successfully")
                    ShiftsData();
                } else {
                    // setMsg(message);
                    // setTtype("error");
                    toast.error(message)
                    console.log("update shift", message)
                }
            })
    }
    const [shiftList, setShiftList] = useState([{}])
    const ShiftsData = async () => {
        await restaurantStore.getShifts()
            .then(([success, message]: any) => {
                if (success) {
                    // setShiftList(message);
                    const shifts = message.filter((shift: any) => shift.is_deleted === false)
                    setShiftList(shifts)
                    console.log("Shift List", shifts)

                } else {
                    console.log(message.msg);
                }
            })
    }
    useEffect(() => {
        ShiftsData();
    }, []);

    const DeleteShift = (data: any) => {
        restaurantStore.deleteShift(data.id)
            .then(([success, message]: any) => {
                if (success) {
                    console.log("Shift Deleted successfully ", message)
                    console.log("Shift id", shiftid)
                    // setMsg("Shift Deleted successfully");
                    // setTtype("success");
                    toast.success("Shift Deleted Successfully")
                    ShiftsData();
                } else {
                    console.log("shift delete error", message)
                    // setMsg(message);
                    // setTtype("error");
                    toast.error(message)
                }
            })
    }
    const [val1, setVal1] = useState("");
    const [val2, setVal2] = useState("");

    const weekData = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
    const weekMap = weekData.map((days) => { return { 'key': days, 'name': days } })

    const [selectedDays, setSelectedDays] = useState<any>([]);

    const onDayChange = (e: any) => {
        let _selectedDays = [...selectedDays];

        if (e.checked) {
            _selectedDays.push(e.value);
        }
        else {
            for (let i = 0; i < _selectedDays.length; i++) {
                const selectedCategory = _selectedDays[i];

                if (selectedCategory.key === e.value.key) {
                    _selectedDays.splice(i, 1);
                    break;
                }
            }
        }

        setSelectedDays(_selectedDays);
    }


    return <Fragment>
        <Toaster type={Ttype} text={msg} />
        <div className="breadcrum">
            <h2 onClick={() => history.push('/settings')} className="breadcrumText activeBreadcrum">SETTINGS</h2 >
            <h2 className="breadcrumText"> &gt; </h2>
            <h2 onClick={Goback} className="breadcrumText activeBreadcrum">TIME &amp; AVAILABILITY</h2>
            <h2 className="breadcrumText"> &gt; </h2>
            <h2 className="breadcrumText">MANAGE SHIFTS</h2>
        </div>
        <hr className="hr" />
        <div className="floorPlanList">
            <h2 className=" mfHead">Manage Shifts</h2 >
            <hr className="hr" />
            <br />
            {(shiftList).length < 4 ?
                <button className="calSearch" onClick={onclick}><i style={{ fontSize: "16px" }} className="fas fa-plus-circle">&nbsp;</i> Create New Shift</button> : null}
            <div>
                <br />
                <div className="floorPlan">
                    <DataTable responsive value={shiftList} sortMode="multiple" paginator rows={10} >
                        <Column field="name" style={{ width: "120px", textTransform: "uppercase" }} header="Shift"></Column>
                        <Column body={getfromtime} style={{ width: "120px", textTransform: "uppercase" }} header="From Time"></Column>
                        <Column body={gettotime} style={{ width: "120px", textTransform: "uppercase" }} header="To Time"></Column>
                        <Column body={Actions} style={{ width: '130px', textTransform: "uppercase" }} header="Actions"></Column>
                    </DataTable>
                </div>
            </div>
            {/* Edit Shift */}
            <Dialog visible={dialog2} position="center" style={{ width: 'auto' }} onHide={() => onHide()}>
                <div>
                    <div className="cfhead">
                        <h3 style={{ color: "#510AEA" }}>EDIT SHIFT </h3>
                        <i onClick={onHide} className="pi pi-times close"></i>
                    </div>

                    <hr className='hr' />
                    <br />

                    <Formik
                        initialValues={{ shift: "", From: "", To: "" }}
                        // validationSchema={yup.object({
                        //     shift: yup.string().min(5, "minimum 5 char required")
                        //         .required('Required'),
                        //     From: yup.string().required("Required"),
                        //     To:yup.string().required('Required').test("is-greater", "is less then from time", function (value) {
                        //         const { From } = this.parent;
                        //         return moment(value, "hh:mm a").isSameOrAfter(moment(From, "hh:mm a"));
                        //     }),
                        // })}
                        onSubmit={(values) => {
                            EditShiftData(selectedDays);
                            onHide();
                        }}
                    >
                        {({ errors, status, touched }) => (
                            <Form>
                                <div className="name">
                                    <label style={{ fontSize: "14px", color: "#000" }} >CHANGE SHIFT NAME</label>
                                    <ErrorMessage name='shift' component={FormErrorMsg} /><br />
                                    <Field autoComplete="off" required maxLength="20" style={{ width: '300px' }} value={shiftEdit} onChange={(e: any) => setShiftEdit(e.target.value)}
                                        className={(errors.shift && touched.shift ? ' is-invalid' : 'inpFld')} name='shift' as={InputText} />
                                </div>
                                <br />

                                <div style={{ display: "flex", columnGap: "20px", rowGap: "20px", flexWrap: "wrap" }}>
                                    <div>
                                        <label htmlFor="basic">From</label><ErrorMessage name='From' component={FormErrorMsg} /><br /><br />
                                        <Field as={InputMask} style={{ textTransform: "uppercase" }} required autoComplete="off" value={efrom} onChange={(e: any) => setEFrom(e.target.value)} name="From" className={(errors.From && touched.From ? ' is-invalid' : ' ')} id="From" mask="99:99 aa" placeholder="ex: 01:00 AM" />
                                    </div>
                                    <div>
                                        <label htmlFor="basic">To</label><ErrorMessage name='To' component={FormErrorMsg} /><br /><br />
                                        <Field as={InputMask} style={{ textTransform: "uppercase" }} required autoComplete="off" value={eto} onChange={(e: any) => setETo(e.target.value)} name="To" className={(errors.To && touched.To ? ' is-invalid' : ' ')} id="To" mask="99:99 aa" placeholder="ex: 04:00 PM" />
                                    </div>
                                </div>
                                <br />
                                <label >Days Applicable</label>
                                <div style={{ display: "flex", padding: '20px 0', columnGap: "20px", rowGap: "20px", flexWrap: "wrap" }}>
                                    {
                                        weekMap.map((days) => {
                                            return (
                                                <div key={days.key} className="field-checkbox">
                                                    <Checkbox inputId={days.key} name="category" value={days} onChange={onDayChange} checked={selectedDays.some((item: any) => item.key === days.key)} />
                                                    <label htmlFor={days.key}>&nbsp;&nbsp;{days.name.slice(0, 3)}</label>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                                <div className="name">
                                    <button type="submit" className="calSearch" style={{ width: '100%', height: '45px', marginTop: '20px' }} >Save</button>
                                </div>

                            </Form>
                        )}
                    </Formik>
                </div>
            </Dialog>

            {/* Create New shift */}
            <Dialog visible={dialog} position="center" style={{ width: 'auto' }} onHide={() => onHide()}>
                <div className="createFloor">
                    <div className="cfhead">
                        <h3>Create New Shift</h3>
                        <i onClick={onHide} className="pi pi-times close"></i>
                    </div>

                    <hr className='hr' />
                    <br />

                    <Formik
                        initialValues={{ floor: "", From: "", To: "" }}
                        validationSchema={yup.object({
                            floor: yup.string().min(5, "minimum 5 char required")
                                .required('Required'),
                            From: yup.string().required("Required"),
                            To: yup.string().required('Required').test("is-greater", `< default booking time`,
                                function (value) {
                                    if (value) {
                                        const { From } = this.parent;
                                        let ft = moment(From, "hh:mm a");
                                        let tt = moment(value, "hh:mm a");
                                        let c = tt.diff(ft, 'minutes');
                                        console.log("time diff : ", c, minTime)
                                        if (c >= minTime) {
                                            return true
                                        }
                                        else return false
                                    }
                                    return false
                                }),

                        })}
                        onSubmit={(values) => {
                            // console.log(values);
                            ShiftData(values, selectedDays);
                            onHide();
                            console.log("shift data : ", values)
                        }}
                    >
                        {({ errors, status, touched }) => (
                            <Form>
                                <div className="name">
                                    <label >Enter Shift Name</label>
                                    <ErrorMessage name='floor' component={FormErrorMsg} /><br />
                                    <Field autoComplete="off" maxLength="20"
                                        className={(errors.floor && touched.floor ? ' is-invalid' : 'inpFld')} name='floor' as={InputText} />
                                    <br />
                                </div>
                                <br />
                                <div className="name">
                                    {/* <div style={{ display: "flex", columnGap: "20px" }}>
                                        <div>
                                            <label htmlFor="time12">From <ErrorMessage name='from' component={FormErrorMsg} /></label><br />
                                            <Calendar id="time12" name="from" inline value={from} onChange={(e: any) => setFrom(e.value)} timeOnly hourFormat="12" />
                                        </div>
                                        <div>
                                            <label htmlFor="time12">To <ErrorMessage name='to' component={FormErrorMsg} /></label><br />
                                            <Calendar id="time12" name="to" inline value={to} onChange={(e: any) => setTo(e.value)} timeOnly hourFormat="12" />
                                        </div>
                                    </div> */}
                                    <div style={{ display: "flex", columnGap: "20px", rowGap: "20px", flexWrap: "wrap" }}>
                                        <div>
                                            <label htmlFor="basic">From</label><ErrorMessage name='From' component={FormErrorMsg} /><br /><br />
                                            <Field as={InputMask} style={{ textTransform: "uppercase" }} autoComplete="off" name="From" className={(errors.From && touched.From ? ' is-invalid' : ' ')} id="From" mask="99:99 aa" placeholder="ex: 01:00 AM" />
                                        </div>
                                        <div>
                                            <label htmlFor="basic">To</label><ErrorMessage name='To' component={FormErrorMsg} /><br /><br />
                                            <Field as={InputMask} style={{ textTransform: "uppercase" }} autoComplete="off" name="To" className={(errors.To && touched.To ? ' is-invalid' : ' ')} id="To" mask="99:99 aa" placeholder="ex: 04:00 PM" />
                                        </div>
                                    </div>
                                    <br />
                                    <label >Days Applicable</label>
                                    <div style={{ display: "flex", padding: '20px 0', columnGap: "20px", rowGap: "20px", flexWrap: "wrap" }}>
                                        {
                                            weekMap.map((days) => {
                                                return (
                                                    <div key={days.key} className="field-checkbox">
                                                        <Checkbox inputId={days.key} name="category" value={days} onChange={onDayChange} checked={selectedDays.some((item: any) => item.key === days.key)} />
                                                        <label htmlFor={days.key}>&nbsp;&nbsp;{days.name.slice(0, 3)}</label>
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>
                                </div>
                                <div className="name">
                                    <button type="submit" className="calSearch" style={{ width: '100%', height: '45px', marginTop: '20px' }} >Save</button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Dialog>
        </div>
    </Fragment>
}

export default FloorPlans