import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from "react-router";
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import styled from 'styled-components';
import { InputMask } from 'primereact/inputmask';
import { Dialog } from 'primereact/dialog';
import * as yup from 'yup';
import "./manageRoleSettings.css";
import './manageWaiter.css';
import Toaster from '../components/Toaster/toaster';
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useStore } from '../stores/useStore';
const FormErrorMsg = styled.span`
    color: red;
    float:right;
    font-size:12px;
   `
toast.configure()
const ManageWaiter = () => {
	// const regex = /^\(?([1-9]{1})?([0-9]{9})$/;
	const history = useHistory();
	const [bi, setBI] = useState(true);
	const [Ttype, setTtype] = useState("");
	const [msg, setMsg] = useState("");
	const [dialog, setDialog] = useState(false)
	const { userSessionStore, restaurantStore } = useStore();

	const Showdeactivate = () => {
		setDialog(true);
	}

	const onHide = () => {
		setDialog(false);
	}
	const Goback = () => {
		history.goBack()
	}
	const Deactivate = (waiterId: any) => {
		console.log("User Deactivated", waiterId);
		restaurantStore.deactivateUser(waiterId)
			.then(([success, message]: any) => {
				if (success) {
					console.log("User Deactivated", message);
					// setTtype("success");
					// setMsg("Waiter Deactivated");
					toast.success("Waiter Deactivated");
					onHide();
					window.history.go(0);
				} else {
					// setTtype("error");
					// setMsg(message.msg);
					toast.error(message)
					console.log("not working ", message)
				}
			})

	}
	const Activate = (waiterId: any) => {
		console.log("User Activated", waiterId);
		restaurantStore.activateUser(waiterId)
			.then(([success, message]: any) => {
				if (success) {
					console.log("User Activated", message);
					// setTtype("success");
					// setMsg("Waiter Activated");
					toast.success("Waiter Activated")
					onHide();
					window.history.go(0);
				} else {
					// setTtype("error");
					// setMsg(message.msg);
					toast.error(message)
					console.log("not working ", message)
				}
			})

	}
	const [waiterList, setWaiterList] = useState([]);
	const waiters = async () => {
		userSessionStore.waiterList()
			.then(([success, message]: any) => {
				if (success) {
					console.log("waiterlist output", message.data);
					setWaiterList(message.data);
				} else {
					console.log("not working", message)
				}
			})
	}

	const [Waiter, setWaiter] = useState("");
	const searchWaiter = () => {
		let data = waiterList.filter((name: any) => {
			if (Waiter !== "") {
				let f = (name.first_name).toLowerCase()
				let l = (name.last_name).toLowerCase()
				let fullN = f + l
				let check = Waiter.toLowerCase()
				let res: any = fullN.includes(check) ? name.email : "No match Found";
				return res === name.email
			}
			else {
				console.log(waiterList)
				return waiterList
			}

		})
		setWaiterList(data);
	}
	useEffect(() => {
		waiters();
		searchWaiter();
	}, [])

	const [showWaiter, setShowWaiter] = useState(true);
	const [fName, setFname] = useState("");
	const [lName, setLname] = useState("");
	const [email, setEmail] = useState("");
	const [mobile, setMobile] = useState("");
	const [mobile2, setMobile2] = useState("");
	const [gender, setGender] = useState("");
	const [waiterId, setWaiterId] = useState("");
	const [waiterID, setWaiterID] = useState("");
	const [otp, setOtp] = useState('');
	const [showOtp, setShowOtp] = useState(true);
	const [showOtp2, setShowOtp2] = useState(true);
	const [isActive, setIsActive] = useState();
	const [waiterRes, setWaiterData] = useState();

	const selectedWaiter = (user: any) => {
		setShowOtp(true);
		setOtp("");
		setShowOtp2(true);
		setIsActive(user.is_active);
		restaurantStore.getWaiter(user.id)
			.then(([success, user]: any) => {
				if (success) {
					console.log("waiter data", user);
					setWaiterData(user);
					setFname(user.first_name);
					setLname(user.last_name);
					setEmail(user.email);
					setMobile(user.mobile);
					setMobile2(user.mobile);
					setGender(user.gender);
					setWaiterId(user.user_id);
					setWaiterID(user.id);
					setShowWaiter(false);
				} else {
					console.log("not working", user)
				}
			})
	}

	const onCancel = () => {
		setBI(true);
		setShowOtp(true);
		setFname(waiterRes?.first_name);
		setLname(waiterRes?.last_name);
		setEmail(waiterRes?.email);
		setMobile(waiterRes?.mobile);
	}
	const getOtp = () => {
		if (mobile.length === 13) {
			console.log("otp mobile", mobile)
			const num = mobile.replace("+", "%2B")
			console.log("otp mobile", num)
			restaurantStore.getOtp(num)
				.then(([success, message]: any) => {
					if (success) {
						console.log("otp : ", message.data);
						// setTtype("success");
						// setMsg(message);
						toast.success(message);
					} else {
						console.log("otp : ", message)
						// setTtype("error");
						// setMsg(message.msg);
						toast.error(message)

					}
				})
		}
	}
	const verifyOtp = (e: any) => {
		setOtp(e.target.value);
		console.log(e.target.value)
		if (e.target.value.length === 6) {
			const num = mobile.replace("+", "%2B")
			restaurantStore.verifyOtp(num, e.target.value)
				.then(([success, message]: any) => {
					if (success) {
						console.log("otp verification : ", message);
						// setTtype("success");
						// setMsg(message);
						toast.success(message)
						setShowOtp2(true);

					} else {
						console.log("otp verification error : ", message)
						// setTtype("error");
						// setMsg(message.msg);
						toast.error(message.msg)
					}
				})
		}
	}
	const saveChanges = () => {
		const data = {
			"first_name": fName,
			"last_name": lName,
			"email": email,
			"mobile": mobile,
			"gender": gender,
			"waiter_id": waiterID
			// "updated_by": getItem('role-id'),
			// "otp": otp
		}
		console.log("waiter update data : ", data)
		restaurantStore.updateWaiter(data)
			.then(([success, message]: any) => {
				if (success) {
					console.log("updated waiter : ", message);
					// setTtype("success");
					// setMsg("Waiter updated successfully");
					toast.success("Waiter updated successfully")
					setOtp("");
					waiters();
				} else {
					console.log("updated waiter : ", message)
					// setTtype("error");
					// setMsg(message);
					toast.error(message)
					waiters();
				}
			})
	}

	return <Fragment>
		<Toaster type={Ttype} text={msg} />
		<div id="content" className="position-relative ">

			{/* <div className="page-section text-primary text-uppercase">
			<span className="text-black">my profile</span> <i className="fas fa-chevron-right text-black"></i> <span className="text-black">manage roles</span> <i className="fas fa-chevron-right"></i> manage co-admin
		</div> */}
			<div className="breadcrum">
				<h2 onClick={() => history.push('/adminprofile')} className="breadcrumText activeBreadcrum">my profile</h2 >
				<h2 className="breadcrumText"> &gt; </h2>
				<h2 onClick={Goback} className="breadcrumText activeBreadcrum">manage roles</h2 >
				<h2 className="breadcrumText"> &gt; </h2>
				<h2 className="breadcrumText">manage waiter</h2>
			</div>
			<hr className="hr" />
			<div className="profile-wrap d-flex flex-wrap managew align-items-stretch">
				<div className="employee-list-profile">
					<form onSubmit={(e) => { e.preventDefault(); searchWaiter() }}>
						<Link to="/createWaiter"><i className="btn d-block text-uppercase text-white primary-btn transition"><i className="fas fa-plus-circle "></i>Create Waiter</i></Link>
						<label htmlFor="" className="position-relative">
							<input id="search" type="search" name="search-employee" autoComplete="off" value={Waiter} onChange={(e: any) => { setWaiter(e.target.value); waiters(); }} placeholder="Search Waiter" className="form-control" />
							<i id="sbtn" onClick={searchWaiter} style={{ cursor: "pointer" }} className="fas fa-search fa-lg text-gray  searchIc"></i>
						</label>
					</form>

					<div className="employee-list custom-scrollbar bg-white" style={{ overflowY: 'scroll' }}>
						<ul>
							{waiterList.map(
								(users: any) => <li onClick={() => { selectedWaiter(users); setBI(true) }} key={users.id} id={users.id} className="Waiterlist ">{users.first_name} {users.last_name}<span style={{ float: "right", marginRight: "10px" }}>{!users.is_active ? <i className="pi pi-ban red"></i> : <i className="pi pi-check-circle green"></i>}</span></li>
							)
							}
						</ul>
					</div>
				</div>

				{showWaiter ? <div className="showWaiter">
					<div>
						<p>Please select <b>waiter</b> to see their details.</p>
						<img src="assets/images/select-user.svg" alt="" />
					</div>
				</div>
					:
					<div className="profile-info">
						<div className="user-info-list">
							<div className="user-name text-black d-flex justify-content-between"><span>{fName + " " + lName}</span> {isActive ? <h6 onClick={Showdeactivate} className="ada red rhove">Deactivate</h6> : <h6 onClick={() => Activate(waiterId)} className="ada green ghove">Activate</h6>}</div>
							<Dialog visible={dialog} style={{ width: '358px' }} onHide={() => onHide()}>
								<h3 className="logoutTtl">Are you sure, you want to Deactivate</h3>
								<br />
								<span><button style={{ width: "70px", marginRight: "10px" }} onClick={onHide} className="outline">No</button></span>

								<span><button autoFocus style={{ width: "70px", height: "35px" }} onClick={() => Deactivate(waiterId)} className="filled">Yes</button></span>
								<br />

							</Dialog>
							<div className="designation text-gray">Waiter</div>

						</div>

						<div className="basic-info">
							<div className="section-title text-capitalize">
								<span className="text-black">basic information &nbsp;&nbsp;</span>
								<span onClick={() => setBI(false)} className="price-edit"><img className="price-editIcon" src="./assets/images/edit2.png" alt="" />&nbsp;EDIT</span>
							</div>
							<Formik
								initialValues={{ FirstName: "", LastName: "", Email: "", Mobile: "", Otp: "" }}
								// validationSchema={yup.object({
								// 	FirstName: yup.string()
								// 		.required('Required'),
								// 	LastName: yup.string()
								// 		.required('Required'),
								// 	Email: yup.string().email()
								// 		.required('Required'),
								// 	Mobile: yup.string()
								// 		.min(10, 'Required min 10 characters')
								// 		.required('Required')
								// 	// .matches(regex,"Enter a valid mobile number")
								// })}
								onSubmit={(values) => {
									setBI(true);
									setShowOtp(true);
									saveChanges();
								}}
							>{({ errors, status, touched }) => (<Form>

								<div className='form-control-group hndl-inps'>
									<div className="div">
										<label>First Name</label>
										<ErrorMessage name='FirstName' component={FormErrorMsg} />

										<Field as={InputText} minLength={3} maxLength={15} required value={fName} onChange={(e: any) => setFname(e.target.value)} autoComplete="off" className={(errors.FirstName && touched.FirstName ? ' is-invalid' : 'minputtext')} type="text" name="FirstName" readOnly={bi} />
									</div>
									<div className="div">
										<label>Last Name</label>
										<ErrorMessage name='LastName' component={FormErrorMsg} />
										<Field required as={InputText} minLength={3} maxLength={15} value={lName} onChange={(e: any) => setLname(e.target.value)} className={(errors.LastName && touched.LastName ? ' is-invalid' : 'minputtext')} autoComplete="off" type="text" name="LastName" readOnly={bi} />
									</div>
									<div className="div">
										<label>Email</label>
										<ErrorMessage name='Email' component={FormErrorMsg} />
										<Field required as={InputText} type="email" value={email} onChange={(e: any) => setEmail(e.target.value)} autoComplete="off" name="Email" readOnly={bi}
											className={(errors.Email && touched.Email ? ' is-invalid' : 'minputtext')} />
									</div>
									<div className="div">
										<label>Mobile</label>
										<span id="phone"
											style={{ display: "none", float: "right", fontSize: "14px" }}
											className="float-right">Required</span>
										<div id="pinp" className="mobileInp">
											<Field as={PhoneInput} value={mobile} onChange={(e: any) => {
												if (mobile2 !== e) {
													setShowOtp(false);
													setShowOtp2(false);
												}
												else { setShowOtp(true); setShowOtp2(true); }

												if (e?.length <= 11) {
													let p = document.getElementById('phone')
													let pi = document.getElementById('pinp')
													p.style.color = "red"
													p.style.display = "initial"
													pi.style.border = "1px solid red"
												}
												else {
													let p = document.getElementById('phone')
													let pi = document.getElementById('pinp')
													p.style.display = "none"
													pi.style.border = "none"

												}

												setMobile('+' + e)
											}}
												onBlur={(e: any) => {
													console.log("on blur phone", e.target.value)
													if (e.target.value?.length <= 3) {
														let p = document.getElementById('phone')
														let pi = document.getElementById('pinp')
														p.style.color = "red"
														p.style.display = "initial"
														pi.style.border = "1px solid red"
													}
													else {
														let p = document.getElementById('phone')
														let pi = document.getElementById('pinp')
														p.style.display = "none"
														pi.style.border = "none"

													}
												}}
												type="tel" required onlyCountries={['gb', 'in']}
												country={'gb'} autoComplete="off" name="Mobile" disabled={bi}
												className={(errors.Mobile && touched.Mobile ? ' is-invalid' : '')} />
											{/* <span hidden={showOtp} onClick={getOtp} className="MblOTP">GET OTP</span> */}
										</div>
									</div>
									{/* {!showOtp?
									<div className="p-input">
										<label>OTP</label>
										<ErrorMessage name='Otp' component={FormErrorMsg} />
										<Field as={InputText} min="000001" max="999999" value={otp} onChange={(e: any) => {
											verifyOtp(e);
										}} autoComplete="off" type="number" name="Otp"
										//  disabled={showOtp2}
										 className={(errors.Otp && touched.Otp ? ' is-invalid' : 'minputtext')} />
									</div>:null} */}
								</div>
								<Button type="submit"
									//  disabled={showOtp2 !== true}	
									style={{ display: bi === true ? 'none' : 'initial', marginTop: "10px", marginLeft: "10px" }} className='filledSmall' label="save" />
								<Button type="button" onClick={onCancel} style={{ display: bi === true ? 'none' : 'initial', marginTop: "10px", marginLeft: "10px" }} className='outlineSmall' label="cancel" />
							</Form>
							)}
							</Formik>
							<div style={{ display: isActive ? "none" : "block" }} className="deactivated">
								<p className="deactivate-stamp">Deactivated</p>
							</div>
						</div>

					</div>}
			</div>
		</div>
	</Fragment>
}

export default ManageWaiter