import { Formik, Form, ErrorMessage, Field } from 'formik';
import { InputText } from 'primereact/inputtext';
import React, { useState } from 'react'
import styled from 'styled-components';
import PhoneInput from 'react-phone-input-2';
import { useHistory } from "react-router";
import * as yup from 'yup';
import './AddNewRestaurant.css'
import { Button } from 'primereact/button';
import { useStore } from '../stores/useStore';
import { getItem } from './storage';
import { toast } from 'react-toastify';

const FormErrorMsg = styled.span`
    color: red;
    float:right;
    font-size:12px;
   `
toast.configure();

const AddNewRestaurant = () => {
    const orgID = getItem('org-id')
    const { restaurantStore } = useStore();
    const [mobile, setMobile] = useState('');
    const [uploadedImage, setUploadedImage] = useState('');
    const [showWarning, setShowWarning] = useState('');
    const [imagePath, setImagePath] = useState("");
    const [uploadingImage, setUploadingImage] = useState('');

    const history = useHistory();
    const Goback = () => {
        history.goBack()
    }

    const showLogo = (img: any) => {
        console.log("image responce while uploading : ", img.target.files[0])

        var formdata = new FormData()
        formdata.append('logo_url', img.target.files[0])

        var maxSize = 200 * 1024; // 200 is size in kb * 1024 gives size in bytes

        if (img.target.files.length > 0 && img.target.files[0].size <= maxSize) {
            let image = new Image();
            let url = (URL.createObjectURL(img.target.files[0]));

            image.onload = () => {
                console.log(image.naturalWidth, image.naturalHeight)
                if (image.naturalWidth <= 200 && image.naturalHeight <= 200) {
                    console.log(formdata)
                    restaurantStore.uploadImg(formdata)
                        .then(([success, data]: any) => {
                            if (success) {
                                setImagePath(data);
                                console.log("success image upload", data)
                            } else {
                                console.log("Image upload error responce : ", data)
                            }
                        })
                    setUploadedImage(url);
                    setUploadingImage('uploadimg')
                    setShowWarning('');
                }

                else {
                    setUploadedImage("");
                    setUploadingImage("");
                    setShowWarning('showerr');
                    console.log("please upload an image of 200x200")
                }
            };
            image.src = (URL.createObjectURL(img.target.files[0]));
        }
        else {
            setUploadedImage("");
            setUploadingImage("");
            setShowWarning('showerr');
            console.log("please upload an image size less then or equal to 200kb")
        }
    }


    const AddRest = (props: any) => {
        const restaurantDetails = {
            logo_url: imagePath,
            name: props.RestaurantName,
            email: props.Email,
            mobile: mobile,
            org_id: orgID,
            address: {
                address1: props.Address1,
                address2: props.Address2,
                land_mark: props.landMark,
                zip_code: props.ZipCode,
                latitude: "",
                longitude: "",
                city: props.city,
                state: props.state,
                country: props.country,
            }
        }
        restaurantStore.addBranch(restaurantDetails)
            .then(([success, data]: any) => {
                if (success) {
                    setImagePath('');
                    toast.success('Branch Added Successfully');
                    setTimeout(() => {
                        Goback();
                    }, 500);
                }
                else {
                    toast.error(data);

                }
            })


        console.log('restaurant details :', restaurantDetails)
    }
    return <>
        <div className="breadcrum">
            <h2 onClick={Goback} className="breadcrumText activeBreadcrum">MY PROFILE</h2 >
            <h2 className="breadcrumText"> &gt; </h2>
            <h2 className="breadcrumText">ADD NEW BRANCH</h2>
        </div>
        <hr className="hr" />
        <div className='formHolder'>
            <Formik
                initialValues={{ RestaurantName: '', Address1: '', Address2: '', Email: '', Phone: '', landMark: '', ZipCode: '', city: '', state: '', country: '' }}
                validationSchema={yup.object({
                    RestaurantName: yup.string()
                        .required('Required'),
                    Address1: yup.string()
                        .required('Required'),
                    Address2: yup.string()
                        .required('Required'),
                    Email: yup.string().email('Enter a valid Email').required("Required"),
                    landMark: yup.string()
                        .required('Required'),
                    ZipCode: yup.string()
                        .required('Required'),
                    city: yup.string()
                        .required('Required'),
                    state: yup.string()
                        .required('Required'),
                    country: yup.string()
                        .required('Required'),
                    // Phone:yup.string().required('Required'),

                    // Phone: yup.string().matches(regex,"Enter a valid mobile number").min(10,'Required min 10 characters')
                    // 	.required('Required'),

                })}
                // onSubmit={(values:any, {resetForm}) => { console.log(values); resetForm({values: ''}) }}
                onSubmit={(values,) => {
                    AddRest(values);
                }}

            >{({ errors, status, touched }) => (<Form>
                <div className='form-control-group hndl-cwinps'>
                    <div className="inpcont">
                        <label>Branch Logo</label>
                        <div id="uploadFiles" className="newImg">
                            <input type="file" className="newlogo" accept="image/png" name="image" id="file" onChange={(e: any) => showLogo(e)} ></input>
                            <img style={{ display: 'none' }} className={uploadingImage} src={uploadedImage} alt=" " />
                        </div>
                        <p style={{ display: 'none', margin: '5px 0 10px' }} className={showWarning} > * please upload an image of 200 x 200 with size &lt;= 200KB* </p>
                    </div>
                    <div className='inpcont'></div>
                    <div className="inpcont">
                        <label>Branch Name*</label>
                        <ErrorMessage name='RestaurantName' component={FormErrorMsg} />
                        <Field as={InputText} maxLength="50" autoComplete="off" type="text" name="RestaurantName"
                            className={(errors.RestaurantName && touched.RestaurantName ? ' is-invalid' : 'minputtext')} />
                    </div>
                    <div className="inpcont">
                        <label>Branch Email*</label>
                        <ErrorMessage name='Email' component={FormErrorMsg} />
                        <Field as={InputText} autoComplete="off" type="text" name="Email"
                            className={(errors.Email && touched.Email ? ' is-invalid' : 'minputtext')} />
                    </div>
                    <div className="inpcont">
                        <label>Address 1*</label>
                        <ErrorMessage name='Address1' component={FormErrorMsg} />
                        <Field as={InputText} maxLength="100" autoComplete="off" type="text" name="Address1"
                            className={(errors.Address1 && touched.Address1 ? ' is-invalid' : 'minputtext')} />
                    </div>
                    <div className="inpcont">
                        <label>Address 2*</label>
                        <ErrorMessage name='Address2' component={FormErrorMsg} />
                        <Field as={InputText} maxLength="100" autoComplete="off" type="text" name="Address2"
                            className={(errors.Address2 && touched.Address2 ? ' is-invalid' : 'minputtext')} />
                    </div>
                    <div className="inpcont">
                        <label>Land Mark*</label>
                        <ErrorMessage name='landMark' component={FormErrorMsg} />
                        <Field as={InputText} autoComplete="off" type="text" name="landMark"
                            className={(errors.landMark && touched.landMark ? ' is-invalid' : 'minputtext')} />
                    </div>
                    <div className="inpcont">
                        <label>Zip Code*</label>
                        <ErrorMessage name='ZipCode' component={FormErrorMsg} />
                        <Field as={InputText} autoComplete="off" type="text" name="ZipCode"
                            className={(errors.ZipCode && touched.ZipCode ? ' is-invalid' : 'minputtext')} />
                    </div>
                    <div className="inpcont">
                        <label>City*</label>
                        <ErrorMessage name='city' component={FormErrorMsg} />
                        <Field as={InputText} autoComplete="off" type="text" name="city"
                            className={(errors.city && touched.city ? ' is-invalid' : 'minputtext')} />
                    </div>
                    <div className="inpcont">
                        <label>State*</label>
                        <ErrorMessage name='state' component={FormErrorMsg} />
                        <Field as={InputText} autoComplete="off" type="text" name="state"
                            className={(errors.state && touched.state ? ' is-invalid' : 'minputtext')} />
                    </div>
                    <div className="inpcont">
                        <label>country*</label>
                        <ErrorMessage name='country' component={FormErrorMsg} />
                        <Field as={InputText} autoComplete="off" type="text" name="country"
                            className={(errors.country && touched.country ? ' is-invalid' : 'minputtext')} />
                    </div>
                    <div className="inpcont">
                        <label htmlFor="Phone">Phone*</label>
                        <span id="phone"
                            style={{ display: "none", float: "right" }}
                            className="float-right">Required</span>
                        <div id="pinp" className="mobileInp">
                            <Field as={PhoneInput} required onlyCountries={['gb', 'in']}
                                country={'gb'} autoComplete="off" type="tel" name="Phone" value={mobile}
                                onChange={(e: any) => {

                                    if (e?.length <= 11) {
                                        let p = document.getElementById('phone')
                                        let pi = document.getElementById('pinp')
                                        p.style.color = "red"
                                        p.style.display = "initial"
                                        pi.style.border = "1px solid red"
                                    }
                                    else {
                                        let p = document.getElementById('phone')
                                        let pi = document.getElementById('pinp')
                                        p.style.display = "none"
                                        pi.style.border = "none"

                                    }

                                    setMobile('+' + e)
                                }}
                                onBlur={(e: any) => {
                                    console.log("on blur phone", e.target.value)
                                    if (e.target.value?.length <= 3) {
                                        let p = document.getElementById('phone')
                                        let pi = document.getElementById('pinp')
                                        p.style.color = "red"
                                        p.style.display = "initial"
                                        pi.style.border = "1px solid red"
                                    }
                                    else {
                                        let p = document.getElementById('phone')
                                        let pi = document.getElementById('pinp')
                                        p.style.display = "none"
                                        pi.style.border = "none"

                                    }
                                }}
                                className={(errors.Phone && touched.Phone ? ' is-invalid' : '')} />

                        </div>
                    </div>
                </div>
                <br />
                <div style={{ display: 'flex', columnGap: '20px', rowGap: '20px', flexWrap: 'wrap' }}>
                    <Button type="button" onClick={Goback} className='outline' label="BACK" style={{ width: "150px" }} />
                    <Button type="submit" className='filledSmall' label="Add Branch" />
                </div>
            </Form>
            )}
            </Formik>
        </div>

    </>
}

export default AddNewRestaurant