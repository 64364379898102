import { ErrorMessage, Field, Form, Formik } from 'formik';
import { InputText } from 'primereact/inputtext';
import React, { Fragment, useState } from 'react';
import './forgotPassword.css';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { useStore } from '../stores/useStore';
import * as yup from 'yup';

const FormErrorMsg = styled.span`
 color: red;
 float:right;
 font-size:12px;
`
const ForgotPassword = () => {
  const history = useHistory();
  const [gtLogin, setGtLogin] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);
  const { userSessionStore } = useStore();

  return <Fragment>
    <div className="forgotPassword">
      <div className="svgLogo">
        <img src="./assets/images/forgotpassword.svg" alt=""></img>
      </div>
      <div className="forgotInp">
        <div hidden={!gtLogin} className="forgotIp">
          <h1>Reset Password</h1>
          <p>Please enter your email ID to receive a random new password</p>
          <br />

          <Formik
            initialValues={{ email: "" }}
            validationSchema={yup.object({
              email: yup.string().email('Please enter a valid Email')
                .max(100, 'Must be 100 characters or less')
                .required('Required')
            })}

            onSubmit={values => {
              userSessionStore.resetPassword(values.email)
                .then(([success, message]: any) => {
                  if (success) {
                    setErrorMsg(null)
                    setGtLogin(false);
                  } else {
                    setErrorMsg(message)
                  }
                })
            }}

          >
            {formik =>
              <Form>
                <div className="pwCont">
                <span style={{float:"right",color:"red"}}>{errorMsg}</span>
                  <label>Email </label>
                  <ErrorMessage name='email' component={FormErrorMsg} />
                  <Field className="inpFld" name='email' as={InputText} />
                  <br />
                  <button type="submit" className="pwBtn">Recover</button>
                  <p className="back blue" onClick={() => history.push('/login')}>Back</p>
                </div>
              </Form>
            }

          </Formik>

        </div>
        <div hidden={gtLogin} className="goToLogin">
          <h1 className="gtHeading">Password Reset Successful.</h1>
          <p className="gtCont">The new password has been sent to your registered email ID</p>
          <h6 onClick={() => history.push('/login')} className="gtLog">Click here to login.</h6>
        </div>
      </div>

    </div>
  </Fragment>
}

export default ForgotPassword